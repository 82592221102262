import React from 'react';
import {
  IonCard,
  IonSkeletonText,
  IonCardContent,
  IonCardHeader,
  IonAvatar,
  IonThumbnail,
  IonList,
  IonItem,
  IonIcon,
} from '@ionic/react';
import { flameOutline, chatbubblesOutline } from 'ionicons/icons';

const VehicleComponentSkeleton = ({ isComponent }) => {
  const screenWidth = window.innerWidth;
  const imageSize = screenWidth * 0.285;

  return (
    <>
      <IonSkeletonText
        className="ion-margin-bottom"
        animated
        style={{ margin: '24px 0', width: '30%', height: '20px' }}
      />
      {isComponent ? (
        <IonThumbnail style={{ width: '100%', height: '350px' }}>
          <IonSkeletonText animated />
        </IonThumbnail>
      ) : (
        <div
          className="ion-justify-content-between"
          style={{ display: 'flex' }}
        >
          <IonThumbnail
            style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
          >
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonThumbnail
            style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
          >
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonThumbnail
            style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
          >
            <IonSkeletonText animated />
          </IonThumbnail>
        </div>
      )}
      <IonCard className="UserInfoCard" style={{ marginLeft: 0, marginRight: 0 }}>
        {!isComponent && (
          <IonCardHeader
            className="ion-align-items-center"
            style={{ display: 'flex' }}
          >
            <IonAvatar
              style={{ marginRight: '1rem', width: '96px', height: '96px' }}
            >
              <IonSkeletonText animated />
            </IonAvatar>
            <p style={{ width: 'calc(100% - 96px - 1rem)' }}>
              <IonSkeletonText animated style={{ width: '40%' }} />
              <IonSkeletonText animated style={{ width: '30%' }} />
              <IonSkeletonText animated style={{ width: '10%' }} />
            </p>
          </IonCardHeader>
        )}
        <IonCardContent className="DetailsCard-content">
          <IonList>
            <IonItem className="DetailsCard-item">
              <IonThumbnail
                slot="start"
                style={{ width: '24px', height: '24px' }}
              >
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonSkeletonText animated style={{ width: '50%' }} />
              <IonSkeletonText animated style={{ width: '10%' }} slot="end" />
            </IonItem>
            <IonItem className="DetailsCard-item">
              <IonThumbnail
                slot="start"
                style={{ width: '24px', height: '24px' }}
              >
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonSkeletonText animated style={{ width: '50%' }} />
              <IonSkeletonText animated style={{ width: '10%' }} slot="end" />
            </IonItem>
            <IonItem className="DetailsCard-item">
              <IonThumbnail
                slot="start"
                style={{ width: '24px', height: '24px' }}
              >
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonSkeletonText animated style={{ width: '50%' }} />
              <IonSkeletonText animated style={{ width: '10%' }} slot="end" />
            </IonItem>
            <IonItem className="DetailsCard-item">
              <IonThumbnail
                slot="start"
                style={{ width: '24px', height: '24px' }}
              >
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonSkeletonText animated style={{ width: '50%' }} />
              <IonSkeletonText animated style={{ width: '10%' }} slot="end" />
            </IonItem>
            <IonItem className="DetailsCard-item">
              <IonThumbnail
                slot="start"
                style={{ width: '24px', height: '24px' }}
              >
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonSkeletonText animated style={{ width: '50%' }} />
              <IonSkeletonText animated style={{ width: '10%' }} slot="end" />
            </IonItem>
            <IonItem className="DetailsCard-item">
              <IonThumbnail
                slot="start"
                style={{ width: '24px', height: '24px' }}
              >
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonSkeletonText animated style={{ width: '50%' }} />
              <IonSkeletonText animated style={{ width: '10%' }} slot="end" />
            </IonItem>
            <IonItem className="DetailsCard-item">
              <IonThumbnail
                slot="start"
                style={{ width: '24px', height: '24px' }}
              >
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonSkeletonText animated style={{ width: '50%' }} />
              <IonSkeletonText animated style={{ width: '10%' }} slot="end" />
            </IonItem>
          </IonList>
          <div className="DetailsCard-footer">
            <div className="DetailsCard-footer__button">
              <IonIcon color="dark" icon={flameOutline} />
              <IonSkeletonText animated style={{ width: '1.5rem' }} />
            </div>
            <div className="DetailsCard-footer__button">
              <IonIcon color="dark" icon={chatbubblesOutline} />
              <IonSkeletonText animated style={{ width: '1.5rem' }} />
            </div>
          </div>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default VehicleComponentSkeleton;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage, withIonLifeCycle } from '@ionic/react';

import Header from '../../components/Header/Header';
import VehicleComponentSkeleton from '../../components/SkeletonGroup/VehicleComponentSkeleton';
import CarDetails from '../../components/CarDetails/CarDetails';

import {
  getCarRef,
  getCarProfile,
  getCurrentUserUid,
} from '../../api/firebase';
import { SHOWROOM_ROUTE, VEHICLE_ROUTE } from '../../constants/routes';
import { LOADING_START, LOADING_FINISH } from '../../store/car/actions';

const initialState = {
  details: null,
  isLoading: true,
  _carProfileListener: null,
};

class Vehicle extends Component {
  state = initialState;
  _isUnmounted = false;

  ionViewDidEnter() {
    this.setState({ isLoading: true });
    const { pathname } = this.props.history.location;
    const carUid = pathname.split(VEHICLE_ROUTE)[1];
    const carProfileListener = getCarRef(carUid).on('value', () => {
      this.fetchCarProfile(carUid);
    });

    return this.setState({ _carProfileListener: carProfileListener });
  }

  ionViewWillLeave() {
    if (this.state.details && this.state.details.uid) {
      getCarRef(this.state.details.uid).off(
        'value',
        this.state._carProfileListener
      );
    }

    if (!this._isUnmounted) {
      setTimeout(() => {
        this.setState(initialState);
      }, 300);
    }
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  fetchCarProfile = carUid =>
    getCarProfile(carUid)
      .then(data => {
        if (!data) {
          throw new Error();
        }

        return this.setState({
          details: { ...data, uid: carUid },
          isLoading: false,
        });
      })
      .catch(() => this.props.history.push(SHOWROOM_ROUTE));

  render() {
    const { details, isLoading } = this.state;
    const isOwner =
      details && details.owner && details.owner.uid === getCurrentUserUid();
    const myCarDetails = {
      ...this.props.myCarDetails,
      owner: details ? details.owner : null,
    };

    return (
      <IonPage>
        <Header goBack />
        <IonContent className="ion-padding">
          {!isLoading && details ? (
            <CarDetails
              details={isOwner ? myCarDetails : details}
              myCar={isOwner}
              isOwner={isOwner}
            />
          ) : (
            <VehicleComponentSkeleton />
          )}
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = store => ({
  myCarDetails: store.car.details,
});

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch({ type: LOADING_START }),
  finishLoading: () => dispatch({ type: LOADING_FINISH }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withIonLifeCycle(Vehicle));

import React from 'react';
import { IonIcon, IonToolbar, IonButtons, IonButton } from '@ionic/react';
import { funnelOutline, gridOutline, squareOutline } from 'ionicons/icons';

import { viewModes } from '../../constants/constants';
import './Toolbar.sass';

const Toolbar = ({ viewMode, onViewModeChange, openSortingModal }) => {
  return (
    <IonToolbar className="Toolbar">
      <IonButtons slot="start" className="Toolbar-block">
        <IonButton color="dark" onClick={openSortingModal}>
          <IonIcon icon={funnelOutline} />
        </IonButton>
      </IonButtons>
      <IonButtons slot="end" className="Toolbar-block">
        <IonButton
          color="dark"
          onClick={() => onViewModeChange(viewModes.TWO_COLUMNS)}
        >
          <IonIcon
            icon={gridOutline}
            color={viewMode === viewModes.TWO_COLUMNS ? 'primary' : 'dark'}
          />
        </IonButton>
        <IonButton
          color="dark"
          onClick={() => onViewModeChange(viewModes.ONE_COLUMN)}
        >
          <IonIcon
            icon={squareOutline}
            color={viewMode === viewModes.ONE_COLUMN ? 'primary' : 'dark'}
          />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

export default Toolbar;

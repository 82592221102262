import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IonIcon, IonButton } from '@ionic/react';
import { arrowRedoOutline } from 'ionicons/icons';

import { LOGIN_ROUTE } from '../../../constants/routes';

const ReplyButton = ({ disabled, isLoggedIn, onClick }) => {
  const replyIcon = useRef(null);

  const onButtonClick = () => {
    if (!isLoggedIn) {
      return;
    }
    const icon = replyIcon.current;

    if (disabled) {
      icon.classList.add('disabled');
      return setTimeout(() => {
        icon.classList.remove('disabled');
      }, 500);
    }

    return onClick();
  };

  const replyButton = (
    <IonButton
      slot="end"
      fill="clear"
      color="medium"
      size="small"
      onClick={onButtonClick}
    >
      <IonIcon ref={replyIcon} icon={arrowRedoOutline} />
    </IonButton>
  );

  return !isLoggedIn ? (
    <Link to={LOGIN_ROUTE}>{replyButton}</Link>
  ) : (
    replyButton
  );
};

const mapStateToProps = store => ({
  isLoggedIn: store.user.isLoggedIn,
});

export default connect(mapStateToProps)(ReplyButton);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonText,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';

import Header from '../../components/Header/Header';
import Toolbar from '../../components/Toolbar/Toolbar';
import CatalogueComponentCard from '../../components/CardGroup/CatalogueComponentCard/CatalogueComponentCard';
import ShowroomCatalogueSkeleton from '../../components/SkeletonGroup/ShowroomCatalogueSkeleton';
import MasonryGrid from '../../components/MasonryGrid/MasonryGrid';
import Fade from '../../components/utils/Fade';

import { fetchCatalogueComponentsAction } from '../../store/catalogue/actions';
import {
  CATALOGUE_VIEW_MODE_STORAGE_PROPERTY,
  viewModes,
} from '../../constants/constants';

class Catalogue extends Component {
  ionContent = React.createRef();

  state = {
    viewMode: viewModes.TWO_COLUMNS,
  };

  componentDidMount() {
    const viewMode = localStorage.getItem(CATALOGUE_VIEW_MODE_STORAGE_PROPERTY)
      
    
    this.setState({ viewMode })
    this.fetchComponents();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSameActiveTab !== this.props.isSameActiveTab &&
      this.props.isSameActiveTab
    ) {
      this.ionContent.current.scrollToTop(500);
      this.props.resetSecondTabClick();
    }
  }

  fetchComponents = e => {
    const { components, fetchCatalogueComponents } = this.props;
    const lastComponent = components[components.length - 1];

    return fetchCatalogueComponents(
      lastComponent ? lastComponent.id : null
    ).then(() => (e ? e.target.complete() : true));
  };

  onRefresh = e =>
    setTimeout(
      this.props.fetchCatalogueComponents().then(() => e.detail.complete()),
      500
    );

  handleViewModeChange = viewMode => {
    localStorage.setItem(CATALOGUE_VIEW_MODE_STORAGE_PROPERTY, viewMode);
    this.setState({ viewMode });
  };

  render() {
    const { components, isAllComponentsFetched, isLoading } = this.props;
    const { viewMode } = this.state;

    return (
      <IonPage>
        <Header />
        <IonContent ref={this.ionContent} className="ion-padding">
          <IonRefresher slot="fixed" onIonRefresh={this.onRefresh}>
            <IonRefresherContent className="infinite-spinner" />
          </IonRefresher>
          <Fade>
            <IonText color="dark">
              <h1>Catalogue</h1>
            </IonText>
            <Toolbar
              viewMode={viewMode}
              onViewModeChange={this.handleViewModeChange}
            />
            <MasonryGrid columns={viewMode === viewModes.TWO_COLUMNS ? 2 : 1}>
              {isLoading
                ? [
                    ...new Array(viewMode === viewModes.TWO_COLUMNS ? 6 : 2),
                  ].map((_, index) => (
                    <ShowroomCatalogueSkeleton
                      key={index}
                      shrinked={viewMode === viewModes.TWO_COLUMNS}
                    />
                  ))
                : components.map((component, index) => (
                    <CatalogueComponentCard
                      key={index}
                      shrinked={viewMode === viewModes.TWO_COLUMNS}
                      component={component}
                    />
                  ))}
            </MasonryGrid>
            <IonInfiniteScroll
              threshold="100px"
              disabled={isAllComponentsFetched}
              onIonInfinite={this.fetchComponents}
            >
              <IonInfiniteScrollContent
                className="infinite-spinner"
                loading-spinner="dots"
              />
            </IonInfiniteScroll>
          </Fade>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = store => ({
  components: store.catalogue.components,
  isLoading: store.catalogue.isLoading,
  isAllComponentsFetched: store.catalogue.isAllComponentsFetched,
});

const mapDispatchToProps = dispatch => ({
  fetchCatalogueComponents: lastComponentId =>
    dispatch(fetchCatalogueComponentsAction(lastComponentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);

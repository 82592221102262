import React, { Component } from 'react';
import { IonText, IonList } from '@ionic/react';

import SettingToggle from '../SettingToggle';

import { setNotificationSetting } from '../../../api/api';
import notificationTypes from '../../../constants/notificationTypes';

class NotificationsSettings extends Component {
  state = {
    newFollower: !!this.props.settings.newFollower,
    newVehicleComment: !!this.props.settings.newVehicleComment,
    newComponentComment: !!this.props.settings.newComponentComment,
    newVehicleLike: !!this.props.settings.newVehicleLike,
    newComponentLike: !!this.props.settings.newComponentLike,
    newCommentLike: !!this.props.settings.newCommentLike,
    newFollowingImage: !!this.props.settings.newFollowingImage,
    newFollowingComponent: !!this.props.settings.newFollowingComponent,
    newFollowingLevel: !!this.props.settings.newFollowingLevel,
    followingLikesVehicle: !!this.props.settings.followingLikesVehicle,
    followingLikesComponent: !!this.props.settings.followingLikesComponent,
    followingLikesComment: !!this.props.settings.followingLikesComment,
    followingCommentsVehicle: !!this.props.settings.followingCommentsVehicle,
    followingCommentsComponent: !!this.props.settings
      .followingCommentsComponent,
  };

  getStateSettingNameFromType = type => {
    switch (type) {
      case notificationTypes.NEW_FOLLOWER:
        return 'newFollower';
      case notificationTypes.NEW_VEHICLE_COMMENT:
        return 'newVehicleComment';
      case notificationTypes.NEW_COMPONENT_COMMENT:
        return 'newComponentComment';
      case notificationTypes.NEW_VEHICLE_LIKE:
        return 'newVehicleLike';
      case notificationTypes.NEW_COMPONENT_LIKE:
        return 'newComponentLike';
      case notificationTypes.NEW_COMMENT_LIKE:
        return 'newCommentLike';
      case notificationTypes.NEW_FOLLOWING_IMAGE:
        return 'newFollowingImage';
      case notificationTypes.NEW_FOLLOWING_COMPONENT:
        return 'newFollowingComponent';
      case notificationTypes.NEW_FOLLOWING_LEVEL:
        return 'newFollowingLevel';
      case notificationTypes.FOLLOWING_LIKES_VEHICLE:
        return 'followingLikesVehicle';
      case notificationTypes.FOLLOWING_LIKES_COMPONENT:
        return 'followingLikesComponent';
      case notificationTypes.FOLLOWING_LIKES_COMMENT:
        return 'followingLikesComment';
      case notificationTypes.FOLLOWING_COMMENTS_VEHICLE:
        return 'followingCommentsVehicle';
      case notificationTypes.FOLLOWING_COMMENTS_COMPONENT:
        return 'followingCommentsComponent';
      default:
        return 'newFollower';
    }
  };

  updateSetting = (type, value) =>
    setNotificationSetting({
      type,
      value,
    });

  handleChange = (value, type) =>
    this.setState(
      prevState => {
        const newSetting = {};
        newSetting[this.getStateSettingNameFromType(type)] = value;

        return {
          ...prevState,
          ...newSetting,
        };
      },
      () => this.updateSetting(type, value)
    );

  render() {
    return (
      <>
        <IonText>
          <h1>Notifications</h1>
        </IonText>
        <IonList>
          <SettingToggle
            name="Someone follows you"
            isChecked={this.state.newFollower}
            onChange={this.handleChange}
            type={notificationTypes.NEW_FOLLOWER}
          />
          <SettingToggle
            name="Someone comments your car"
            isChecked={this.state.newVehicleComment}
            onChange={this.handleChange}
            type={notificationTypes.NEW_VEHICLE_COMMENT}
          />
          <SettingToggle
            name="Someone comments your component"
            isChecked={this.state.newComponentComment}
            onChange={this.handleChange}
            type={notificationTypes.NEW_COMPONENT_COMMENT}
          />
          <SettingToggle
            name="Someone likes your car"
            isChecked={this.state.newVehicleLike}
            onChange={this.handleChange}
            type={notificationTypes.NEW_VEHICLE_LIKE}
          />
          <SettingToggle
            name="Someone likes your components"
            isChecked={this.state.newComponentLike}
            onChange={this.handleChange}
            type={notificationTypes.NEW_COMPONENT_LIKE}
          />
          <SettingToggle
            name="Someone likes your comment"
            isChecked={this.state.newCommentLike}
            onChange={this.handleChange}
            type={notificationTypes.NEW_COMMENT_LIKE}
          />
          <SettingToggle
            name="User you follow, adds new pictures"
            isChecked={this.state.newFollowingImage}
            onChange={this.handleChange}
            type={notificationTypes.NEW_FOLLOWING_IMAGE}
          />
          <SettingToggle
            name="User you follow, taggs new components"
            isChecked={this.state.newFollowingComponent}
            onChange={this.handleChange}
            type={notificationTypes.NEW_FOLLOWING_COMPONENT}
          />
          <SettingToggle
            name="User you follow, reaches a new level"
            isChecked={this.state.newFollowingLevel}
            onChange={this.handleChange}
            type={notificationTypes.NEW_FOLLOWING_LEVEL}
          />
          <SettingToggle
            name="User you follow, likes a car"
            isChecked={this.state.followingLikesVehicle}
            onChange={this.handleChange}
            type={notificationTypes.FOLLOWING_LIKES_VEHICLE}
          />
          <SettingToggle
            name="User you follow, likes a component"
            isChecked={this.state.followingLikesComponent}
            onChange={this.handleChange}
            type={notificationTypes.FOLLOWING_LIKES_COMPONENT}
          />
          <SettingToggle
            name="User you follow, likes a comment"
            isChecked={this.state.followingLikesComment}
            onChange={this.handleChange}
            type={notificationTypes.FOLLOWING_LIKES_COMMENT}
          />
          <SettingToggle
            name="User you follow, comments a car"
            isChecked={this.state.followingCommentsVehicle}
            onChange={this.handleChange}
            type={notificationTypes.FOLLOWING_COMMENTS_VEHICLE}
          />
          <SettingToggle
            name="User you follow, comments a component"
            isChecked={this.state.followingCommentsComponent}
            onChange={this.handleChange}
            type={notificationTypes.FOLLOWING_COMMENTS_COMPONENT}
          />
        </IonList>
      </>
    );
  }
}

export default NotificationsSettings;

import React from 'react';
import { IonContent, IonText } from '@ionic/react';

import Header from '../../components/Header/Header';
import LoginForm from '../../components/AuthForms/LoginForm';
import RegisterForm from '../../components/AuthForms/RegisterForm';
import PasswordReset from '../../components/AuthForms/PasswordReset';
import NewPassword from '../../components/AuthForms/NewPassword';

const types = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
  PASSWORD_RESET: 'reset-password',
  NEW_PASSWORD: 'new-password',
};

const Auth = ({ type = types.LOGIN }) => {
  const getTitleByType = type => {
    switch (type) {
      case types.LOGIN:
        return 'Login';
      case types.REGISTRATION:
        return 'Register';
      case types.PASSWORD_RESET:
        return 'Reset Password';
      case types.NEW_PASSWORD:
        return 'New Password';
      default:
        return 'Login';
    }
  };

  const getComponentByType = type => {
    switch (type) {
      case types.LOGIN:
        return <LoginForm />;
      case types.REGISTRATION:
        return <RegisterForm />;
      case types.PASSWORD_RESET:
        return <PasswordReset />;
      case types.NEW_PASSWORD:
        return <NewPassword />;
      default:
        return <LoginForm />;
    }
  };

  return (
    <>
      <Header goBack />
      <IonContent className="ion-padding">
        <IonText color="dark">
          <h1>{getTitleByType(type)}</h1>
        </IonText>
        <div className="ion-text-center">{getComponentByType(type)}</div>
      </IonContent>
    </>
  );
};

export default Auth;

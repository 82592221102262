import { getAllComponents, catalogueRef } from '../../api/firebase';

export const LOADING_START = 'LOADING_CATALOGUE_START';
export const LOADING_FINISH = 'LOADING_CATALOGUE_FINISH';
export const CATALOGUE_UPDATE_COMPONENT = 'CATALOGUE_UPDATE_COMPONENT';
export const ALL_COMPONENTS_FETCHED = 'ALL_COMPONENTS_FETCHED';
export const FETCH_COMPONENTS = 'FETCH_COMPONENTS';

export const listenToCatalogueUpdates = () => dispatch =>
  catalogueRef().on('child_changed', data =>
    dispatch({
      type: CATALOGUE_UPDATE_COMPONENT,
      payload: { ...data.val(), id: data.key },
    })
  );

export const fetchCatalogueComponentsAction = lastComponentId => {
  return dispatch => {
    if (!lastComponentId) {
      dispatch({ type: LOADING_START });
    }
    return getAllComponents(lastComponentId)
      .then(components => {
        if (components && components.length) {
          dispatch({
            type: FETCH_COMPONENTS,
            payload: { components, isRefresh: !lastComponentId },
          });
        } else {
          dispatch({ type: ALL_COMPONENTS_FETCHED });
        }
        return components;
      })
      .catch(err => {
        alert(err);
        dispatch({ type: LOADING_FINISH });
      });
  };
};

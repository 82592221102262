import React from 'react';
import { Link } from 'react-router-dom';
import {
  IonCard,
  IonItem,
  IonCardContent,
  IonChip,
  IonLabel,
} from '@ionic/react';

import LikeButton from '../../ButtonGroup/LikeButton/LikeButton';

import { VEHICLE_ROUTE } from '../../../constants/routes';
import { getCurrentUserUid } from '../../../api/firebase';
import { getOptimizedImageURL } from '../../../utils/utils';
import './ShowroomCarCard.sass';

const ShowroomCarCard = ({ car, shrinked }) => {
  const isOwner = car.owner.uid === getCurrentUserUid();

  return (
    <IonCard
      className={`ShowroomCarCard ${
        shrinked ? 'ShowroomCarCard--shrinked' : ''
      }`}
    >
      <Link to={VEHICLE_ROUTE + car.id} className="Link">
        <img
          srcSet={`${getOptimizedImageURL(car.image, 730, null, '10:7')} 768w,
          ${getOptimizedImageURL(car.image, 385, null, '10:7')} 425w`}
          src={getOptimizedImageURL(car.image, 385, null, '10:7')}
          alt="Car"
        />
      </Link>
      <IonCardContent className="ShowroomCarCard-content">
        <Link
          to={VEHICLE_ROUTE + car.id}
          className="Link ShowroomCarCard-card-info"
        >
          <div>
            <IonItem className="ShowroomCarCard-card-info__title">
              <IonLabel>
                {car.make}, {car.type}
              </IonLabel>
            </IonItem>
            <IonChip className="ShowroomCarCard-card-info__chip" outline>
              <IonLabel color="primary">{car.model}</IonLabel>
            </IonChip>
          </div>
          <div className="ShowroomCarCard-card-info__owner">
            {car.owner.name}
          </div>
        </Link>
        <div className="ShowroomCarCard-like-zone">
          <LikeButton
            type="icon"
            size="large"
            path={{ vehicleId: car.id }}
            isLiked={car.likes ? car.likes[getCurrentUserUid()] : false}
            disabled={isOwner}
          />
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default ShowroomCarCard;

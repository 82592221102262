import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { IonContent, IonPage, IonToast, IonLoading } from '@ionic/react';

import Header from '../../components/Header/Header';
import SettingsList from '../../components/SettingsGroup/SettingsList/SettingsList';
import ProfileSettings from '../../components/SettingsGroup/ProfileSettings/ProfileSettings';
import PasswordSettings from '../../components/SettingsGroup/PasswordSettings/PasswordSettings';
import MailSettings from '../../components/SettingsGroup/MailSettings/MailSettings';
import NotificationsSettings from '../../components/SettingsGroup/NotificationsSettings/NotificationsSettings';
import DisplaySettings from '../../components/SettingsGroup/DisplaySettings/DisplaySettings';
import DeleteCarOrProfileSetting from '../../components/SettingsGroup/DeleteCarOrProfileSetting/DeleteCarOrProfileSetting';

import {
  LOADING_START,
  LOADING_FINISH,
  logUserOut,
} from '../../store/user/actions';
import { SHOWROOM_ROUTE } from '../../constants/routes';

import './Settings.sass';

class Settings extends Component {
  state = {
    showToastMsg: false,
  };

  openToastMsg = () => this.setState({ showToastMsg: true });

  redirectToShowroom = () => this.props.history.push(SHOWROOM_ROUTE);

  handleGoBackToSettings = () => {
    this.props.history.goBack();
    this.openToastMsg();
  };

  renderSettings = () => {
    const {
      user,
      carDetails,
      startLoading,
      finishLoading,
      logoutUser,
    } = this.props;

    switch (this.props.type) {
      case 'list':
        return <SettingsList carDetails={carDetails} />;
      case 'profile':
        return (
          <ProfileSettings
            user={user}
            startLoading={startLoading}
            finishLoading={finishLoading}
            goBack={this.handleGoBackToSettings}
          />
        );
      case 'password':
        return (
          <PasswordSettings
            startLoading={startLoading}
            finishLoading={finishLoading}
            goBack={this.handleGoBackToSettings}
          />
        );
      case 'mail':
        return (
          <MailSettings
            startLoading={startLoading}
            finishLoading={finishLoading}
            goBack={this.handleGoBackToSettings}
          />
        );
      case 'notifications':
        return <NotificationsSettings settings={user.notifications.settings} />;
      case 'display':
        return <DisplaySettings />;
      case 'delete-car':
        return (
          <DeleteCarOrProfileSetting
            isCarDeleteTab
            startLoading={startLoading}
            finishLoading={finishLoading}
            goBack={this.handleGoBackToSettings}
          />
        );
      case 'delete-profile':
        return (
          <DeleteCarOrProfileSetting
            startLoading={startLoading}
            finishLoading={finishLoading}
            logoutUser={logoutUser}
            goBack={this.redirectToShowroom}
          />
        );
      default:
        return <SettingsList carDetails={carDetails} />;
    }
  };

  render() {
    const { showToastMsg } = this.state;
    const { user, isLoggedIn, isLoading } = this.props;

    if (!user || !isLoggedIn) {
      return <Redirect to={SHOWROOM_ROUTE} />;
    }

    return (
      <IonPage>
        <IonLoading isOpen={isLoading} message={'Please wait...'} />
        <IonToast
          isOpen={showToastMsg}
          onDidDismiss={() => this.setState({ showToastMsg: false })}
          message="Your changes were saved"
          duration={2000}
          color="primary"
        />
        <Header goBack />
        <IonContent className="ion-padding">{this.renderSettings()}</IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = store => ({
  carDetails: store.car.details,
  user: store.user.user,
  isLoggedIn: store.user.isLoggedIn,
  isLoading: store.user.isLoading,
});

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch({ type: LOADING_START }),
  finishLoading: () => dispatch({ type: LOADING_FINISH }),
  logoutUser: () => dispatch(logUserOut()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));

import React, { Component } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import ClickNHold from 'react-click-n-hold';
import { IonIcon, IonAlert } from '@ionic/react';
import { cameraOutline } from 'ionicons/icons';
import { ImagePicker } from '@ionic-native/image-picker';
import './ImageUploader.sass';

class ImageUploader extends Component {
  state = {
    showMaxImagesAlertMsg: false,
    showRemoveConfirmAlertMsg: false,
    indexToRemove: null,
    rowHeight: 100,
    disableDrag: true,
  };

  componentDidMount() {
    this.setRowHeight();
    window.addEventListener('resize', this.setRowHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setRowHeight);
  }

  setRowHeight = () => {
    let rowHeight = (window.innerWidth - 16 * 2) / 3;
    this.setState({ rowHeight: rowHeight });
  };

  onDragging = (_, sourceIndex, targetIndex) => {
    const { images } = this.props;

    if (sourceIndex === images.length || targetIndex === images.length) return;

    const updatedImages = swap(images, sourceIndex, targetIndex);
    this.props.setImages(updatedImages);
  };

  onImageAdding = () => {
    const { images, setImages } = this.props;

    if (images.length === 20) {
      return this.showMaxImagesAlertMsg();
    }

    try {
      ImagePicker.getPictures({
        maximumImagesCount: 20 - images.length,
        quality: 80,
        outputType: 1, // base64 format
      }).then(imageFiles => {
        if (!imageFiles.length) return;

        if (images.length + imageFiles.length <= 20) {
          setImages([...images, ...imageFiles]);
          this.props.setCheckboxAvailability(true);
        } else {
          this.showMaxImagesAlertMsg();
        }
      });
    } catch {}
  };

  checkNewImagesWereAdded = () =>
    this.props.images.length ? this.props.images.find(el => el.name) : false;

  onImageRemove = () => {
    const { setImages, images, setCheckboxAvailability } = this.props;

    setImages(
      images.filter((el, index) => index !== this.state.indexToRemove),
      () => setCheckboxAvailability(this.checkNewImagesWereAdded())
    );
  };

  showMaxImagesAlertMsg = () => this.setState({ showMaxImagesAlertMsg: true });

  hideMaxImagesAlertMsg = () => this.setState({ showMaxImagesAlertMsg: false });

  showRemoveConfirmAlertMsg = index => {
    this.setState({ showRemoveConfirmAlertMsg: true, indexToRemove: index });
  };

  hideRemoveConfirmAlertMsg = () =>
    this.setState({ showRemoveConfirmAlertMsg: false, indexToRemove: null });

  render() {
    const { images } = this.props;
    const {
      showMaxImagesAlertMsg,
      showRemoveConfirmAlertMsg,
      rowHeight,
      disableDrag,
    } = this.state;

    return (
      <>
        <IonAlert
          isOpen={showMaxImagesAlertMsg}
          onDidDismiss={this.hideMaxImagesAlertMsg}
          header={'Warning'}
          message={
            'You’ve reached the current maximum of 20 images. Delete old ones to add new ones'
          }
          buttons={['OK']}
        />
        <IonAlert
          isOpen={showRemoveConfirmAlertMsg}
          onDidDismiss={this.hideRemoveConfirmAlertMsg}
          header={'Are you sure?'}
          message={
            "Do you really want to delete this image? This can't be undone."
          }
          buttons={[
            'Abort',
            {
              text: 'Delete',
              handler: this.onImageRemove,
            },
          ]}
        />
        <ClickNHold
          time={0.8}
          onClickNHold={() => this.setState({ disableDrag: false })}
          onEnd={() => this.setState({ disableDrag: true })}
        >
          <div>
            <GridContextProvider onChange={this.onDragging}>
              <GridDropZone
                boxesPerRow={3}
                disableDrag={disableDrag}
                rowHeight={rowHeight}
                style={{
                  height: Math.ceil((images.length + 1) / 3) * rowHeight + 'px',
                }}
                className="ImageUploader"
              >
                {images.map((item, index) => (
                  // Image can be an object from databse or URL string
                  <GridItem key={item.src ? item.src : index}>
                    <div
                      className="ImageUploader__item"
                      style={{
                        backgroundImage: `url(${
                          item.src ? item.src : 'data:image/png;base64,' + item
                        })`,
                      }}
                      onClick={() => this.showRemoveConfirmAlertMsg(index)}
                    ></div>
                  </GridItem>
                ))}
                <GridItem key="add-btn">
                  <div
                    className="ImageUploader__item ImageUploader-btn"
                    onClick={this.onImageAdding}
                  >
                    <IonIcon icon={cameraOutline} color="dark" />
                  </div>
                </GridItem>
              </GridDropZone>
            </GridContextProvider>
          </div>
        </ClickNHold>
        <small className="ImageUploader__hint">*drag to sort, tap to delete.</small>
      </>
    );
  }
}

export default ImageUploader;

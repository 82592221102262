import React, { useState } from 'react';
import { IonButton, IonSlide, IonAlert } from '@ionic/react';
import uniqid from 'uniqid';

import ComponentInputs from '../ComponentInputs/ComponentInputs';

import { editComponent, deleteTagOrComponent } from '../../../api/api';
import { uploadImage, getImageURL } from '../../../api/firebase';
import { COMPONENTS_IMAGE_UPLOAD_PATH } from '../../../api/routes';
import './EditComponent.sass';

const EditComponent = ({
  lastTag,
  imageIndex,
  components,
  onSwipingNext,
  closeModal,
  startLoading,
  finishLoading,
  fetchMyCarDetails,
}) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleComponentEdit = component => {
    component.id = lastTag.componentId;

    startLoading();

    if (component.image && component.image.selectedImgSrc) {
      const uniqueFileName = uniqid('component-image-');

      return uploadImage(
        COMPONENTS_IMAGE_UPLOAD_PATH,
        uniqueFileName,
        component.image.selectedImgSrc
      )
        .then(() => getImageURL(COMPONENTS_IMAGE_UPLOAD_PATH, uniqueFileName))
        .then(url => {
          component.image = url;

          return onEditComponent(component);
        });
    }

    return onEditComponent(component);
  };

  const onEditComponent = component =>
    editComponent(component)
      .then(() => {
        fetchMyCarDetails();
        closeModal();
      })
      .catch(() => finishLoading());

  const onDeletingComponent = (deleteAll = false) => {
    const component = {
      componentId: lastTag.componentId,
    };

    if (!deleteAll) {
      component.imageIndex = imageIndex;
    }

    startLoading();

    return deleteTagOrComponent(component)
      .then(() => closeModal())
      .catch(() => finishLoading());
  };

  const getComponentDataByTag = () => {
    const component = components.find(
      component => component.id === lastTag.componentId
    );

    return component ? component : null;
  };

  const componentData = getComponentDataByTag();

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Are you sure?'}
        buttons={[
          {
            text: 'Delete tag here',
            handler: () => onDeletingComponent(),
          },
          {
            text: 'Delete tag on all images (deletes component)',
            handler: () => onDeletingComponent(true),
          },
          {
            text: 'Abort',
            role: 'cancel',
          },
        ]}
      />
      <IonSlide>
        <div className="TaggingModal-content ion-padding ion-flex">
          <div className="EditComponent-buttons">
            <IonButton
              className="TaggingModal-content-btn"
              color="warning"
              style={{ marginTop: '1rem' }}
              onClick={onSwipingNext}
            >
              Edit
            </IonButton>
            <IonButton
              className="TaggingModal-content-btn"
              color="danger"
              style={{ marginTop: '1rem' }}
              onClick={() => setShowAlert(true)}
            >
              Delete
            </IonButton>
          </div>
        </div>
      </IonSlide>
      <IonSlide>
        <ComponentInputs
          category={componentData ? componentData.category : null}
          componentData={componentData}
          onSubmit={handleComponentEdit}
        />
      </IonSlide>
    </>
  );
};

export default EditComponent;

import React, { Component } from 'react';
import { IonContent, IonPage, IonText, withIonLifeCycle } from '@ionic/react';

import Header from '../../components/Header/Header';
import UserInfoCard from '../../components/CardGroup/UserInfoCard/UserInfoCard';
import NarrowCardList from '../../components/CardGroup/NarrowCardList/NarrowCardList';
import ProfileSkeleton from '../../components/SkeletonGroup/ProfileSkeleton';

import {
  SHOWROOM_ROUTE,
  MY_CAR_ROUTE,
  PROFILE_ROUTE,
  VEHICLE_ROUTE,
  COMPONENT_ROUTE,
} from '../../constants/routes';
import {
  getUserRef,
  getUserProfile,
  getCurrentUserUid,
} from '../../api/firebase';
import './Profile.sass';

const initialState = {
  user: null,
  carDetails: null,
  _userRefListener: null,
};

class Profile extends Component {
  state = initialState;
  _isUnmounted = false;

  ionViewDidEnter() {
    const userUid = this.props.history.location.pathname.split(
      PROFILE_ROUTE
    )[1];

    const userRefListener = getUserRef(userUid).on('value', () => {
      this.fetchUserProfile(userUid);
    });

    this.setState({ _userRefListener: userRefListener });
  }

  ionViewWillLeave() {
    if (this.state.user && this.state.user.uid) {
      getUserRef(this.state.user.uid).off('value', this.state._userRefListener);
    }

    if (!this._isUnmounted) {
      this.setState(initialState);
    }
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  fetchUserProfile = userUid =>
    getUserProfile(userUid)
      .then(data =>
        this.setState({
          user: { ...data.user, uid: userUid },
          carDetails: data.car,
        })
      )
      .catch(() => {
        this.props.history.push(SHOWROOM_ROUTE);
      });

  render() {
    const { user, carDetails } = this.state;
    const uid = this.props.history.location.pathname.split('/')[2];
    const isMyProfile = user && user.uid === getCurrentUserUid();

    return (
      <IonPage className="Profile">
        <Header goBack />
        <IonContent className="Profile-content ion-padding">
          {!user ? (
            <ProfileSkeleton />
          ) : (
            <>
              <IonText color="dark">
                <h2>{user ? user.name : ''}</h2>
              </IonText>
              <UserInfoCard
                user={{ ...user, uid }}
                componentAmount={
                  carDetails && carDetails.components
                    ? carDetails.components.length
                    : 0
                }
                small
              />
              {carDetails && (
                <>
                  <IonText color="dark">
                    <h2>
                      {user
                        ? isMyProfile
                          ? 'My car'
                          : `${user.name}'s car`
                        : ''}
                    </h2>
                  </IonText>
                  <NarrowCardList
                    data={[carDetails]}
                    getImage={car =>
                      car.images ? car.images[0].src : car.image
                    }
                    getTitle={car => car.model + ', ' + car.type}
                    getSubtitle={car => car.make}
                    getLink={() =>
                      carDetails.owner === getCurrentUserUid()
                        ? MY_CAR_ROUTE
                        : VEHICLE_ROUTE + carDetails.uid
                    }
                  />
                  {carDetails.components && (
                    <>
                      <IonText color="dark">
                        <h2>
                          {user
                            ? isMyProfile
                              ? 'My tagged components'
                              : `${user.name}'s tagged components`
                            : ''}
                        </h2>
                      </IonText>
                      <NarrowCardList
                        data={carDetails.components}
                        getImage={component => component.image}
                        getTitle={component => component.name}
                        getSubtitle={component => component.make}
                        getLink={component =>
                          COMPONENT_ROUTE + `${component.id}`
                        }
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Profile);

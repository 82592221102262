import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { IonText, withIonLifeCycle } from '@ionic/react';

import Gallery from '../Gallery/Gallery';
import FullscreenGallerySlider from '../FullscreenGallerySlider/FullscreenGallerySlider';
import CarDetailsCard from '../CardGroup/DetailsCardGroup/CarDetailsCard';
import LevelUpModal from '../LevelUpModal/LevelUpModal';
import CommentSection from '../../components/CommentGroup/CommentSection/CommentSection';

import './CarDetails.sass';

export const LevelUpVisibilityContext = React.createContext();

class CarDetails extends Component {
  state = {
    initialSlide: 0,
    showFullscreenGallery: false,
    showLevelUpModal: false,
  };

  componentDidMount() {
    this.onBackFromComponentScreen();
  }

  ionViewDidEnter() {
    this.onBackFromComponentScreen();
  }

  onBackFromComponentScreen = () => {
    const location = this.props.history.location;
    const {
      details: { images },
    } = this.props;
    const initialSlideProp =
      location.state && Number.isInteger(location.state.initialSlide)
        ? location.state.initialSlide
        : false;

    if (Number.isInteger(initialSlideProp)) {
      return this.setState({
        initialSlide: initialSlideProp,
        showFullscreenGallery: true,
      });
    }

    if (location?.state?.componentId && images) {
      const componentId = location.state.componentId;
      let initialSlide;

      for (let i = 0; i < images.length; i++) {
        if (!images[i].tags) continue;

        const index = images[i].tags.findIndex(
          (el) => el.componentId === componentId
        );

        if (index !== -1) {
          initialSlide = i;
          break;
        }
      }

      if (Number.isInteger(initialSlide)) {
        return this.setState({
          initialSlide,
          showFullscreenGallery: true,
        });
      }
    }
  };

  getIsNewLevel = () => {
    if (!this.props.details.components) return false;

    const componentAmount = this.props.details.components.length;

    return (
      componentAmount === 1 || componentAmount === 6 || componentAmount === 11
    );
  };

  render() {
    const { details, isOwner } = this.props;
    const {
      initialSlide,
      showFullscreenGallery,
      showLevelUpModal,
    } = this.state;
    let user = this.props.user;

    if (!user) {
      user = details.owner;
    }

    return (
      <>
        <IonText color="dark">
          <h1 className="CarDetails-title">{user.name}</h1>
        </IonText>
        <LevelUpVisibilityContext.Provider
          value={{
            openLevelUpModal: () => this.setState({ showLevelUpModal: true }),
            isNewLevel: this.getIsNewLevel(),
          }}
        >
          <FullscreenGallerySlider
            isOpen={showFullscreenGallery}
            withTagging={isOwner}
            components={details.components}
            images={details.images}
            initialSlide={initialSlide}
            closeFullscreenGallery={() =>
              this.setState({ showFullscreenGallery: false })
            }
          />
        </LevelUpVisibilityContext.Provider>
        <LevelUpModal
          isOpen={showLevelUpModal}
          hideModal={() => this.setState({ showLevelUpModal: false })}
          componentAmount={
            details && details.components ? details.components.length : 0
          }
        />
        <Gallery
          images={details.images}
          openFullscreenGallery={(initialSlide) =>
            this.setState({ initialSlide, showFullscreenGallery: true })
          }
        />
        <CarDetailsCard user={user} details={details} isOwner={isOwner} />
        {details.uid && (
          <CommentSection
            commentAmount={details.commentAmount}
            vehicleId={details.uid}
          />
        )}
      </>
    );
  }
}

export default withRouter(withIonLifeCycle(CarDetails));

import React from 'react';
import { IonSpinner } from '@ionic/react';

const CenteredSpinner = ({fullsize = true}) => {
  return (
    <div
      className="ion-margin-top ion-align-items-center ion-justify-content-center"
      style={{ display: 'flex', height: fullsize ? '100%' : 'auto' }}
    >
      <IonSpinner />
    </div>
  );
};

export default CenteredSpinner;

import React from 'react';
import { IonApp, setupConfig } from '@ionic/react';
import ReactDOM from 'react-dom';
import { IonReactRouter } from '@ionic/react-router';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import userReducer from './store/user/user';
import showroomReducer from './store/showroom/showroom';
import catalogueReducer from './store/catalogue/catalogue';
import notificationsReducer from './store/notifications/notifications';
import carReducer from './store/car/car';
import App from './App';

const rootReducer = combineReducers({
  user: userReducer,
  showroom: showroomReducer,
  catalogue: catalogueReducer,
  notifications: notificationsReducer,
  car: carReducer,
});
const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk)
);

setupConfig({ mode: 'ios' });
JavascriptTimeAgo.addLocale(en);

ReactDOM.render(
  <Provider store={store}>
    <IonApp>
      <IonReactRouter>
        <App />
      </IonReactRouter>
    </IonApp>
  </Provider>,
  document.getElementById('root')
);

import React, { useState } from 'react';
import {
  IonText,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonAlert,
} from '@ionic/react';

import { isEmail } from 'validator';
import { updateUserEmail } from '../../../api/firebase';

const MailSettings = ({
  startLoading,
  finishLoading,
  goBack,
}) => {
  const [showAlertMsg, setShowAlertMsg] = useState(false);

  let emailInput = null;

  const onSubmit = e => {
    e.preventDefault();

    const email = emailInput.value;

    if (!isEmail(email)) {
      setShowAlertMsg(true);
      return;
    }

    startLoading();

    updateUserEmail(email)
      .then(goBack)
      .catch(err => {
        alert(err.message);
        finishLoading();
      });
  };

  return (
    <>
      <IonAlert
        isOpen={showAlertMsg}
        onDidDismiss={() => setShowAlertMsg(false)}
        header="Warning"
        message="Wrong email address"
        buttons={['OK']}
      />
      <IonText>
        <h1>Mail</h1>
      </IonText>
      <form onSubmit={onSubmit}>
        <IonList>
          <IonItem className="Settings-input">
            <IonLabel color="primary" position="floating">
              Enter New Email <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              type="email"
              required={true}
              ref={input => (emailInput = input)}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton className="Settings-button" type="submit">
          Save Changes
        </IonButton>
      </form>
    </>
  );
};

export default MailSettings;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  IonContent,
  IonPage,
  IonText,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  withIonLifeCycle,
} from '@ionic/react';

import Header from '../../components/Header/Header';
import MyLikesCard from '../../components/CardGroup/MyLikesCard/MyLikesCard';
import MyLikesSkeleton from '../../components/SkeletonGroup/MyLikesSkeleton';

import { getMyLikes } from '../../api/firebase';
import { SHOWROOM_ROUTE } from '../../constants/routes';

export const segments = {
  CARS: 'vehicles',
  COMPONENT: 'components',
};

class MyLikes extends Component {
  state = {
    activeSegment: segments.CARS,
    likes: null,
    isLoading: true,
  };

  ionViewDidEnter() {
    if (this.props.isLoggedIn) {
      this.setState({ isLoading: true });
      this.fetchLikes();
    }
  }

  fetchLikes = () =>
    getMyLikes().then(likes => this.setState({ likes, isLoading: false }));

  handleUnlike = el => {
    const { activeSegment } = this.state;
    const likes = { ...this.state.likes };
    const activeLikeList = likes[activeSegment];
    const index = activeLikeList.indexOf(el);

    document.getElementById(`my-likes-${el.id}`).style.opacity = '0';

    setTimeout(() => {
      activeLikeList.splice(index, 1);

      this.setState({
        likes,
      });
    }, 300);
  };

  render() {
    const { isLoggedIn } = this.props;
    const { activeSegment, likes, isLoading } = this.state;

    return isLoggedIn ? (
      <IonPage>
        <Header goBack />
        <IonContent className="ion-padding">
          <IonText color="dark">
            <h1>My Likes</h1>
          </IonText>
          <IonSegment
            onIonChange={e => this.setState({ activeSegment: e.detail.value })}
            value={activeSegment}
          >
            <IonSegmentButton value={segments.CARS}>
              <IonLabel>Cars</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="components">
              <IonLabel>Components</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {!isLoading ? (
            likes &&
            likes[activeSegment].length ? (
              <MyLikesCard
                likes={likes[activeSegment]}
                activeSegment={activeSegment}
                onUnlike={this.handleUnlike}
              />
            ) : null
          ) : (
            <MyLikesSkeleton />
          )}
        </IonContent>
      </IonPage>
    ) : (
      <Redirect to={SHOWROOM_ROUTE} />
    );
  }
}

const mapStateToProps = store => ({
  isLoggedIn: store.user.isLoggedIn,
});

export default connect(mapStateToProps)(withIonLifeCycle(MyLikes));

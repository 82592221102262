import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import TagPopover from '../TagPopover/TagPopover';
import Fade from '../../utils/Fade';

import { COMPONENT_ROUTE } from '../../../constants/routes';
import './Tag.sass';

const Tag = ({
  data,
  editMode,
  showPopovers,
  component,
  parentSizes,
  imageIndex,
  onClick = null,
}) => {
  const history = useHistory();
  const position = {
    top: data.top,
    left: data.left,
  };

  const tag = (
    <div
      className="Tag"
      style={{
        ...position,
        backgroundColor: editMode
          ? 'var(--ion-color-warning)'
          : 'var(--ion-color-primary-contrast)',
      }}
      onClick={editMode ? onClick : null}
    ></div>
  );

  return !editMode ? (
    <Link
      to={{
        pathname: COMPONENT_ROUTE + `${data.componentId}`,
        state: {
          prevRoute: history.location.pathname,
          initialSlide: imageIndex,
        },
      }}
      onClick={onClick}
    >
      <Fade show={showPopovers}>
        <TagPopover
          parentSizes={parentSizes}
          position={position}
          name={component ? component.name : ''}
        />
      </Fade>
      {tag}
    </Link>
  ) : (
    tag
  );
};

export default Tag;

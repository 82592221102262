import React, { Component } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import Header from '../../components/Header/Header';
import CenteredSpinner from '../../components/CenteredSpinner/CenteredSpinner';
import {
  SHOWROOM_ROUTE,
  WELCOME_ROUTE,
  NEW_PASSWORD_ROUTE,
} from '../../constants/routes';

const EMAIL_VERIFICATION_TYPE = 'verifyEmail';
const RESET_PASSWORD_TYPE = 'resetPassword';

class Action extends Component {
  state = {
    isLoading: false,
    showAlertMessage: false,
  };

  componentDidMount() {
    const { history } = this.props;
    const url = new URL(window.location.href);
    const type = url.searchParams.get('mode');
    const actionCode = url.searchParams.get('oobCode');

    if (!actionCode) {
      history.push(SHOWROOM_ROUTE);
    }

    switch (type) {
      case EMAIL_VERIFICATION_TYPE:
        return history.push({
          pathname: WELCOME_ROUTE,
          state: { actionCode },
        });
      case RESET_PASSWORD_TYPE:
        return history.push({
          pathname: NEW_PASSWORD_ROUTE,
          state: { actionCode },
        });
      default:
        return history.push(SHOWROOM_ROUTE);
    }
  }

  render() {
    return (
      <IonPage>
        <Header />
        <IonContent className="Action">
          <CenteredSpinner />
        </IonContent>
      </IonPage>
    );
  }
}

export default Action;

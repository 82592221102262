import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-database';
import 'firebase/storage';

import { updateUserProfile } from './api';

const config = {
  apiKey: 'AIzaSyCQwmIzap7FpvSZgD1ehSDFEBXjFLBjWB4',
  authDomain: 'tuningwelt-141809.firebaseapp.com',
  databaseURL: 'https://tuningwelt-141809.firebaseio.com',
  projectId: 'tuningwelt-141809',
  storageBucket: 'tuningwelt-141809.appspot.com',
  messagingSenderId: '32912855951',
  appId: '1:32912855951:web:d05a62cf62e1d06b8871fa',
  measurementId: 'G-4RMJ7WM2LD',
};
firebase.initializeApp(config);
export const auth = firebase.auth();
const db = firebase.database();
const storage = firebase.storage();
export default firebase;

export const uploadImage = (path, name, imageBase64) =>
  storage.ref(path + name).putString(imageBase64, 'base64');

export const getImageURL = (path, name) =>
  storage.ref(path + name).getDownloadURL();

// The only way to check that currentPassword is correct
export const reauthenticate = (currentPassword) => {
  const user = auth.currentUser;
  const cred = firebase.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword
  );
  return user.reauthenticateAndRetrieveDataWithCredential(cred);
};

export const verifyUserEmail = (actionCode) => auth.applyActionCode(actionCode);

export const setPasswordAfterReset = (actionCode, newPassword) =>
  auth
    .confirmPasswordReset(actionCode, newPassword)
    .catch((err) => err.message);

export const getUserToken = () => {
  const currentUser = auth.currentUser;

  if (!currentUser) return;

  return auth.currentUser.getIdToken();
};

export const isEmailVerified = () => {
  const currentUser = auth.currentUser;

  if (!currentUser) return false;

  return auth.currentUser.emailVerified;
};

export const getCurrentUser = () => auth.currentUser;

export const getCurrentUserUid = () =>
  auth.currentUser ? auth.currentUser.uid : null;

export const updateUserPassword = (oldPassword, newPassword) => {
  const user = auth.currentUser;
  return reauthenticate(oldPassword)
    .then(() => user.updatePassword(newPassword))
    .catch((err) => alert(err.message));
};

export const updateUserEmail = (emailAddress) => {
  const user = auth.currentUser;
  return user
    .updateEmail(emailAddress)
    .then(() => updateUserProfile({ email: emailAddress }))
    .catch((err) => alert(err.message));
};

export const resetPassword = (email) => auth.sendPasswordResetEmail(email);

/* !---* DIRECT DATA REQUESTS *---! */

/* --- USER --- */
export const getUserRef = (uid) => db.ref(`users/${uid}`);
export const getCurrentUserRef = () => getUserRef(getCurrentUserUid());
export const getUser = (uid) =>
  getUserRef(uid)
    .once('value')
    .then((data) => data.val());
export const getUserProfile = (uid) => {
  let isSucceed = false;
  let result = null;

  return getUser(uid)
    .then((userData) => {
      if (!userData) {
        return (isSucceed = true);
      }

      const { name, profileImage, location, followers, following, verified } =
        userData;

      result = {
        user: {
          name,
          profileImage,
          location,
          stats: {
            likes: 0,
          },
          followers: followers ? Object.values(followers).length : 0,
          following: following ? Object.values(following).length : 0,
          verified,
        },
      };

      return getCarByUser(uid);
    })
    .then((data) => {
      if (isSucceed) return;

      if (!data) {
        return;
      }

      const [vehcileId, vehicle] = Object.entries(data)[0];
      const { images, make, model, type } = vehicle;

      result.car = {
        uid: vehcileId,
        image: images[0].src,
        make,
        model,
        type,
      };

      if (vehicle.likes) {
        result.user.stats.likes += Object.values(vehicle.likes).length;
      }

      return getComponentsByCar(vehcileId);
    })
    .then((data) => {
      if (isSucceed) return null;

      if (data) {
        const components = Object.entries(data);

        result.car.components = components.map(([id, component]) => {
          const { image, name, make } = component;

          if (component.likes) {
            result.user.stats.likes += Object.values(component.likes).length;
          }

          return { id, image, name, make };
        });
      }

      return result;
    })
    .catch((err) => alert(err.message));
};

/* --- SHOWROOM --- */
export const showroomRef = () => db.ref('vehicles');

export const fetchShowroomCars = (lastVehicleId) => {
  const result = [];
  const promise = lastVehicleId
    ? db
        .ref('vehicles')
        .orderByKey()
        .startAt(lastVehicleId)
        .limitToFirst(11)
        .once('value')
    : db.ref('vehicles').orderByKey().limitToFirst(10).once('value');

  return promise
    .then((data) => {
      data = data.val();

      if (!data) {
        return result;
      }

      delete data[lastVehicleId];

      const vehicles = Object.entries(data);

      return Promise.all(
        vehicles.map(([id, vehicle], index) => {
          const image = vehicle.images[0].src;
          const { model, make, type, likes, owner } = vehicle;

          return getUser(owner).then((user) => {
            return (result[index] = {
              id,
              image,
              model,
              make,
              type,
              likes,
              owner: {
                uid: owner,
                name: user.name,
              },
            });
          });
        })
      );
    })
    .then(() => result)
    .catch((err) => alert(err.message));
};

/* --- CAR --- */
export const getCarRef = (carId) => db.ref(`vehicles/${carId}`);
export const getCar = (carId) =>
  db
    .ref(`vehicles/${carId}`)
    .once('value')
    .then((data) => data.val());

export const getCarByUser = (uid) =>
  db
    .ref('vehicles')
    .orderByChild('owner')
    .equalTo(uid)
    .once('value')
    .then((data) => data.val());

export const getCurrentUserCarRef = () =>
  db.ref('vehicles').orderByChild('owner').equalTo(getCurrentUserUid());

export const getCurrentUserCar = () =>
  db
    .ref('vehicles')
    .orderByChild('owner')
    .equalTo(getCurrentUserUid())
    .once('value')
    .then((data) => data.val());

export const getCarProfile = (carId) => {
  let result = null;
  let isSucceed = false;

  return getCarRef(carId)
    .once('value')
    .then((data) => {
      if (!data) {
        return null;
      }

      const vehicle = data.val();

      if (!vehicle) {
        return (isSucceed = true);
      }

      result = {
        ...vehicle,
      };

      return getComponentsByCar(carId);
    })
    .then((components) => {
      if (isSucceed) return;

      if (components) {
        result.components = Object.entries(components).map(
          ([id, component]) => {
            const { image, make, name } = component;
            return { id, image, make, name };
          }
        );
      }

      return getUser(result.owner);
    })
    .then((user) => {
      if (isSucceed || !user) return null;

      result.owner = {
        uid: result.owner,
        name: user.name,
        profileImage: user.profileImage,
        location: user.location,
        verified: user.verified,
      };

      return result;
    })
    .catch((err) => alert(err.message));
};

/* --- COMPONENTS AND TAGS --- */
export const getComponentRef = (componentId) =>
  db.ref(`components/${componentId}`);
export const getComponentsByCar = (carId) =>
  db
    .ref('components')
    .orderByChild('vehicle')
    .equalTo(carId)
    .once('value')
    .then((data) => data.val());

export const catalogueRef = () => db.ref('components');

export const getAllComponents = (lastComponentId) => {
  const result = [];
  const promise = lastComponentId
    ? db
        .ref('components')
        .orderByKey()
        .startAt(lastComponentId)
        .limitToFirst(11)
        .once('value')
    : db.ref('components').orderByKey().limitToFirst(10).once('value');

  return promise
    .then((data) => {
      data = data.val();

      if (!data) {
        return result;
      }

      delete data[lastComponentId];

      const components = Object.entries(data);

      return Promise.all(
        components.map(([id, component], index) => {
          const { image, name, price, category, likes, vehicle } = component;

          return getCar(vehicle).then((vehicle) => {
            return (result[index] = {
              id,
              image,
              price,
              name,
              category,
              likes,
              owner: vehicle ? vehicle.owner : null,
            });
          });
        })
      );
    })
    .then(() => result)
    .catch((err) => alert(err.message));
};

export const getComponent = (componentId) => {
  if (!componentId) {
    return null;
  }

  return getComponentRef(componentId)
    .once('value')
    .then((data) => {
      const component = data.val();

      if (!component) {
        return null;
      }

      component.id = componentId;

      return component;
    })
    .catch((err) => alert(err.message));
};

/* --- LIKES --- */
export const getMyLikes = () => {
  let likes = null;
  const result = {
    components: [],
    vehicles: [],
  };

  return db
    .ref(`users/${getCurrentUserUid()}/likes`)
    .once('value')
    .then((data) => {
      likes = data.val();

      if (!likes || !likes.components) {
        return null;
      }

      const componentIds = Object.keys(likes.components);

      return Promise.all(
        componentIds.map((componentId, index) =>
          getComponent(componentId).then((component) => {
            if (!component) return;

            const { id, name, make, image } = component;

            return (result.components[index] = {
              id,
              name,
              make,
              image,
            });
          })
        )
      );
    })
    .then(() => {
      if (!likes || !likes.vehicles) {
        return null;
      }

      const vehicleIds = Object.keys(likes.vehicles);

      return Promise.all(
        vehicleIds.map((vehicleId, index) =>
          getCar(vehicleId).then((vehicle) => {
            if (!vehicle) return;

            const { model, type, make, images } = vehicle;

            result.vehicles[index] = {
              id: vehicleId,
              model,
              type,
              make,
              image: images[0].src,
            };
          })
        )
      );
    })
    .then(() => result);
};

/* --- COMMENTS --- */
export const getCommentRef = (path) => {
  let ref;

  if (path.componentId) {
    ref = `comments/components/${path.componentId}`;
  } else {
    ref = `comments/vehicles/${path.vehicleId}`;
  }

  return db.ref(ref);
};

const formReplies = (replies) =>
  Promise.all(
    replies.map(([id, reply], index) =>
      getUser(reply.user).then((user) => {
        reply.user = user
          ? {
              profileImage: user.profileImage,
              name: user.name,
              uid: reply.user,
              verified: user.verified,
            }
          : {};
        reply.id = id;

        replies[index] = reply;
      })
    )
  ).then(() => replies);

export const formNewComment = (newComment) =>
  getUser(newComment.user).then((user) => {
    newComment.user = user
      ? {
          profileImage: user.profileImage,
          name: user.name,
          uid: newComment.user,
          verified: user.verified,
        }
      : {};

    if (newComment.replies) {
      const replies = Object.entries(newComment.replies);

      return formReplies(replies).then((formedReplies) => {
        newComment.replies = formedReplies;

        return newComment;
      });
    }

    return newComment;
  });

export const getComments = ({ path, lastCommentId }) => {
  let ref = null;
  let comments;

  if (path.componentId) {
    ref = `comments/components/${path.componentId}`;
  } else {
    ref = `comments/vehicles/${path.vehicleId}`;
  }

  const promise = lastCommentId
    ? db
        .ref(ref)
        .orderByKey()
        .endAt(lastCommentId)
        // 1 existing + 10 new
        .limitToFirst(11)
        .once('value')
    : db.ref(ref).limitToLast(10).once('value');

  return promise
    .then((data) => {
      data = data.val();

      if (data) {
        delete data[lastCommentId];
        comments = Object.entries(data).reverse();
      } else {
        comments = [];
      }

      return Promise.all(
        comments.map(([id, comment], index) =>
          getUser(comment.user).then((user) => {
            comment.user = user
              ? {
                  profileImage: user.profileImage,
                  name: user.name,
                  verified: user.verified,
                  uid: comment.user,
                }
              : {};

            comment.id = id;

            if (comment.replies) {
              const replies = Object.entries(comment.replies);

              return formReplies(replies).then((formedReplies) => {
                comment.replies = formedReplies;
                comments[index] = comment;
              });
            }

            return (comments[index] = comment);
          })
        )
      ).then(() => ({ comments }));
    })
    .catch((err) => alert(err.message));
};

/* --- FOLLOWING --- */
export const getFollowing = (uid, type) => {
  const result = [];
  let followingList = null;

  const types = ['followers', 'following'];

  if (types.indexOf(type) === -1) {
    return;
  }

  return db
    .ref(`users/${uid}/${type}`)
    .once('value')
    .then((data) => {
      followingList = data.val();

      if (!followingList) return;

      const userIds = Object.keys(followingList);

      return Promise.all(
        userIds.map((uid) => {
          let name;
          let profileImage;

          return getUser(uid)
            .then((user) => {
              if (!user) return;

              name = user.name;
              profileImage = user.profileImage;

              return getCarByUser(uid);
            })
            .then((data) => {
              const vehicle = data ? Object.values(data)[0] : {};
              const { model, type } = vehicle;
              const profile = {
                name,
                profileImage,
                uid,
                model,
                type,
              };

              result.push(profile);
            });
        })
      );
    })
    .then(() => result)
    .catch((err) => alert(err.message));
};

/* --- NOTIFICATIONS --- */
export const getNotificationRef = () =>
  db.ref(`notifications/${getCurrentUserUid()}/list`);
export const getNotificationSettingsRef = () =>
  db.ref(`notifications/${getCurrentUserUid()}/settings`);
export const getNotificationSettings = () =>
  getNotificationSettingsRef()
    .once('value')
    .then((data) => data.val());

export const getNotifications = (lastNotificationId) => {
  const ref = `notifications/${getCurrentUserUid()}/list`;
  const getNotificationChunkRef = lastNotificationId
    ? db
        .ref(ref)
        .orderByKey()
        .endAt(lastNotificationId)
        // 1 existing + 10 new
        .limitToFirst(11)
    : db.ref(ref).orderByKey().limitToLast(10);

  return getNotificationChunkRef
    .once('value')
    .then((data) => {
      data = data.val();

      if (!data) return;

      delete data[lastNotificationId];

      const notificationEntries = data ? Object.entries(data).reverse() : [];
      const notifications = notificationEntries.map(([id, notification]) => {
        notification.id = id;
        return notification;
      });

      return notifications;
    })
    .catch((err) => alert(err.message));
};

export const readNotifications = () => {
  const uid = getCurrentUserUid();

  return db
    .ref(`notifications/${uid}/list`)
    .orderByChild('seen')
    .equalTo(false)
    .once('value')
    .then((data) => {
      const unSeenNotifications = data.val();

      if (unSeenNotifications) {
        for (let notification in unSeenNotifications) {
          db.ref(`notifications/${uid}/list/${notification}/seen`).set(true);
        }
      }
    })
    .catch((err) => {
      alert(err.message);
    });
};

/* --- FAQ --- */
export const getFAQRef = () => db.ref('faq');
export const getFAQ = () =>
  db
    .ref('faq')
    .once('value')
    .then((data) => data.val());

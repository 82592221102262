import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonText,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  withIonLifeCycle,
} from '@ionic/react';

import Header from '../../components/Header/Header';
import Toolbar from '../../components/Toolbar/Toolbar';
import ShowroomCarCard from '../../components/CardGroup/ShowroomCarCard/ShowroomCarCard';
import ShowroomCatalogueSkeleton from '../../components/SkeletonGroup/ShowroomCatalogueSkeleton';
import MasonryGrid from '../../components/MasonryGrid/MasonryGrid';
import SortingModal from '../../components/SortingModal/SortingModal';
import Fade from '../../components/utils/Fade';

import { fetchShowroomCarsAction } from '../../store/showroom/actions';
import {
  SHOWROOM_VIEW_MODE_STORAGE_PROPERTY,
  viewModes,
} from '../../constants/constants';

class Showroom extends Component {
  ionContent = React.createRef();

  state = {
    viewMode: viewModes.TWO_COLUMNS,
    showSortingModal: false,
  };

  componentDidMount() {
    const viewMode = localStorage.getItem(SHOWROOM_VIEW_MODE_STORAGE_PROPERTY);

    this.setState({ viewMode });
    this.fetchCars();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSameActiveTab !== this.props.isSameActiveTab &&
      this.props.isSameActiveTab
    ) {
      this.ionContent.current.scrollToTop(500);
      this.props.resetSecondTabClick();
    }
  }

  fetchCars = e => {
    const lastCar = this.props.cars[this.props.cars.length - 1];

    return this.props
      .fetchShowroomCars(lastCar ? lastCar.id : null)
      .then(() => (e ? e.target.complete() : true));
  };

  onRefresh = e =>
    // need this timeout as a workaround for a common bug on iOS (spinner donesn't disappear)
    setTimeout(
      () => this.props.fetchShowroomCars().then(() => e.detail.complete()),
      500
    );

  handleViewModeChange = viewMode => {
    localStorage.setItem(SHOWROOM_VIEW_MODE_STORAGE_PROPERTY, viewMode);
    this.setState({ viewMode });
  };

  render() {
    const { cars, isAllCarsFetched, isLoading } = this.props;
    const { viewMode, showSortingModal } = this.state;

    return (
      <IonPage>
        <Header />
        <IonContent ref={this.ionContent} className="ion-padding">
          <IonRefresher slot="fixed" onIonRefresh={this.onRefresh}>
            <IonRefresherContent className="infinite-spinner" />
          </IonRefresher>
          <SortingModal
            isOpen={showSortingModal}
            onClose={() => this.setState({ showSortingModal: false })}
          />
          <Fade>
            <IonText color="dark">
              <h1 style={{ transform: 'translateX(3px)' }}>Showroom</h1>
            </IonText>
            <Toolbar
              viewMode={viewMode}
              onViewModeChange={this.handleViewModeChange}
              openSortingModal={() => this.setState({ showSortingModal: true })}
            />
            <MasonryGrid columns={viewMode === viewModes.TWO_COLUMNS ? 2 : 1}>
              {isLoading
                ? [
                    ...new Array(viewMode === viewModes.TWO_COLUMNS ? 6 : 2),
                  ].map((_, index) => (
                    <ShowroomCatalogueSkeleton
                      key={index}
                      shrinked={viewMode === viewModes.TWO_COLUMNS}
                    />
                  ))
                : cars.map((car, index) => (
                    <ShowroomCarCard
                      key={index}
                      shrinked={viewMode === viewModes.TWO_COLUMNS}
                      car={car}
                    />
                  ))}
            </MasonryGrid>
            <IonInfiniteScroll
              threshold="100px"
              disabled={isAllCarsFetched}
              onIonInfinite={this.fetchCars}
            >
              <IonInfiniteScrollContent
                className="infinite-spinner"
                loading-spinner="dots"
              />
            </IonInfiniteScroll>
          </Fade>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = store => ({
  cars: store.showroom.cars,
  isLoading: store.showroom.isLoading,
  isAllCarsFetched: store.showroom.isAllCarsFetched,
});

const mapDispatchToProps = dispatch => ({
  fetchShowroomCars: lastVehicleId =>
    dispatch(fetchShowroomCarsAction(lastVehicleId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withIonLifeCycle(Showroom));

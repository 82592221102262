import React from 'react';
import { Link } from 'react-router-dom';
import { IonItem, IonMenuToggle, IonIcon } from '@ionic/react';

const MenuItem = ({ className = '', onClick, link, icon, children }) => {
  return link ? (
    <Link to={link} onClick={onClick}>
      <IonMenuToggle menu="menu">
        <IonItem className={`Menu__item ${className} ion-item-padding-start`}>
          <IonIcon icon={icon} color="primary" slot="start" />
          {children}
        </IonItem>
      </IonMenuToggle>
    </Link>
  ) : (
    <IonMenuToggle menu="menu">
      <IonItem className={`Menu__item ${className}`}>
        <IonIcon icon={icon} slot="start" />
        {children}
      </IonItem>
    </IonMenuToggle>
  );
};

export default MenuItem;

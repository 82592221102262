import React from 'react';
import { IonItem, IonToggle, IonLabel } from '@ionic/react';

import { vibrate } from '../../utils/vibrate';

const SettingToggle = ({ isChecked, name, onChange, type }) => {
  return (
    <IonItem className="Settings-input">
      <IonLabel className="ion-text-wrap">{name}</IonLabel>
      <IonToggle
        checked={isChecked}
        onIonChange={(e) => {
          vibrate();
          onChange(e.detail.checked, type);
        }}
      />
    </IonItem>
  );
};

export default SettingToggle;

import React from 'react';
import { IonIcon } from '@ionic/react';

import './IconWithBadge.sass';

const IconWithBadge = ({ icon, showBadge = true }) => {
  return <IonIcon className={showBadge ? 'IconWithBadge' : ''} icon={icon} />;
};

export default IconWithBadge;

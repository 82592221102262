import React, { Component } from 'react';
import { IonList } from '@ionic/react';

import Comment from '../Comment/Comment';

class CommentList extends Component {
  render() {
    const {
      comments,
      onReply,
      vehicleId,
      componentId,
    } = this.props;

    return (
      <IonList className="CommentList">
        {comments.map(comment => (
          <div key={comment.id}>
            <Comment
              comment={comment}
              onReply={onReply}
              vehicleId={vehicleId}
              componentId={componentId}
            />
            {comment.replies
              ? comment.replies.map(reply => (
                  <Comment
                    key={reply.id}
                    comment={reply}
                    onReply={onReply}
                    parentCommentId={comment.id}
                    vehicleId={vehicleId}
                    componentId={componentId}
                  />
                ))
              : null}
          </div>
        ))}
      </IonList>
    );
  }
}

export default CommentList;

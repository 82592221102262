import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';

import CommentInput from '../CommentInput/CommentInput';
import CommentList from '../CommentList/CommentList';

import { addComment } from '../../../api/api';
import {
  getComments,
  formNewComment,
  getCommentRef,
} from '../../../api/firebase';

class CommentSection extends Component {
  state = {
    comments: [],
    replyTo: null,
    isSubmitting: false,
  };
  commentInputRef = React.createRef();

  componentDidMount() {
    const path = {
      vehicleId: this.props.vehicleId,
      componentId: this.props.componentId,
    };

    this.fetchComments(null, true).then(() =>
      getCommentRef(path)
        .limitToLast(1)
        .on('child_added', data =>
          this.unshiftNewComment({ ...data.val(), id: data.key })
        )
    );

    getCommentRef(path).on('child_changed', data =>
      this.updateComment({ ...data.val(), id: data.key })
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.vehicleId !== this.props.vehicleId ||
      prevProps.componentId !== this.props.componentId ||
      prevProps.commentAmount !== this.props.commentAmount
    ) {
      this.fetchComments(null, true);
    }
  }

  componentWillUnmount() {
    const path = {
      vehicleId: this.props.vehicleId,
      componentId: this.props.componentId,
    };

    getCommentRef(path).off('child_added');
    getCommentRef(path).off('child_changed');
  }

  fetchComments = (e, isRefresh = false) => {
    const { comments } = this.state;
    const lastComment = comments[comments.length - 1];
    const path = {
      path: {
        vehicleId: this.props.vehicleId,
        componentId: this.props.componentId,
      },
      lastCommentId: !isRefresh && lastComment ? lastComment.id : null,
    };

    return getComments(path).then(({ comments }) => {
      if (
        this.props.commentAmount <
        this.state.comments.length + comments.length
      ) {
        return;
      }

      return this.setState(
        prevState => {
          if (!isRefresh) {
            const seen = new Set();
            const updatedComments = [...prevState.comments, ...comments].filter(
              el => {
                const duplicate = seen.has(el.id);
                seen.add(el.id);
                return !duplicate;
              }
            );

            return {
              comments: updatedComments,
            };
          } else {
            return {
              comments,
            };
          }
        },
        () => (e ? e.target.complete() : true)
      );
    });
  };

  unshiftNewComment = newComment => {
    const latestCommentId = this.state.comments[0]
      ? this.state.comments[0].id
      : false;

    if (latestCommentId && newComment.id === latestCommentId) return;

    formNewComment(newComment).then(newFormedComment =>
      this.setState(prevState => ({
        comments: [newFormedComment, ...prevState.comments],
      }))
    );
  };

  updateComment = newComment => {
    const { comments } = this.state;
    const updatedCommentIndex = comments.findIndex(
      comment => comment.id === newComment.id
    );

    if (updatedCommentIndex === -1) return;

    formNewComment(newComment).then(newFormedComment => {
      const updatedComments = [...comments];

      updatedComments[updatedCommentIndex] = newFormedComment;

      this.setState({
        comments: updatedComments,
      });
    });
  };

  handleOnSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    const { replyTo } = this.state;
    const comment = {
      path: {
        vehicleId: this.props.vehicleId,
        componentId: this.props.componentId,
      },
      comment: this.commentInputRef.current.value,
    };

    if (replyTo) {
      comment.parentCommentId = replyTo;
    }

    return addComment(comment).then(() => {
      this.setState(
        { replyTo: null, isSubmitting: false },
        () => (this.commentInputRef.current.value = '')
      );
      this.fetchComments(null, true);
    });
  };

  handleOnReply = parentCommentId => {
    this.setState({ replyTo: parentCommentId }, () =>
      this.commentInputRef.current.getInputElement().then(input => {
        input.focus();
        window.scrollTo(0, input.offsetTop);
      })
    );
  };

  handleReplyReset = () => this.setState({ replyTo: null });

  render() {
    const { isLoggedIn, commentAmount, vehicleId, componentId } = this.props;
    const { replyTo, comments, isSubmitting } = this.state;

    return (
      <>
        <CommentInput
          isLoggedIn={isLoggedIn}
          inputRef={this.commentInputRef}
          onSubmit={this.handleOnSubmit}
          replyTo={replyTo}
          onReplyReset={this.handleReplyReset}
          isSubmitting={isSubmitting}
        />
        <CommentList
          comments={comments}
          onReply={this.handleOnReply}
          vehicleId={vehicleId}
          componentId={componentId}
        />
        <IonInfiniteScroll
          style={{ marginTop: '1rem' }}
          threshold="100px"
          disabled={comments.length === commentAmount}
          onIonInfinite={this.fetchComments}
        >
          <IonInfiniteScrollContent
            className="infinite-spinner"
            loading-spinner="dots"
          />
        </IonInfiniteScroll>
      </>
    );
  }
}

const mapStateToProps = store => ({
  isLoggedIn: store.user.isLoggedIn,
});

export default connect(mapStateToProps)(CommentSection);

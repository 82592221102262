import * as actions from './actions';

const initialState = {
  components: [],
  isLoading: false,
  isAllComponentsFetched: false,
};

const catalogueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING_START:
      return { ...state, isLoading: true };
    case actions.FETCH_COMPONENTS: {
      const { isRefresh, components } = action.payload;

      return {
        ...state,
        components: isRefresh
          ? components
          : [...state.components, ...components],
        isLoading: false,
      };
    }
    case actions.CATALOGUE_UPDATE_COMPONENT: {
      const updatedComponentData = action.payload;
      const updatedComponents = [...state.components];
      const index = updatedComponents.findIndex(
        component => component.id === updatedComponentData.id
      );

      if (index === -1) {
        return state;
      }

      const componentToUpdate = updatedComponents[index];
      const { image, name, price, category, likes } = updatedComponentData;

      componentToUpdate.image = image;
      componentToUpdate.name = name;
      componentToUpdate.price = price;
      componentToUpdate.category = category;
      componentToUpdate.likes = likes;

      return {
        ...state,
        components: updatedComponents,
      };
    }
    case actions.ALL_COMPONENTS_FETCHED:
      return {
        ...state,
        isAllComponentsFetched: true,
        isLoading: false,
      };
    case actions.LOADING_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default catalogueReducer;

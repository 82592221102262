import * as actions from './actions';

const initialState = {
  isLoggedIn: false,
  isLoading: false,
  user: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOADING_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case actions.LOG_USER_IN:
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        user: { ...action.payload },
      };
    case actions.LOG_USER_OUT:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        user: null,
      };
    case actions.UPDATE_USER_NOTIFCATION_SETTINGS: {
      if (!state.user?.notifications) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          notifications: {
            ...state.user.notifications,
            settings: action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;

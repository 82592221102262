import * as actions from './actions';

const initialState = {
  cars: [],
  isLoading: false,
  isAllCarsFetched: false,
};

const showroomReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING_START:
      return { ...state, isLoading: true };
    case actions.FETCH_VEHICLES:
      const { isRefresh, cars } = action.payload;

      return {
        ...state,
        cars: isRefresh ? cars : [...state.cars, ...cars],
        isLoading: false,
      };
    case actions.ALL_CARS_FETCHED:
      return {
        ...state,
        isAllCarsFetched: true,
        isLoading: false,
      };
    case actions.SHOWROOM_UPDATE_VEHICLE: {
      const updatedCarData = action.payload;
      const updatedCars = [...state.cars];
      const index = updatedCars.findIndex(car => car.id === updatedCarData.id);

      if (index === -1) {
        return state;
      }

      const carToUpdate = updatedCars[index];
      const { model, make, type, likes } = updatedCarData;

      carToUpdate.model = model;
      carToUpdate.make = make;
      carToUpdate.type = type;
      carToUpdate.likes = likes;
      carToUpdate.image = updatedCarData.images[0].src;

      return {
        ...state,
        cars: updatedCars,
      };
    }
    case actions.LOADING_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default showroomReducer;

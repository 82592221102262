import React from 'react';
import {
  IonCard,
  IonSkeletonText,
  IonCardContent,
  IonThumbnail,
} from '@ionic/react';

const ShowroomCatalogueSkeleton = ({ shrinked }) => {
  return (
    <IonCard style={{ margin: 0 }}>
      <IonThumbnail
        style={{ width: '100%', height: shrinked ? '128px' : '200px' }}
      >
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonCardContent>
        <IonSkeletonText animated />
        <IonSkeletonText animated style={{ width: '88%' }} />
        {!shrinked && (
          <>
            <IonSkeletonText animated style={{ width: '70%' }} />
            <IonSkeletonText animated style={{ width: '60%' }} />
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default ShowroomCatalogueSkeleton;

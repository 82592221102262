import React from 'react';
import { Link } from 'react-router-dom';
import {
  IonCard,
  IonList,
  IonItem,
  IonCardContent,
  IonLabel,
} from '@ionic/react';

import FollowButton from '../../ButtonGroup/FollowButton/FollowButton';

import { getCurrentUserUid } from '../../../api/firebase';
import './FollowersCard.sass';

const FollowersCard = ({ followers, withAnimation = false, onUnfollow }) => {
  return followers && followers.length ? (
    <IonCard className="FollowersCard">
      <IonCardContent>
        <IonList>
          {followers.map(profile => (
            <IonItem
              className="FollowersCard-item"
              key={profile.uid}
              id={withAnimation ? `following-${profile.uid}` : null}
            >
              <Link to={'/user/' + profile.uid} className="Link">
                <div className="FollowersCard-item-wrapper">
                  <img
                    src={profile.profileImage}
                    className="FollowersCard-item__image"
                    slot="start"
                    alt="User"
                  />
                  <IonLabel className="FollowersCard-item__info" color="medium">
                    <h3>{profile.name}</h3>
                    {profile.type ? `${profile.model}, ${profile.type}` : null}
                  </IonLabel>
                </div>
              </Link>
              {profile.uid !== getCurrentUserUid() && (
                <div
                  className="FollowersCard-item__btn"
                  slot="end"
                  onClick={
                    withAnimation && onUnfollow
                      ? () => onUnfollow(profile)
                      : null
                  }
                >
                  <FollowButton profileId={profile.uid} />
                </div>
              )}
            </IonItem>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  ) : null;
};

export default FollowersCard;

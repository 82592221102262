import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IonText, IonIcon, IonButton } from '@ionic/react';
import { flameOutline, flame } from 'ionicons/icons';

import { vibrate } from '../../../utils/vibrate';
import { toggleLike } from '../../../api/api';
import { LOGIN_ROUTE } from '../../../constants/routes';
import './LikeButton.sass';

const types = {
  DEFAULT: 'default',
  COMMENT: 'comment',
  ICON: 'icon',
};

const LikeButton = ({
  path,
  color = 'dark',
  size = 'default',
  type = types.DEFAULT,
  disabled,
  isLoggedIn,
  total,
  isLiked,
}) => {
  const likeIcon = useRef(null);

  const onClick = () => {
    vibrate();

    if (!isLoggedIn || !path) {
      return;
    }
    
    const icon = likeIcon.current;

    if (disabled) {
      icon.classList.add('disabled');
      return setTimeout(() => {
        icon.classList.remove('disabled');
      }, 500);
    }

    if (!isLiked) {
      // Button is liked
      icon.classList.add('liked');
    } else {
      // Button is unliked
      icon.classList.remove('liked');
    }

    return toggleLike({ ...path });
  };

  const likeButton = (
    <IonButton
      className="LikeButton"
      slot="end"
      fill="clear"
      color={color}
      size="small"
      onClick={onClick}
    >
      <IonIcon
        ref={likeIcon}
        className={type === types.ICON ? 'LikeButton--big-icon' : ''}
        icon={isLiked ? flame : flameOutline}
        color={isLiked ? 'fire' : color}
        size={size}
      />
      {type !== types.ICON && (
        <IonText
          className={type === types.COMMENT ? '' : 'LikeButton__amount'}
          color={color}
        >
          {total}
        </IonText>
      )}
    </IonButton>
  );

  return !isLoggedIn ? <Link to={LOGIN_ROUTE}>{likeButton}</Link> : likeButton;
};

const mapStateToProps = (store) => ({
  isLoggedIn: store.user.isLoggedIn,
});

export default connect(mapStateToProps)(LikeButton);

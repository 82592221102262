const notificationTypes = {
  NEW_FOLLOWER: 'new follower',
  NEW_VEHICLE_COMMENT: 'new vehicle comment',
  NEW_COMPONENT_COMMENT: 'new component comment',
  NEW_VEHICLE_LIKE: 'new vehicle like',
  NEW_COMPONENT_LIKE: 'new component like',
  NEW_COMMENT_LIKE: 'new comment like',
  NEW_FOLLOWING_IMAGE: 'new following image',
  NEW_FOLLOWING_COMPONENT: 'new following component',
  NEW_FOLLOWING_LEVEL: 'new following level',
  FOLLOWING_LIKES_VEHICLE: 'following likes vehicle',
  FOLLOWING_LIKES_COMPONENT: 'following likes component',
  FOLLOWING_LIKES_COMMENT: 'following likes comment',
  FOLLOWING_COMMENTS_VEHICLE: 'following comments vehicle',
  FOLLOWING_COMMENTS_COMPONENT: 'following comments component',
};

export default notificationTypes;

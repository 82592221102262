import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
const { App } = Plugins;

const AppUrlListener = () => {
  let history = useHistory();

  useEffect(() => {
    App.addListener('appUrlOpen', data => {
      const slug = data.url.split('.net').pop();
      if (slug) {
        history.push(slug);
      }
    });
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default AppUrlListener;

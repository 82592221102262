import * as routes from './routes';
import axios from 'axios';
import { getUserToken } from './firebase';

const callAPI = (path, data, token = '') => {
  const headers = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  if (data) {
    return axios.post(path, data, headers).then((res) => res.data);
  }

  return axios.get(path, headers).then((res) => res.data);
};

/* --- USER --- */
export const createNewUser = (userData) =>
  getUserToken().then((token) =>
    callAPI(routes.CREATE_NEW_USER, userData, token)
  );
export const updateUserProfile = (userProfile) =>
  getUserToken().then((token) =>
    callAPI(routes.UPDATE_USER_PROFILE, { userProfile }, token)
  );
export const setUserFcmToken = (fcmToken) =>
  getUserToken().then((token) =>
    callAPI(routes.SET_USER_FCM_TOKEN, { fcmToken }, token)
  );
export const deleteUserProfile = () =>
  getUserToken().then((token) =>
    callAPI(routes.DELETE_USER_PROFILE, null, token)
  );

/* --- CAR --- */
export const getAllCarModels = (data) =>
  getUserToken().then((token) =>
    callAPI(routes.GET_ALL_CAR_MODELS, { data }, token)
  );
export const postNewCar = (carDetails) =>
  getUserToken().then((token) =>
    callAPI(routes.ADD_NEW_CAR, { carDetails }, token)
  );
export const updateMyCar = (carDetails) =>
  getUserToken().then((token) =>
    callAPI(routes.UPDATE_MY_CAR, { carDetails }, token)
  );
export const deleteMyCar = (carUid) =>
  getUserToken().then((token) => callAPI(routes.DELETE_CAR, { carUid }, token));
export const addNewComponentOrTag = (component) =>
  getUserToken().then((token) =>
    callAPI(routes.ADD_NEW_COMPONENT_OR_TAG, { component }, token)
  );

/* --- COMPONENTS AND TAGS --- */
export const editComponent = (component) =>
  getUserToken().then((token) =>
    callAPI(routes.EDIT_COMPONENT, { component }, token)
  );
export const deleteTagOrComponent = (component) =>
  getUserToken().then((token) =>
    callAPI(routes.DELETE_TAG_OR_COMPONENT, { component }, token)
  );

/* --- LIKES --- */
export const toggleLike = (like) =>
  getUserToken().then((token) => callAPI(routes.TOGGLE_LIKE, { like }, token));

/* --- COMMENTS --- */
export const addComment = (comment) =>
  getUserToken().then((token) =>
    callAPI(routes.ADD_COMMENT, { comment }, token)
  );

/* --- FOLLWING --- */
export const followProfile = (profileId) => {
  try {
    return getUserToken().then((token) =>
      callAPI(routes.FOLLOW_PROFILE, { profileId }, token)
    );
  } catch (e) {}
};

/* --- NOTIFICATIONS --- */
export const notifyNewFollowingLevel = (level) =>
  getUserToken().then((token) =>
    callAPI(routes.NOTIFY_NEW_FOLLOWING_LEVEL, { level }, token)
  );
export const setNotificationSetting = (notification) =>
  getUserToken().then((token) =>
    callAPI(routes.SET_NOTIFICATION_SETTING, { notification }, token)
  );

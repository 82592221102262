import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  IonCard,
  IonCardHeader,
  IonText,
  IonCardContent,
  IonIcon,
  IonList,
  IonItem,
} from '@ionic/react';
import { SocialSharing } from '@ionic-native/social-sharing';
import {
  businessOutline,
  carSportOutline,
  listOutline,
  settingsOutline,
  speedometerOutline,
  calendarOutline,
  pricetagsOutline,
  chatbubblesOutline,
  chevronForwardOutline,
  shareOutline,
} from 'ionicons/icons';

import UserInfoCard from '../UserInfoCard/UserInfoCard';
import LikeButton from '../../ButtonGroup/LikeButton/LikeButton';

import { getCurrentUserUid } from '../../../api/firebase';
import { BASE_URL } from '../../../api/routes';
import {
  PROFILE_ROUTE,
  MY_CAR_ROUTE,
  VEHICLE_ROUTE,
} from '../../../constants/routes';
import './DetailsCard.sass';

const CarDetailsCard = ({ user, details, isOwner }) => {
  const location = useLocation();

  const onShare = () => {
    let url = BASE_URL + location.pathname;

    if (location.pathname === MY_CAR_ROUTE && details.uid) {
      url = BASE_URL + VEHICLE_ROUTE + details.uid;
    }

    SocialSharing.share(
      'Check out this car!\n\n',
      'Check out this car!',
      null,
      url
    );
  };

  return (
    <IonCard className="DetailsCard">
      <IonCardHeader>
        <UserInfoCard
          user={user}
          componentAmount={details.components ? details.components.length : 0}
          withoutWrapper
          profileLink={
            PROFILE_ROUTE + (details.owner.uid ? details.owner.uid : user.uid)
          }
        />
      </IonCardHeader>
      <IonCardContent>
        <IonList>
          <IonItem className="DetailsCard-item">
            <IonIcon icon={businessOutline} slot="start" />
            <IonText color="dark">Make</IonText>
            <IonText color="dark" className="DetailsCard-item-value" slot="end">
              {details.make}
            </IonText>
          </IonItem>
          <IonItem className="DetailsCard-item">
            <IonIcon icon={carSportOutline} slot="start" />
            <IonText color="dark">Model</IonText>
            <IonText color="dark" className="DetailsCard-item-value" slot="end">
              {details.model}
            </IonText>
          </IonItem>
          {details.type && (
            <IonItem className="DetailsCard-item">
              <IonIcon icon={listOutline} slot="start" />
              <IonText color="dark">Type</IonText>
              <IonText
                color="dark"
                className="DetailsCard-item-value"
                slot="end"
              >
                {details.type}
              </IonText>
            </IonItem>
          )}
          <IonItem className="DetailsCard-item">
            <IonIcon icon={settingsOutline} slot="start" />
            <IonText color="dark">Transmission</IonText>
            <IonText color="dark" className="DetailsCard-item-value" slot="end">
              {details.transmission}
            </IonText>
          </IonItem>
          <IonItem className="DetailsCard-item">
            <IonIcon icon={speedometerOutline} slot="start" />
            <IonText color="dark">Power</IonText>
            <IonText color="dark" className="DetailsCard-item-value" slot="end">
              {details.power}
            </IonText>
          </IonItem>
          <IonItem className="DetailsCard-item">
            <IonIcon icon={calendarOutline} slot="start" />
            <IonText color="dark">Manufacturing year</IonText>
            <IonText color="dark" className="DetailsCard-item-value" slot="end">
              {details.manufacturingYear}
            </IonText>
          </IonItem>
          <Link to={PROFILE_ROUTE + user.uid} className="Link">
            <IonItem className="DetailsCard-item">
              <IonIcon icon={pricetagsOutline} slot="start" />
              <IonText color="dark">
                {details.components ? details.components.length : 0} tagged
                components
              </IonText>
              <IonIcon
                icon={chevronForwardOutline}
                className="forward-icon"
                slot="end"
              />
            </IonItem>
          </Link>
        </IonList>
        <div className="DetailsCard-footer">
          <div className="DetailsCard-footer__button">
            <LikeButton
              path={{ vehicleId: details.uid }}
              isLiked={
                details.likes ? details.likes[getCurrentUserUid()] : false
              }
              total={details.likes ? Object.values(details.likes).length : 0}
              disabled={isOwner}
            />
          </div>
          <div className="DetailsCard-footer__button">
            <IonIcon color="dark" icon={chatbubblesOutline} />
            <IonText color="dark">
              {details.commentAmount ? details.commentAmount : 0}
            </IonText>
          </div>
          <div className="DetailsCard-footer__button">
            <IonIcon color="dark" icon={shareOutline} onClick={onShare} />
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default CarDetailsCard;

import React from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonText,
  IonButton,
  IonIcon,
  withIonLifeCycle,
} from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import extractDomain from 'extract-domain';

import Header from '../../components/Header/Header';
import ComponentDetailsCard from '../../components/CardGroup/DetailsCardGroup/ComponentDetailsCard';
import FullscreenGallerySlider from '../../components/FullscreenGallerySlider/FullscreenGallerySlider';
import CommentSection from '../../components/CommentGroup/CommentSection/CommentSection';
import VehicleComponentSkeleton from '../../components/SkeletonGroup/VehicleComponentSkeleton';

import { getComponent, getComponentRef } from '../../api/firebase';
import { SHOWROOM_ROUTE } from '../../constants/routes';
import './Component.sass';

class Component extends React.Component {
  state = {
    showFullscreenGallery: false,
    component: null,
  };

  ionViewDidEnter() {
    const path = this.props.history.location.pathname.split('/');
    const componentId = path[2];

    getComponentRef(componentId).on('value', () =>
      this.fetchComponentData(componentId)
    );
  }

  ionViewWillLeave() {
    if (this.state.component && this.state.component.id) {
      getComponentRef(this.state.component.id).off('value');
    }
  }

  setCommentAmount = commentAmount => this.setState({ commentAmount });

  fetchComponentData = componentId => {
    try {
      getComponent(componentId)
        .then(component => {
          if (!component) {
            throw new Error();
          }

          this.setState({ component });
        })
        .catch(() => this.props.history.push(SHOWROOM_ROUTE));
    } catch {}
  };

  render() {
    const { myCar, history } = this.props;
    const { showFullscreenGallery, component } = this.state;
    const isCameFromFullscreenGallery =
      history.location.state && history.location.state.initialSlide >= 0;

    return (
      <IonPage>
        <Header
          goBack
          onGoBack={
            isCameFromFullscreenGallery &&
            (() =>
              history.push(history.location.state.prevRoute, {
                initialSlide: history.location.state.initialSlide,
                direction: 'back',
              }))
          }
        />
        <IonContent className="Component ion-padding">
          {component ? (
            <>
              <IonText color="dark">
                <h1>{component.name}</h1>
              </IonText>
              <img
                src={component.image}
                className="Component-image"
                alt="Component"
                onClick={() => this.setState({ showFullscreenGallery: true })}
              />
              <FullscreenGallerySlider
                isOpen={showFullscreenGallery}
                withTagging={false}
                images={component.image ? [component.image] : []}
                single={true}
                initialSlide={0}
                closeFullscreenGallery={() =>
                  this.setState({ showFullscreenGallery: false })
                }
              />
              {component.retailer && (
                <IonButton
                  className="Component-get-btn"
                  href={
                    extractDomain(component.retailer)
                      ? `//${extractDomain(component.retailer)}`
                      : `//${component.retailer}`
                  }
                  target="_blank"
                >
                  Get component
                  <IonIcon slot="end" icon={chevronForwardOutline} />
                </IonButton>
              )}
              <ComponentDetailsCard
                component={component}
                isOwner={
                  myCar && myCar.uid ? myCar.uid === component.vehicle : false
                }
              />
              {component && (
                <CommentSection
                  vehicleId={component.carUid}
                  componentId={component.id}
                  commentAmount={component.commentAmount}
                />
              )}
            </>
          ) : (
            <VehicleComponentSkeleton isComponent />
          )}
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = store => ({
  myCar: store.car.details,
});

export default connect(mapStateToProps)(withIonLifeCycle(Component));

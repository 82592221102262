import React, { useEffect } from 'react';
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
} from '@ionic/react';
import { trophy } from 'ionicons/icons';

import { notifyNewFollowingLevel } from '../../api/api';
import './LevelUpModal.sass';

const levels = [
  { color: 'bronze', requiredAmount: 1 },
  { color: 'silver', requiredAmount: 6 },
  { color: 'gold', requiredAmount: 11 },
];

const LevelUpModal = ({ isOpen, hideModal, componentAmount = 0 }) => {
  useEffect(() => {
    if (isOpen) notifyNewFollowingLevel(levels[getLevel()].color);
    // eslint-disable-next-line
  }, [isOpen]);

  const getLevel = () => {
    if (componentAmount >= 6 && componentAmount <= 10) {
      return 1;
    } else if (componentAmount > 10) {
      return 2;
    }

    return 0;
  };

  const renderModalContent = () => {
    const levelIndex = getLevel();

    return (
      <div className="LevelUpModal ion-padding">
        <IonIcon
          icon={trophy}
          color={levels[levelIndex].color}
          className="LevelUpModal__trophy"
        />
        <h2 className="LevelUpModal__title">
          Congratulations, you've reached level {levels[levelIndex].color}!
        </h2>
        {levelIndex < levels.length - 1 && (
          <p className="LevelUpModal__description">
            Tag at least {levels[levelIndex + 1].requiredAmount} components to
            reach level {levels[levelIndex + 1].color}.
          </p>
        )}
      </div>
    );
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={hideModal}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>{renderModalContent()}</IonContent>
    </IonModal>
  );
};

export default LevelUpModal;

import React from 'react';
import { Link } from 'react-router-dom';
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
} from '@ionic/react';

import Logo from '../../assets/logo.png';
import HeaderBackButton from '../ButtonGroup/HeaderBackButton/HeaderBackButton';

import { SHOWROOM_ROUTE } from '../../constants/routes';

import './Header.sass';

const Header = ({ goBack, onGoBack }) => {
  return (
    <IonHeader className="Header">
      <IonToolbar mode="ios">
        {goBack || onGoBack ? (
          <HeaderBackButton goBack={goBack} onGoBack={onGoBack} />
        ) : (
          <IonButtons slot="start">
            <IonMenuButton menu="menu" />
          </IonButtons>
        )}
        <Link to={SHOWROOM_ROUTE}>
          <IonTitle>
            <img src={Logo} className="Header-logo" alt="Logo" />
          </IonTitle>
        </Link>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;

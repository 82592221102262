import React, { Component } from 'react';
import {
  IonText,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonCheckbox,
  IonSpinner,
  IonLoading,
} from '@ionic/react';
import ImageUploader from '../ImageUploader/ImageUploader';
import uniqid from 'uniqid';
import { getAllCarModels } from '../../api/api';
import { uploadImage, getImageURL } from '../../api/firebase';
import { VEHICLES_IMAGE_UPLOAD_PATH } from '../../api/routes';
import { getNumericInput } from '../../utils/utils';
import './MyCarForm.sass';

const inputRefs = {
  power: null,
};

class MyCarForm extends Component {
  state = {
    allCarModels: {
      makes: [],
      models: [],
      types: [],
      isLoading: false,
    },
    make: '',
    model: '',
    type: '',
    transmissionType: 'manual',
    manufacturingYear: new Date().getFullYear(),
    images: [],
    checkbox: {
      enabled: false,
      checked: false,
    },
    showLoading: true,
  };

  componentDidMount() {
    // EDIT MODE: Extracting data from carDetails object
    const { isEdit, carDetails } = this.props;
    let callback = this.initializeExistingData;

    if (!isEdit && !carDetails) {
      callback = () => this.setState({ showLoading: false });
    }

    this.getAllMakes().then(() => callback());
  }

  initializeExistingData = () => {
    const { carDetails } = this.props;
    const { power, transmission, manufacturingYear, images } = carDetails;
    const makeName = carDetails.make;
    const modelName = carDetails.model;
    const typeName = carDetails.type;

    const setFields = () => {
      inputRefs.power.value = power;
      this.setState({
        make,
        model,
        type,
        transmissionType: transmission,
        manufacturingYear: manufacturingYear,
        images: images,
        showLoading: false,
      });
    };

    let make = '';
    let model = '';
    let type = '';

    if (!makeName) return setFields();

    make = this.state.allCarModels?.makes.find(el => el.name === makeName)?.id;

    if (!modelName) return setFields();

    return this.getAllModels(make)
      .then(
        () =>
          (model = this.state.allCarModels.models.find(
            el => el.name === modelName
          ).id)
      )
      .then(() => {
        if (!typeName) return;

        return this.getAllTypes(model);
      })
      .then(() => {
        if (!typeName) return setFields();

        type = this.state.allCarModels.types.find(el => el.name === typeName)
          .id;

        return setFields();
      });
  };

  getAllMakes = () => {
    this.setState(prevState => ({
      allCarModels: { ...prevState.allCarModels, isLoading: true },
    }));

    return getAllCarModels({ field: 'make' }).then(makes =>
      this.setState(prevState => ({
        allCarModels: { ...prevState.allCarModels, makes, isLoading: false },
      }))
    );
  };
  getAllModels = make => {
    this.setState(prevState => ({
      allCarModels: { ...prevState.allCarModels, isLoading: true },
    }));

    return getAllCarModels({
      field: 'model',
      make: make ? make : this.state.make,
    }).then(models =>
      this.setState(prevState => ({
        allCarModels: { ...prevState.allCarModels, models, isLoading: false },
      }))
    );
  };
  getAllTypes = model => {
    this.setState(prevState => ({
      allCarModels: { ...prevState.allCarModels, isLoading: true },
    }));

    return getAllCarModels({
      field: 'type',
      model: model ? model : this.state.model,
    }).then(types =>
      this.setState(prevState => ({
        allCarModels: { ...prevState.allCarModels, types, isLoading: false },
      }))
    );
  };

  onAllCarModelFieldsSelect = (field, selectedValue) => {
    if (!selectedValue) return;

    const newState = { ...this.state };
    newState[field] = selectedValue;
    let promise = null;

    if (field === 'make') {
      if (newState.model || newState.type) {
        newState.model = '';
        newState.type = '';
      }
      promise = this.getAllModels;
    } else if (field === 'model') {
      if (newState.type) {
        newState.type = '';
      }
      promise = this.getAllTypes;
    }

    this.setState(newState, promise);
  };

  handleSetImages = images => this.setState({ images });

  isRequiredFieldsFilled = () => {
    const {
      allCarModels,
      make,
      model,
      type,
      transmissionType,
      manufacturingYear,
      images,
    } = this.state;
    //power has required attribute

    if (
      !make ||
      !model ||
      (!type && allCarModels.types.length) ||
      !transmissionType ||
      !manufacturingYear ||
      !images.length
    ) {
      return false;
    }

    return true;
  };

  onSubmit = e => {
    e.preventDefault();

    const {
      allCarModels,
      make,
      model,
      type,
      transmissionType,
      manufacturingYear,
      images,
    } = this.state;

    if (!images.length) {
      return;
    }

    const makeName = make
      ? allCarModels.makes.find(el => el.id === make).name
      : '';
    const modelName = model
      ? allCarModels.models.find(el => el.id === model).name
      : '';
    const typeName = type
      ? allCarModels.types.find(el => el.id === type).name
      : '';

    const carDetails = {
      make: makeName ? makeName : '',
      model: modelName ? modelName : '',
      type: typeName ? typeName : '',
      transmission: transmissionType,
      power: inputRefs.power ? inputRefs.power.value : '',
      manufacturingYear: manufacturingYear,
    };

    let newImages = [...images];
    const imageSources = [];
    const alreadyUploadedImages = [];

    if (this.props.editMode) {
      // Filter new image files from already uploaded
      newImages = images.filter((el, index) => {
        if (!el.src) return true;

        alreadyUploadedImages.push({ imageData: el, position: index });
        return false;
      });
    }

    this.props.startLoading();

    Promise.all(
      newImages.map(image => {
        const uniqueFileName = uniqid('vehicle-');
        return uploadImage(VEHICLES_IMAGE_UPLOAD_PATH, uniqueFileName, image)
          .then(() => getImageURL(VEHICLES_IMAGE_UPLOAD_PATH, uniqueFileName))
          .then(url => imageSources.push({ src: url }));
      })
    )
      .then(() => {
        carDetails.images = imageSources;

        if (this.props.editMode) {
          alreadyUploadedImages.map(el =>
            carDetails.images.splice(el.position, 0, el.imageData)
          );
          carDetails.uid = this.props.carDetails.uid;
          this.props.editMyCar(carDetails).then(() => {
            this.props.finishLoading();
            this.props.goBack();
          });
        } else {
          this.props.addNewCar(carDetails);
        }
      })
      .catch(() => this.props.finishLoading());
  };

  onCheckboxChange = e =>
    this.setState(prevState => ({
      checkbox: {
        ...prevState.checkbox,
        checked: e.detail.checked,
      },
    }));

  handleSetCheckboxAvailability = value => {
    this.setState(prevState => ({
      checkbox: {
        enabled: value,
        checked: value ? prevState.checkbox.checked : false,
      },
    }));
  };

  render() {
    const { editMode, isLoading } = this.props;
    const {
      allCarModels,
      make,
      model,
      type,
      transmissionType,
      manufacturingYear,
      images,
      checkbox,
      showLoading,
    } = this.state;

    return (
      <>
        <IonLoading isOpen={showLoading} message={'Please wait...'} />
        <IonText color="dark">
          <h1>{editMode ? 'Edit' : 'Add'} Car</h1>
        </IonText>
        <form className="MyCarForm" onSubmit={this.onSubmit}>
          <IonList>
            <IonItem className="MyCarForm__input">
              <IonLabel color="primary" position="floating">
                Make <IonText color="danger">*</IonText>
              </IonLabel>
              <IonSelect
                value={make}
                placeholder="Select One"
                onIonChange={e =>
                  this.onAllCarModelFieldsSelect('make', e.detail.value)
                }
              >
                {allCarModels.makes.map((make, i) => (
                  <IonSelectOption value={make.id} key={i}>
                    {make.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem className="MyCarForm__input">
              <IonLabel color="primary" position="floating">
                Model <IonText color="danger">*</IonText>
              </IonLabel>
              <IonSelect
                value={model}
                placeholder="Select One"
                onIonChange={e =>
                  this.onAllCarModelFieldsSelect('model', e.detail.value)
                }
                disabled={!make || !allCarModels.models.length}
              >
                {allCarModels.models.map((model, i) => (
                  <IonSelectOption value={model.id} key={i}>
                    {model.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem className="MyCarForm__input">
              <IonLabel color="primary" position="floating">
                Type <IonText color="danger">*</IonText>
              </IonLabel>
              <IonSelect
                value={type}
                placeholder="Select One"
                onIonChange={e =>
                  this.onAllCarModelFieldsSelect('type', e.detail.value)
                }
                disabled={
                  !model || !allCarModels.types.length || allCarModels.isLoading
                }
              >
                {allCarModels.types.map((type, i) => (
                  <IonSelectOption value={type.id} key={i}>
                    {type.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem className="MyCarForm__select">
              <IonLabel color="primary">
                Transmission <IonText color="danger">*</IonText>
              </IonLabel>
              <IonSelect
                value={transmissionType}
                placeholder="Select One"
                onIonChange={e =>
                  this.setState({ transmissionType: e.detail.value })
                }
                interface="action-sheet"
              >
                <IonSelectOption value="manual">Manual</IonSelectOption>
                <IonSelectOption value="automatic">Automatic</IonSelectOption>
                <IonSelectOption value="none">None (Electric)</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem className="MyCarForm__input">
              <IonLabel color="primary" position="floating">
                Power <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                required
                ref={input => (inputRefs.power = input)}
                maxlength="4"
                onIonChange={e =>
                  (inputRefs.power.value = getNumericInput(e.target.value))
                }
                inputmode="numeric"
              ></IonInput>
            </IonItem>
            <IonItem className="MyCarForm__date-picker">
              <IonLabel color="primary">
                Manufacturing year <IonText color="danger">*</IonText>
              </IonLabel>
              <IonDatetime
                displayFormat="YYYY"
                value={manufacturingYear}
                onIonChange={e =>
                  this.setState({
                    manufacturingYear: new Date(e.detail.value).getFullYear(),
                  })
                }
              ></IonDatetime>
            </IonItem>
            <ImageUploader
              images={images}
              setImages={this.handleSetImages}
              setCheckboxAvailability={this.handleSetCheckboxAvailability}
            />
            <IonItem className="MyCarForm__checkbox">
              <IonLabel className="ion-text-wrap" color="primary">
                The images are my own, or I own the copyright
              </IonLabel>
              <IonCheckbox
                color="primary"
                checked={checkbox.checked}
                disabled={!checkbox.enabled}
                onIonChange={this.onCheckboxChange}
                slot="start"
              ></IonCheckbox>
            </IonItem>
          </IonList>
          <IonButton
            className="MyCarForm__button"
            disabled={
              !this.isRequiredFieldsFilled() ||
              allCarModels.isLoading ||
              (isLoading || editMode
                ? checkbox.enabled && (!images || !checkbox.checked)
                : !images.length || (checkbox.enabled && !checkbox.checked))
            }
            type="submit"
          >
            {isLoading ? <IonSpinner /> : editMode ? 'Save Changes' : 'Add Car'}
          </IonButton>
        </form>
      </>
    );
  }
}

export default MyCarForm;

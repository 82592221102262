import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  IonAlert,
  IonCheckbox,
  IonSpinner,
} from '@ionic/react';

import VerifyEmail from '../VerifyEmail/VerifyEmail';

import { isEmail } from 'validator';
import { registerUser } from '../../store/user/actions';
import {
  TERMS_OF_SERVICE_ROUTE,
  PRIVACY_ROUTE,
  LOGIN_ROUTE,
} from '../../constants/routes';
import './AuthForms.sass';

let name = null;
let email = null;
let password = null;
let passwordRepeat = null;

class RegisterForm extends Component {
  state = {
    isLoading: false,
    isRegistrationDone: false,
    showAlertMessage: {
      isOpen: false,
      message: '',
    },
    checkbox: false,
  };

  toggleAlertMessage = (isOpen, mode = '') => {
    const config = {
      isOpen,
      message: '',
    };

    switch (mode) {
      case 'passwords':
        config.message = "Passwords don't match";
        break;
      case 'email':
        config.message = 'Wrong email address';
        break;
      case 'already-used-email':
        config.message =
          'The email address is already in use by another account';
        break;
      default:
        config.message = 'Wrong input data';
    }

    return this.setState({ showAlertMessage: config });
  };

  onSubmit = e => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const userData = {
      name: name.value,
      email: email.value,
      password: password.value,
      passwordRepeat: passwordRepeat.value,
    };

    if (
      !userData.name ||
      !userData.email ||
      !userData.password ||
      !userData.passwordRepeat
    ) {
      return;
    }
    if (!isEmail(userData.email)) {
      this.toggleAlertMessage(true, 'email');
      return;
    }
    if (userData.password !== userData.passwordRepeat) {
      this.toggleAlertMessage(true, 'passwords');
      password.value = '';
      passwordRepeat.value = '';
      return;
    }

    this.props
      .registerUser(userData)
      .then(() => this.setState({ isRegistrationDone: true }))
      .catch(() => {
        this.toggleAlertMessage(true, 'already-used-email');
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      isRegistrationDone,
      showAlertMessage,
      checkbox,
      isLoading,
    } = this.state;

    return (
      <>
        <IonAlert
          isOpen={showAlertMessage.isOpen}
          onDidDismiss={() => this.toggleAlertMessage(false)}
          header={'Warning'}
          message={showAlertMessage.message}
          buttons={['OK']}
        />
        {!isRegistrationDone ? (
          <form className="AuthForms" onSubmit={this.onSubmit}>
            <IonList>
              <IonItem className="AuthForms__input">
                <IonLabel color="primary" position="floating">
                  First Name <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  type="text"
                  required={true}
                  ref={input => (name = input)}
                ></IonInput>
              </IonItem>
              <IonItem className="AuthForms__input">
                <IonLabel color="primary" position="floating">
                  Email Address <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  type="email"
                  required={true}
                  ref={input => (email = input)}
                ></IonInput>
              </IonItem>
              <IonItem className="AuthForms__input">
                <IonLabel color="primary" position="floating">
                  Password <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  type="password"
                  required={true}
                  minlength="6"
                  ref={input => (password = input)}
                ></IonInput>
              </IonItem>
              <IonItem className="AuthForms__input">
                <IonLabel color="primary" position="floating">
                  Password (Repeat) <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  type="password"
                  required={true}
                  minlength="6"
                  ref={input => (passwordRepeat = input)}
                ></IonInput>
              </IonItem>
              <IonItem className="AuthForms__checkbox">
                <IonLabel color="primary" className="ion-text-wrap">
                  I accept{' '}
                  <Link to={TERMS_OF_SERVICE_ROUTE}>Terms of Service</Link> &{' '}
                  <Link to={PRIVACY_ROUTE}>Privacy</Link>
                </IonLabel>
                <IonCheckbox
                  color="primary"
                  checked={checkbox}
                  onIonChange={() => this.setState({ checkbox: !checkbox })}
                  slot="start"
                ></IonCheckbox>
              </IonItem>
            </IonList>
            <IonButton
              className="AuthForms__button"
              type="submit"
              disabled={!checkbox || isLoading}
            >
              {isLoading ? <IonSpinner /> : 'Register Now'}
            </IonButton>
            <IonText className="AuthForms__link" color="primary">
              Already have an account?{' '}
              <Link to={LOGIN_ROUTE}>
                <b>Log in</b>
              </Link>
            </IonText>
          </form>
        ) : (
          <VerifyEmail />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  registerUser: userData => dispatch(registerUser(userData)),
});

export default connect(null, mapDispatchToProps)(RegisterForm);

import { showroomRef, fetchShowroomCars } from '../../api/firebase';

export const FETCH_VEHICLES = 'FETCH_VEHICLES';
export const ALL_CARS_FETCHED = 'ALL_CARS_FETCHED';
export const SHOWROOM_UPDATE_VEHICLE = 'SHOWROOM_UPDATE_VEHICLE';
export const LOADING_START = 'LOADING_SHOWROOM_START';
export const LOADING_FINISH = 'LOADING_SHOWROOM_FINISH';

export const listenToShowroomUpdates = () => dispatch =>
  showroomRef().on('child_changed', data =>
    dispatch({
      type: SHOWROOM_UPDATE_VEHICLE,
      payload: { ...data.val(), id: data.key },
    })
  );

export const fetchShowroomCarsAction = lastVehicleId => {
  return dispatch => {
    if (!lastVehicleId) {
      dispatch({ type: LOADING_START });
    }
    return fetchShowroomCars(lastVehicleId)
      .then(cars => {
        if (cars.length) {
          dispatch({
            type: FETCH_VEHICLES,
            payload: { cars, isRefresh: !lastVehicleId },
          });
        } else {
          dispatch({ type: ALL_CARS_FETCHED });
        }
      })
      .catch(err => {
        alert(err);
        dispatch({ type: LOADING_FINISH });
      });
  };
};

import React, { Component } from 'react';
import { IonText, IonList } from '@ionic/react';

import SettingToggle from '../SettingToggle';

import { activateSelectedColorTheme } from '../../../utils/utils';
import {
  colorThemes,
  COLOR_THEME_STORAGE_PROPERTY,
} from '../../../constants/constants';

class DisplaySettings extends Component {
  _preventChangeHandler = false;

  state = {
    darkmode: false,
    deviceSetting: false,
  };

  componentDidMount() {
    const colorTheme = localStorage.getItem(COLOR_THEME_STORAGE_PROPERTY);

    const { DARKMODE, DEVICE_SETTING } = colorThemes;

    if (colorTheme === DARKMODE) {
      return this.setState({ darkmode: true }, () => {
        this.changeColorThemeSetting();
      });
    }
    if (colorTheme === DEVICE_SETTING) {
      return this.setState({ deviceSetting: true });
    }
  }

  changeColorThemeSetting = () => {
    const { darkmode, deviceSetting } = this.state;

    if (darkmode) {
      this.setColorTheme(colorThemes.DARKMODE);
    } else if (deviceSetting) {
      this.setColorTheme(colorThemes.DEVICE_SETTING);
    } else {
      this.setColorTheme(colorThemes.LIGHT);
    }

    activateSelectedColorTheme();
  };

  setColorTheme = type =>
    localStorage.setItem(COLOR_THEME_STORAGE_PROPERTY, type);

  handleOnChange = (value, type) => {
    if (this._preventChangeHandler) return;

    switch (type) {
      case colorThemes.DARKMODE: {
        return this.setState(
          prevState => {
            this._preventChangeHandler = true;

            if (prevState.deviceSetting) {
              return { darkmode: value, deviceSetting: false };
            }

            return { darkmode: value };
          },
          () => {
            this._preventChangeHandler = false;
            this.changeColorThemeSetting();
          }
        );
      }
      case colorThemes.DEVICE_SETTING:
        return this.setState(
          prevState => {
            this._preventChangeHandler = true;

            if (prevState.darkmode) {
              return { darkmode: false, deviceSetting: value };
            }

            return { deviceSetting: value };
          },
          () => {
            this._preventChangeHandler = false;
            this.changeColorThemeSetting();
          }
        );
      default:
        return;
    }
  };

  render() {
    const { darkmode, deviceSetting } = this.state;

    return (
      <>
        <IonText>
          <h1>Display</h1>
        </IonText>
        <IonList>
          <SettingToggle
            name="Darkmode"
            isChecked={darkmode}
            onChange={this.handleOnChange}
            type={colorThemes.DARKMODE}
          />
          <SettingToggle
            name="Apply device setting"
            isChecked={deviceSetting}
            onChange={this.handleOnChange}
            type={colorThemes.DEVICE_SETTING}
          />
        </IonList>
      </>
    );
  }
}

export default DisplaySettings;

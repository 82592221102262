import { getNotificationRef, getNotifications } from '../../api/firebase';

export const ALL_NOTIFICATIONS_FETCHED = 'ALL_NOTIFICATIONS_FETCHED';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const UNSHIFT_NEW_NOTIFICATION = 'UNSHIFT_NEW_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

export const fetchNotifications = (
  lastNotificationId,
  isOnInitialization = false
) => {
  return dispatch => {
    if (isOnInitialization) {
      getNotificationRef().on('child_changed', data =>
        dispatch({
          type: READ_NOTIFICATION,
          payload: { ...data.val(), id: data.key },
        })
      );
    }

    return onGetNotifications(dispatch, lastNotificationId).then(() =>
      isOnInitialization
        ? getNotificationRef()
            .limitToLast(1)
            .on('child_added', data =>
              dispatch({
                type: UNSHIFT_NEW_NOTIFICATION,
                payload: { ...data.val(), id: data.key },
              })
            )
        : null
    );
  };
};

const onGetNotifications = (dispatch, lastNotificationId) => {
  return getNotifications(lastNotificationId)
    .then(notifications => {
      if(!notifications) return [];
      
      if (notifications.length || !lastNotificationId) {
        dispatch({
          type: FETCH_NOTIFICATIONS,
          payload: { notifications, isRefresh: !lastNotificationId },
        });
      } else {
        dispatch({ type: ALL_NOTIFICATIONS_FETCHED });
      }
      return notifications;
    })
    .catch(err => {
      alert(err);
    });
};

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  IonCard,
  IonText,
  IonCardContent,
  IonIcon,
  IonList,
  IonItem,
} from '@ionic/react';
import { SocialSharing } from '@ionic-native/social-sharing';
import {
  businessOutline,
  cartOutline,
  colorPaletteOutline,
  resizeOutline,
  cardOutline,
  buildOutline,
  pricetagOutline,
  shareOutline,
  carSportOutline,
  chatbubblesOutline,
} from 'ionicons/icons';
import extractDomain from 'extract-domain';

import LikeButton from '../../ButtonGroup/LikeButton/LikeButton';

import { getCurrentUserUid } from '../../../api/firebase';
import { BASE_URL } from '../../../api/routes';
import { VEHICLE_ROUTE } from '../../../constants/routes';
import { formatPrice } from '../../../utils/utils';
import './DetailsCard.sass';

const ComponentDetailsCard = ({ component, isOwner }) => {
  const location = useLocation();

  const onShare = () => {
    const url = BASE_URL + location.pathname;

    SocialSharing.share(
      'Check out this component!\n\n',
      'Check out this component!',
      null,
      url
    );
  };

  return (
    <IonCard className="DetailsCard">
      <IonCardContent>
        <IonList>
          <IonItem className="DetailsCard-item">
            <IonIcon icon={businessOutline} slot="start" />
            <IonText color="dark">Make</IonText>
            <IonText color="dark" className="DetailsCard-item-value" slot="end">
              {component.make}
            </IonText>
          </IonItem>
          {component.retailer && (
            <IonItem className="DetailsCard-item">
              <IonIcon icon={cartOutline} slot="start" />
              <IonText color="dark">Retailer</IonText>
              <IonText
                color="dark"
                className="DetailsCard-item-value"
                slot="end"
              >
                {extractDomain(component.retailer)
                  ? extractDomain(component.retailer)
                  : component.retailer}
              </IonText>
            </IonItem>
          )}
          {component.color && (
            <IonItem className="DetailsCard-item">
              <IonIcon icon={colorPaletteOutline} slot="start" />
              <IonText color="dark">Color</IonText>
              <IonText
                color="dark"
                className="DetailsCard-item-value"
                slot="end"
              >
                {component.color}
              </IonText>
            </IonItem>
          )}
          {component.rimSize && (
            <IonItem className="DetailsCard-item">
              <IonIcon icon={resizeOutline} slot="start" />
              <IonText color="dark">Rim size</IonText>
              <IonText
                color="dark"
                className="DetailsCard-item-value"
                slot="end"
              >
                {component.rimSize}"
              </IonText>
            </IonItem>
          )}
          {component.tireSize && (
            <IonItem className="DetailsCard-item">
              <IonIcon icon={resizeOutline} slot="start" />
              <IonText color="dark">Tire size</IonText>
              <IonText
                color="dark"
                className="DetailsCard-item-value"
                slot="end"
              >
                {component.tireSize}
              </IonText>
            </IonItem>
          )}
          <IonItem className="DetailsCard-item">
            <IonIcon icon={cardOutline} slot="start" />
            <IonText color="dark">Price</IonText>
            <IonText color="dark" className="DetailsCard-item-value" slot="end">
              {formatPrice(component.price)} €
            </IonText>
          </IonItem>
          <IonItem className="DetailsCard-item">
            <IonIcon icon={buildOutline} slot="start" />
            <IonText color="dark">Difficulty</IonText>
            <IonText color="dark" className="DetailsCard-item-value" slot="end">
              {component.difficulty}/5
            </IonText>
          </IonItem>
          <Link
            to={{
              pathname: VEHICLE_ROUTE + component.vehicle,
              state: {
                componentId: component.id,
              },
            }}
            className="Link"
          >
            <IonItem className="DetailsCard-item">
              <IonIcon icon={pricetagOutline} slot="start" />
              <IonText color="dark">Tagged here</IonText>
              <IonIcon
                icon={carSportOutline}
                color="dark"
                className="DetailsCard-item-value"
                slot="end"
              />
            </IonItem>
          </Link>
        </IonList>
        <div className="DetailsCard-footer">
          <div className="DetailsCard-footer__button">
            <LikeButton
              path={{ componentId: component.id }}
              isLiked={
                component.likes ? component.likes[getCurrentUserUid()] : false
              }
              total={
                component.likes ? Object.values(component.likes).length : 0
              }
              disabled={isOwner}
            />
          </div>
          <div className="DetailsCard-footer__button">
            <IonIcon color="dark" icon={chatbubblesOutline} />
            <IonText color="dark">
              {component.commentAmount ? component.commentAmount : 0}
            </IonText>
          </div>
          <div className="DetailsCard-footer__button">
            <IonIcon color="dark" icon={shareOutline} onClick={onShare} />
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default ComponentDetailsCard;

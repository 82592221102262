import React from 'react';
import { connect } from 'react-redux';
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react';
import { logUserOut } from '../../store/user/actions';
import MenuContent from './MenuContent';

import './Menu.sass';

const Menu = ({ isLoggedIn, user, carDetails, logUserOut }) => {
  return (
    <IonMenu
      className="Menu"
      menuId="menu"
      contentId="menu-content"
      type="push"
      mode="ios"
    >
      <IonHeader>
        <IonToolbar className="Menu-header">
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MenuContent
          isLoggedIn={isLoggedIn}
          user={user}
          carDetails={carDetails}
          logUserOut={logUserOut}
        />
      </IonContent>
    </IonMenu>
  );
};

const mapStateToProps = store => ({
  isLoggedIn: store.user.isLoggedIn,
  user: store.user.user,
  carDetails: store.car.details,
});

const mapDispatchToProps = dispatch => ({
  logUserOut: () => dispatch(logUserOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

import React from 'react';
import { IonIcon } from '@ionic/react';
import { paperPlaneOutline, mailOutline } from 'ionicons/icons';
import './VerifyEmail.sass';

const VerifyEmail = ({ isNotCanAccess, isPasswordReset }) => {
  return (
    <div className="VerifyEmail ion-text-center">
      <IonIcon
        className="VerifyEmail__icon"
        icon={isNotCanAccess ? mailOutline : paperPlaneOutline}
      />
      <ion-text color="primary">
        <h2>{isNotCanAccess ? 'Verify mail first' : 'Almost done'}</h2>
      </ion-text>
      <ion-text color="medium">
        <p>
          We{isNotCanAccess ? ' ' : "'ve"} sent you a mail.
          <br />
          {isNotCanAccess
            ? 'To access this tab please verify your mail first'
            : `Please click the link in it to ${
                isPasswordReset
                  ? 'reset your password'
                  : 'activate your account'
              }.`}
        </p>
      </ion-text>
    </div>
  );
};

export default VerifyEmail;

import React, { useState } from 'react';
import {
  IonText,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonAlert,
} from '@ionic/react';

import { reauthenticate } from '../../../api/firebase';
import { deleteMyCar, deleteUserProfile } from '../../../api/api';

const DeleteCarOrProfileSetting = ({
  isCarDeleteTab = false,
  startLoading,
  finishLoading,
  logoutUser,
  goBack,
}) => {
  const [showPasswordAlertMsg, setShowPasswordAlertMsg] = useState(false);
  const [showComfirmMsg, setShowComfirmMsg] = useState(false);

  let password = null;

  const onSubmit = e => {
    e.preventDefault();

    startLoading();

    return reauthenticate(password.value)
      .then(() => {
        finishLoading();
        setShowComfirmMsg(true);
      })
      .catch(() => {
        password.value = '';
        finishLoading();
        setShowPasswordAlertMsg(true);
      });
  };

  const deleteCar = () => {
    startLoading();

    return deleteMyCar()
      .then(() => {
        if (password) password.value = '';

        finishLoading();
        goBack();
      })
      .catch(err => alert(err.message));
  };

  const deleteProfile = () => {
    startLoading();

    return deleteUserProfile()
      .then(() => logoutUser())
      .then(() => {
        finishLoading();
        goBack();
      })
      .catch(err => alert(err.message));
  };

  return (
    <>
      <IonAlert
        isOpen={showPasswordAlertMsg}
        onDidDismiss={() => setShowPasswordAlertMsg(false)}
        header="Warning"
        message="Wrong password"
        buttons={['OK']}
      />
      <IonAlert
        isOpen={showComfirmMsg}
        onDidDismiss={() => setShowComfirmMsg(false)}
        header={'Are you sure?'}
        message={`Do you really want to delete your ${
          isCarDeleteTab ? 'car' : 'profile'
        }? This can't be undone.`}
        buttons={[
          'Abort',
          {
            text: 'Delete',
            handler: isCarDeleteTab ? deleteCar : deleteProfile,
          },
        ]}
      />
      <IonText>
        <h1>Delete {isCarDeleteTab ? 'Car' : 'Profile'}</h1>
      </IonText>
      <form onSubmit={onSubmit}>
        <IonList>
          <IonItem className="Settings-input">
            <IonLabel color="primary" position="floating">
              Enter Password <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              type="password"
              required={true}
              minlength="6"
              ref={input => (password = input)}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton className="Settings-button" color="danger" type="submit">
          Delete {isCarDeleteTab ? 'Car' : 'Profile'}
        </IonButton>
      </form>
    </>
  );
};

export default DeleteCarOrProfileSetting;

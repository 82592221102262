import React, { useState } from 'react';
import {
  IonText,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonAlert,
} from '@ionic/react';

import { updateUserPassword } from '../../../api/firebase';

const PasswordSettings = ({ startLoading, finishLoading, goBack }) => {
  const [showAlertMsg, setShowAlertMsg] = useState({
    isOpen: false,
    message: '',
  });

  let oldPassInput = null;
  let newPassInput = null;
  let repeatNewPassInput = null;

  const onSubmit = e => {
    e.preventDefault();

    if (newPassInput.value !== repeatNewPassInput.value) {
      setShowAlertMsg({ isOpen: true, message: "Passwords don't match" });
      newPassInput.value = '';
      repeatNewPassInput.value = '';
      return;
    }

    startLoading();

    updateUserPassword(oldPassInput.value, newPassInput.value)
      .then(() => {
        goBack();
        finishLoading();
      })
      .catch(() => {
        resetAllFields();
        setShowAlertMsg({
          isOpen: true,
          message: 'Wrong password',
        });
        finishLoading();
      });
  };

  const resetAllFields = () => {
    oldPassInput.value = '';
    newPassInput.value = '';
    repeatNewPassInput.value = '';
  };

  return (
    <>
      <IonAlert
        isOpen={showAlertMsg.isOpen}
        onDidDismiss={() => setShowAlertMsg({ isOpen: false })}
        header={'Warning'}
        message={showAlertMsg.message}
        buttons={['OK']}
      />
      <IonText>
        <h1>Password</h1>
      </IonText>
      <form onSubmit={onSubmit}>
        <IonList>
          <IonItem className="Settings-input">
            <IonLabel color="primary" position="floating">
              Old Password <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              type="password"
              required={true}
              minlength="6"
              ref={input => (oldPassInput = input)}
            ></IonInput>
          </IonItem>
          <IonItem className="Settings-input">
            <IonLabel color="primary" position="floating">
              New Password <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              type="password"
              required={true}
              minlength="6"
              ref={input => (newPassInput = input)}
            ></IonInput>
          </IonItem>
          <IonItem className="Settings-input">
            <IonLabel color="primary" position="floating">
              Repeat Password <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              type="password"
              required={true}
              minlength="6"
              ref={input => (repeatNewPassInput = input)}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton className="Settings-button" type="submit">
          Save Changes
        </IonButton>
      </form>
    </>
  );
};

export default PasswordSettings;

import { isPlatform } from '@ionic/react';

export const vibrate = (duration = 30) => {
  if (isPlatform('ios')) {
    return;
  }

  if (!navigator || !navigator.vibrate) {
    return;
  }

  navigator.vibrate(duration);
};

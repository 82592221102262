import React from 'react';
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from '@ionic/react';

const SortingModal = ({ isOpen = false, onClose }) => {
  return (
    <IonModal isOpen={isOpen} swipeToClose>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sorting</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" forceOverscroll={false}>
        <p style={{ marginTop: '0' }}>Sorting function coming soon..</p>
      </IonContent>
    </IonModal>
  );
};

export default SortingModal;

import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonAlert,
  IonText,
  IonSpinner,
} from '@ionic/react';
import { isEmail } from 'validator';

import { authenticateUser } from '../../store/user/actions';
import {
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  LOGIN_ROUTE,
} from '../../constants/routes';
import './AuthForms.sass';

const LoginForm = ({ logUserIn }) => {
  const history = useHistory();
  const [alertMessage, setAlertMessage] = useState({
    isOpen: false,
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  let email = null;
  let password = null;

  const onSubmit = e => {
    e.preventDefault();

    setIsLoading(true);

    if (!email.value || !password.value) {
      return;
    }

    if (!isEmail(email.value)) {
      setAlertMessage({ isOpen: true, message: 'Invalid email address' });
      return;
    }

    logUserIn(email.value, password.value)
      .then(emailVerified => {
        if (!emailVerified) {
          setAlertMessage({
            isOpen: true,
            message: 'Please verify your email first',
          });
          return setIsLoading(false);
        }

        return history.location.pathname === LOGIN_ROUTE
          ? history.push('/')
          : true;
      })
      .catch(() => {
        setAlertMessage({
          isOpen: true,
          message: 'Wrong email or password',
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <IonAlert
        isOpen={alertMessage.isOpen}
        onDidDismiss={() => setAlertMessage({ isOpen: false })}
        header={'Warning'}
        message={alertMessage.message}
        buttons={['OK']}
      />
      <form className="AuthForms" onSubmit={onSubmit}>
        <IonList>
          <IonItem className="AuthForms__input">
            <IonLabel color="primary" position="floating">
              Email Address <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              type="email"
              required={true}
              ref={input => (email = input)}
            ></IonInput>
          </IonItem>
          <IonItem className="AuthForms__input">
            <IonLabel color="primary" position="floating">
              Password <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              type="password"
              required={true}
              minlength="6"
              ref={input => (password = input)}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton
          className="AuthForms__button"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <IonSpinner /> : 'Login'}
        </IonButton>
        <IonText className="AuthForms__link" color="primary">
          Don’t have an account?{' '}
          <Link to={REGISTER_ROUTE}>
            <b>Sign up</b>
          </Link>
        </IonText>
        <IonText className="AuthForms__link" color="primary">
          <Link to={RESET_PASSWORD_ROUTE}>
            <b>Forgot password?</b>
          </Link>
        </IonText>
      </form>
    </>
  );
};

const mapStateToProps = store => ({
  isLoggedIn: store.user.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
  logUserIn: (email, password) => dispatch(authenticateUser(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

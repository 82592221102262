import {
  auth,
  getCurrentUserRef,
  getNotificationSettings,
  getCurrentUserUid,
  getNotificationSettingsRef,
} from '../../api/firebase';
import { createNewUser } from '../../api/api';

export const LOG_USER_IN = 'LOG_USER_IN';
export const LOG_USER_OUT = 'LOG_USER_OUT';
export const LOADING_START = 'LOADING_USER_START';
export const LOADING_FINISH = 'LOADING_USER_FINISH';
export const UPDATE_USER_NOTIFCATION_SETTINGS =
  'UPDATE_USER_NOTIFCATION_SETTINGS';

export const authenticateUser = (email, password) => {
  return (dispatch) => {
    dispatch({ type: LOADING_START });
    return auth
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        const isEmailVerified = user.emailVerified;

        if (!isEmailVerified) {
          auth.signOut();
        }

        return isEmailVerified;
      })
      .catch(() => {
        dispatch({ type: LOADING_FINISH });
        throw new Error();
      });
  };
};

export const registerUser = (userData) => {
  return () => {
    return auth
      .createUserWithEmailAndPassword(userData.email, userData.password)
      .then(() => createNewUser({ name: userData.name }))
      .then(() => {
        const user = auth.currentUser;

        user.sendEmailVerification();
        return auth.signOut();
      });
  };
};

export const logUserOut = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_START });
    return auth
      .signOut()
      .then(() => window.location.reload())
      .catch((err) => {
        alert(err);
        dispatch({ type: LOADING_FINISH });
      });
  };
};

export const fetchUserDataAndLogIn = (dispatch) => {
  dispatch({ type: LOADING_START });

  getCurrentUserRef().on('value', (data) => {
    data = data.val();

    const user = { ...data, uid: getCurrentUserUid(), notifications: {} };

    return getNotificationSettings().then((settings) => {
      user.notifications.settings = settings;

      return dispatch({
        type: LOG_USER_IN,
        payload: user,
      });
    });
  });

  getNotificationSettingsRef().on('value', (data) => {
    data = data.val();
    
    return dispatch({
      type: UPDATE_USER_NOTIFCATION_SETTINGS,
      payload: data,
    });
  });
};

import React from 'react';
import { IonText, IonItem, IonIcon } from '@ionic/react';
import { Link } from 'react-router-dom';
import { chevronForwardOutline } from 'ionicons/icons';

const SettingsListItem = ({ link, icon, name, color = 'dark' }) => {
  return (
    <Link to={link} className="Link">
      <IonItem className="SettingsList__input">
        <IonIcon icon={icon} color={color} slot="start" />
        <IonIcon
          icon={chevronForwardOutline}
          color={color}
          className="forward-icon"
          slot="end"
        />
        <IonText color={color}>{name}</IonText>
      </IonItem>
    </Link>
  );
};

export default SettingsListItem;

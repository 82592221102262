import React from 'react';
import { Link } from 'react-router-dom';
import { IonItem, IonIcon, IonLabel } from '@ionic/react';
import {
  personAddOutline,
  chatbubblesOutline,
  flameOutline,
  cameraOutline,
  pricetagOutline,
  trophyOutline,
} from 'ionicons/icons';
import ReactTimeAgo from 'react-time-ago';

import notificationTypes from '../../constants/notificationTypes';
import './Notification.sass';

const Notification = ({ data }) => {
  const renderIconByType = value => {
    switch (value) {
      case notificationTypes.NEW_FOLLOWER:
        return personAddOutline;
      case notificationTypes.NEW_VEHICLE_COMMENT:
      case notificationTypes.NEW_COMPONENT_COMMENT:
      case notificationTypes.FOLLOWING_COMMENTS_VEHICLE:
      case notificationTypes.FOLLOWING_COMMENTS_COMPONENT:
        return chatbubblesOutline;
      case notificationTypes.NEW_FOLLOWING_IMAGE:
        return cameraOutline;
      case notificationTypes.NEW_FOLLOWING_COMPONENT:
        return pricetagOutline;
      case notificationTypes.NEW_FOLLOWING_LEVEL:
        return trophyOutline;
      default: {
        return flameOutline;
      }
    }
  };

  return (
    <Link to={data.target} className="Link">
      <IonItem className="Notification">
        <IonIcon
          icon={renderIconByType(data.type)}
          color={data.seen ? 'dark' : 'primary'}
          slot="start"
        />
        <IonLabel color="dark" className="ion-text-wrap">
          <h3>{data.content}</h3>
          <small>
            <ReactTimeAgo date={new Date(data.date)} />
          </small>
        </IonLabel>
      </IonItem>
    </Link>
  );
};

export default Notification;

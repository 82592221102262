import React from 'react';
import { Link } from 'react-router-dom';
import { IonItem, IonLabel, IonAvatar, IonIcon } from '@ionic/react';
import ReactTimeAgo from 'react-time-ago';
import { checkmarkCircleOutline } from 'ionicons/icons';

import LikeButton from '../../ButtonGroup/LikeButton/LikeButton';
import ReplyButton from '../../ButtonGroup/ReplyButton/ReplyButton';

import { PROFILE_ROUTE } from '../../../constants/routes';
import { getCurrentUserUid } from '../../../api/firebase';
import { DEFAULT_USER_IMAGE_SRC } from '../../../constants/imageSources';
import { getOptimizedImageURL } from '../../../utils/utils';
import './Comment.sass';

const Comment = ({
  comment,
  parentCommentId,
  onReply,
  vehicleId,
  componentId,
}) => {
  const currentUserUid = getCurrentUserUid();
  const user = comment.user;
  const isRemovedUser = !user.uid || !user.profileImage || !user.name;

  return (
    <IonItem className={'Comment' + (parentCommentId ? '--reply' : '')}>
      <IonAvatar className="Comment__avatar" slot="start">
        {!isRemovedUser ? (
          <Link to={PROFILE_ROUTE + user.uid} className="Link">
            <img
              src={getOptimizedImageURL(user.profileImage, 80, null, '1:1')}
              alt="avatar"
            />
          </Link>
        ) : (
          <img src={DEFAULT_USER_IMAGE_SRC} alt="avatar" />
        )}
      </IonAvatar>
      <IonLabel
        style={{ marginBottom: 0, marginRight: 0 }}
        className="ion-text-wrap"
      >
        <div className="Comment__title">
          {!isRemovedUser ? (
            <Link to={PROFILE_ROUTE + user.uid} className="Link">
              <h2>
                {user.name}
                {user.verified && (
                  <IonIcon icon={checkmarkCircleOutline} color="primary" />
                )}
              </h2>
            </Link>
          ) : (
            <h2 style={{ fontStyle: 'italic' }}>Removed</h2>
          )}
          <span className="Comment__title__time">
            <ReactTimeAgo date={new Date(comment.date)} />
          </span>
        </div>
        <p>{comment.comment}</p>
        <div className="Comment-button-wrapper">
          <LikeButton
            color="medium"
            type="comment"
            path={{
              vehicleId,
              componentId,
              commentId: comment.id,
              parentCommentId,
            }}
            isLiked={comment.likes ? comment.likes[currentUserUid] : false}
            total={comment.likes ? Object.values(comment.likes).length : 0}
            disabled={user.uid === currentUserUid || isRemovedUser}
          />
          <ReplyButton
            onClick={() =>
              onReply(parentCommentId ? parentCommentId : comment.id)
            }
            disabled={isRemovedUser}
          />
        </div>
      </IonLabel>
    </IonItem>
  );
};

export default Comment;

import * as actions from './actions';

const initialState = {
  notifications: [],
  isUnseenNotification: false,
  isAllNotificarionsFetched: false,
};

const getIsUnseenNotification = notifications =>
  notifications.findIndex(notification => !notification.seen) !== -1;

const showroomReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_NOTIFICATIONS: {
      const { isRefresh, notifications } = action.payload;

      return {
        ...state,
        notifications: isRefresh
          ? notifications
          : [...state.notifications, ...notifications],
        isUnseenNotification: getIsUnseenNotification(notifications),
      };
    }
    case actions.UNSHIFT_NEW_NOTIFICATION: {
      const lastNotification = state.notifications[0];
      const newNotification = action.payload;

      if (lastNotification && newNotification.id === lastNotification.id) {
        return state;
      }

      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
        isUnseenNotification: true,
      };
    }
    case actions.READ_NOTIFICATION: {
      const readNotification = action.payload;
      const readNotificationIndex = state.notifications.findIndex(
        notification => notification.id === readNotification.id
      );

      if (readNotificationIndex === -1) return state;

      const updatedNotifications = [...state.notifications];
      updatedNotifications[readNotificationIndex] = readNotification;

      return {
        ...state,
        notifications: updatedNotifications,
        isUnseenNotification: getIsUnseenNotification(updatedNotifications),
      };
    }
    case actions.ALL_NOTIFICATIONS_FETCHED:
      return {
        ...state,
        isAllNotificarionsFetched: true,
      };
    default:
      return state;
  }
};

export default showroomReducer;

import React, { useState } from 'react';
import { getOptimizedImageURL } from '../../utils/utils';
import './Gallery.sass';

const Gallery = ({ images = [], openFullscreenGallery }) => {
  const [showAll, setShowAll] = useState(false);

  const renderGalleryItems = () => {
    if (showAll) {
      return images.map((el, index) => {
        return (
          <div
            className="Gallery__item"
            style={{
              backgroundImage: `url(${getOptimizedImageURL(
                el.src,
                230,
                null,
                '1:1'
              )})`,
            }}
            onClick={() => openFullscreenGallery(index)}
            key={index}
          ></div>
        );
      });
    } else {
      const reducedArray = [...images].splice(0, 9);

      return reducedArray.map((el, index) => {
        if (index === 8 && images.length > 9) {
          return (
            <div
              className="Gallery__item"
              key={index}
              onClick={() => setShowAll(true)}
              style={{
                backgroundImage: `url(${getOptimizedImageURL(
                  el.src,
                  230,
                  null,
                  '1:1'
                )})`,
              }}
            >
              <div className="Gallery-overlay">+ {images.length - 9}</div>
            </div>
          );
        }

        return (
          <div
            className="Gallery__item"
            onClick={() => openFullscreenGallery(index)}
            style={{
              backgroundImage: `url(${getOptimizedImageURL(
                el.src,
                230,
                null,
                '1:1'
              )})`,
            }}
            key={index}
          ></div>
        );
      });
    }
  };

  return <div className="Gallery">{renderGalleryItems()}</div>;
};

export default Gallery;

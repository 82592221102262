import React from 'react';
import Columned from 'react-columned';

import './MasonryGrid.sass';

const MasonryGrid = ({ columns, children }) => {
  return (
    <Columned
      columns={columns}
      className={columns === 2 ? 'MasonryGrid--two-columns' : 'MasonryGrid'}
    >
      {children}
    </Columned>
  );
};

export default MasonryGrid;

import React, { Component } from 'react';

import Tag from '../Tag/Tag';

import { getOptimizedImageURL } from '../../../utils/utils';
import { vibrate } from '../../../utils/vibrate';

import './TaggingContainer.sass';

class TaggingContainer extends Component {
  state = {
    newTag: null,
    isLandscape: null,
  };
  container = React.createRef();

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => this.setIsLandscape(true);

  // in order to prevent clicking "outside" of the image
  setIsLandscape = (didResize = false) => {
    if (didResize) {
      return this.setState((prevState) => ({
        isLandscape: !prevState.isLandscape,
      }));
    }

    if (this.state.isLandscape === null)
      return this.setState({
        isLandscape: this.container.current.offsetHeight >= window.innerHeight,
      });
  };

  componentDidUpdate() {
    if (this.container.current.offsetHeight) {
      this.setIsLandscape();
    }

    const { lastTagData, setLastTagData, imageIndex } = this.props;
    if (
      lastTagData &&
      lastTagData.delete &&
      lastTagData.imageIndex === imageIndex
    ) {
      return setLastTagData(null, () => this.setState({ newTag: null }));
    }
  }

  createNewTag = (e) => {
    if (!e.target.src) return;

    vibrate();
    this.props.enableTagging();

    const newTagPosition = {
      offset: {
        // some adjustments in pixels
        x: e.nativeEvent.offsetX - 8,
        y: e.nativeEvent.offsetY - 10,
      },
      dimensions: {
        width: e.target.width,
        height: e.target.height,
      },
    };

    const top = `${this.getRelativePosition(
      newTagPosition.offset.y,
      newTagPosition.dimensions.height
    )}%`;
    const left = `${this.getRelativePosition(
      newTagPosition.offset.x,
      newTagPosition.dimensions.width
    )}%`;
    const newTag = {
      top,
      left,
    };

    this.setState({ newTag: newTag });
    this.props.setLastTagData(newTag);
  };

  getRelativePosition = (clickPos, elementSize) =>
    clickPos / (elementSize / 100);

  getParentSizes = () =>
    this.container.current && {
      width: this.container.current.clientWidth,
      height: this.container.current.clientHeight,
    };

  getComponentById = (id) => {
    const components = this.props.components;
    const index = components.findIndex((component) => component.id === id);
    const component = components[index];

    return component;
  };

  render() {
    const {
      tags = [],
      withTagging = true,
      showPopovers,
      editMode,
      closeFullscreenGallery,
      enableTagging,
      setLastTagData,
      imageIndex,
      image,
    } = this.props;
    const { newTag, isLandscape } = this.state;

    return (
      <div
        className={
          isLandscape ? 'TaggingContainer--landscape' : 'TaggingContainer'
        }
        onClick={
          !showPopovers && !editMode && withTagging ? this.createNewTag : null
        }
        ref={this.container}
      >
        <img
          className={
            isLandscape
              ? 'TaggingContainer-image--landscape'
              : 'TaggingContainer-image'
          }
          src={getOptimizedImageURL(image, 1024)}
          alt="car"
        />
        {tags.map((el, index) => (
          <Tag
            data={el}
            imageIndex={imageIndex}
            parentSizes={this.getParentSizes()}
            showPopovers={showPopovers}
            editMode={editMode}
            component={this.getComponentById(el.componentId)}
            onClick={() =>
              editMode
                ? setLastTagData(tags[index], enableTagging)
                : closeFullscreenGallery(false)
            }
            key={el.componentId}
          />
        ))}
        {newTag && <Tag data={newTag} />}
      </div>
    );
  }
}

export default TaggingContainer;

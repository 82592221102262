import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonModal, IonContent, IonSlides, IonLoading } from '@ionic/react';

import TagNewComponent from '../TagNewComponent/TagNewComponent';
import EditComponent from '../EditComponent/EditComponent';
import BackButton from '../../ButtonGroup/BackButton/BackButton';
import { LevelUpVisibilityContext } from '../../CarDetails/CarDetails';

import {
  LOADING_START,
  LOADING_FINISH,
  fetchMyCarDetails,
} from '../../../store/car/actions';
import './TaggingModal.sass';

let slides = null;

class TaggingModal extends Component {
  ionContent = React.createRef();
  state = {
    smallSizeModal: null,
    currentSlide: 0,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.imageIndex !== this.props.imageIndex ||
      prevProps.editMode !== this.props.editMode
    ) {
      this.setState({ smallSizeModal: null });
    }
  }

  handleSwipingNext = () => {
    const nextSlide = this.state.currentSlide + 1;

    return slides
      .lockSwipes(false)
      .then(() => slides.slideNext())
      .then(() => slides.lockSwipes(true))
      .then(() =>
        this.setState({
          currentSlide: nextSlide,
          smallSizeModal: !this.props.editMode && nextSlide === 1,
        })
      );
  };

  onSwipingBack = () => {
    this.ionContent.current.scrollToTop();
    const nextSlide = this.state.currentSlide - 1;
    const { editMode } = this.props;

    return slides
      .lockSwipes(false)
      .then(() => slides.slidePrev())
      .then(() => slides.lockSwipes(true))
      .then(() =>
        this.setState({
          currentSlide: nextSlide,
          smallSizeModal:
            (editMode && nextSlide === 0) || (!editMode && nextSlide === 1),
        })
      );
  };

  onWillDismiss = () =>
    this.setState({ currentSlide: 0 }, this.props.disableTagging);

  filterUntaggedComponents = () => {
    const { images, imageIndex, components } = this.props;

    if (!images[imageIndex].tags) return components;

    return components.filter(
      component =>
        !images[imageIndex].tags.find(tag => tag.componentId === component.id)
    );
  };

  getModalCSSClass = () => {
    const { smallSizeModal } = this.state;

    switch (smallSizeModal) {
      case null:
        return 'ion-hide';
      case false:
        return 'TaggingModal';
      case true:
        return 'TaggingModal--small-size';
      default:
        return 'TaggingModal';
    }
  };

  render() {
    const {
      isOpen,
      editMode,
      components,
      lastTag,
      imageIndex,
      closeModal,
      startLoading,
      finishLoading,
      isLoading,
      fetchMyCarDetails,
    } = this.props;
    const { currentSlide, smallSizeModal } = this.state;

    const untaggedComponents = this.filterUntaggedComponents();
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      loop: true,
      slidesPerView: 1,
    };

    return (
      <>
        <IonLoading isOpen={isLoading} message={'Please wait...'} />
        <IonModal
          swipeToClose
          isOpen={isOpen}
          backdropDismiss
          showBackdrop={false}
          onWillPresent={() =>
            this.setState({
              smallSizeModal: !untaggedComponents.length || editMode,
            })
          }
          onWillDismiss={this.onWillDismiss}
          cssClass={this.getModalCSSClass()}
        >
          <IonContent
            ref={this.ionContent}
            className="TaggingModal-content"
            forceOverscroll={false}
            style={{ '--padding-top': currentSlide === 0 ? 0 : '2.8rem' }}
            scrollY={currentSlide === 0 && !editMode ? false : !smallSizeModal}
          >
            <div className="TaggingModal-top-controls">
              <div className="TaggingModal-top-controls__dash"></div>
              {currentSlide !== 0 && (
                <BackButton onClick={this.onSwipingBack} />
              )}
            </div>
            <IonSlides
              ref={el => (slides = el)}
              onIonSlidesDidLoad={() => slides.lockSwipes(true)}
              options={slideOpts}
            >
              {!editMode ? (
                <LevelUpVisibilityContext.Consumer>
                  {({ openLevelUpModal, isNewLevel }) => (
                    <TagNewComponent
                      currentSlide={currentSlide}
                      closeModal={closeModal}
                      totalComponentAmount={components.length}
                      components={untaggedComponents}
                      lastTag={lastTag}
                      imageIndex={imageIndex}
                      onSwipingNext={this.handleSwipingNext}
                      startLoading={startLoading}
                      finishLoading={finishLoading}
                      openLevelUpModal={openLevelUpModal}
                      isNewLevel={isNewLevel}
                    />
                  )}
                </LevelUpVisibilityContext.Consumer>
              ) : (
                <EditComponent
                  closeModal={closeModal}
                  components={components}
                  imageIndex={imageIndex}
                  lastTag={lastTag}
                  onSwipingNext={this.handleSwipingNext}
                  startLoading={startLoading}
                  finishLoading={finishLoading}
                  fetchMyCarDetails={fetchMyCarDetails}
                />
              )}
            </IonSlides>
          </IonContent>
        </IonModal>
      </>
    );
  }
}

const mapStateToProps = store => ({
  isLoading: store.car.isLoading,
});

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch({ type: LOADING_START }),
  finishLoading: () => dispatch({ type: LOADING_FINISH }),
  fetchMyCarDetails: () => dispatch(fetchMyCarDetails(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaggingModal);

import React from 'react';
import {
  IonCard,
  IonSkeletonText,
  IonCardContent,
  IonThumbnail,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/react';

import LikeButton from '../ButtonGroup/LikeButton/LikeButton';

const MyLikesSkeleton = () => {
  return (
    <IonCard className="MyLikesCard" style={{ marginLeft: 0, marginRight: 0 }}>
      <IonCardContent>
        <IonList>
          <IonItem className="MyLikesCard-item">
            <IonThumbnail className="MyLikesCard-item__image" slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel className="MyLikesCard-item__info" color="medium">
              <IonSkeletonText animated style={{ width: '80%' }} />
              <IonSkeletonText animated style={{ width: '50%' }} />
            </IonLabel>
            <div className="MyLikesCard-item__icon" slot="end">
              <LikeButton type="icon" isLiked />
            </div>
          </IonItem>
          <IonItem className="MyLikesCard-item">
            <IonThumbnail className="MyLikesCard-item__image" slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel className="MyLikesCard-item__info" color="medium">
              <IonSkeletonText animated style={{ width: '80%' }} />
              <IonSkeletonText animated style={{ width: '50%' }} />
            </IonLabel>
            <div className="MyLikesCard-item__icon" slot="end">
              <LikeButton type="icon" isLiked />
            </div>
          </IonItem>
          <IonItem className="MyLikesCard-item">
            <IonThumbnail className="MyLikesCard-item__image" slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel className="MyLikesCard-item__info" color="medium">
              <IonSkeletonText animated style={{ width: '80%' }} />
              <IonSkeletonText animated style={{ width: '50%' }} />
            </IonLabel>
            <div className="MyLikesCard-item__icon" slot="end">
              <LikeButton type="icon" isLiked />
            </div>
          </IonItem>
          <IonItem className="MyLikesCard-item">
            <IonThumbnail className="MyLikesCard-item__image" slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel className="MyLikesCard-item__info" color="medium">
              <IonSkeletonText animated style={{ width: '80%' }} />
              <IonSkeletonText animated style={{ width: '50%' }} />
            </IonLabel>
            <div className="MyLikesCard-item__icon" slot="end">
              <LikeButton type="icon" isLiked />
            </div>
          </IonItem>
          <IonItem className="MyLikesCard-item">
            <IonThumbnail className="MyLikesCard-item__image" slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <IonLabel className="MyLikesCard-item__info" color="medium">
              <IonSkeletonText animated style={{ width: '80%' }} />
              <IonSkeletonText animated style={{ width: '50%' }} />
            </IonLabel>
            <div className="MyLikesCard-item__icon" slot="end">
              <LikeButton type="icon" isLiked />
            </div>
          </IonItem>
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default MyLikesSkeleton;

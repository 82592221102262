import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IonChip } from '@ionic/react';

import { vibrate } from '../../../utils/vibrate';
import { followProfile } from '../../../api/api';
import { LOGIN_ROUTE } from '../../../constants/routes';

const FollowButton = ({ profileId, isLoggedIn, followingList }) => {
  const isFollowed = followingList[profileId];

  const onClick = () => {
    vibrate();
    return followProfile(profileId);
  };

  const followButton = (
    <IonChip color="primary" outline={!isFollowed} onClick={onClick}>
      {isFollowed ? 'Unfollow' : 'Follow'}
    </IonChip>
  );

  return !isLoggedIn ? (
    <Link to={LOGIN_ROUTE} className="Link">
      {followButton}
    </Link>
  ) : (
    followButton
  );
};

const mapStateToProps = (store) => ({
  isLoggedIn: store.user.isLoggedIn,
  followingList:
    store.user.user && store.user.user.following
      ? store.user.user.following
      : {},
});

export default connect(mapStateToProps)(FollowButton);

import { postNewCar, updateMyCar } from '../../api/api';
import {
  getCurrentUserCarRef,
  getCurrentUserCar,
  getComponentsByCar,
} from '../../api/firebase';

export const FETCH_MY_CAR = 'FETCH_MY_CAR';
export const LOADING_START = 'LOADING_CAR_START';
export const LOADING_FINISH = 'LOADING_CAR_FINISH';

export const addNewCar = carDetails => {
  return dispatch => {
    dispatch({ type: LOADING_START });
    return postNewCar(carDetails).catch(err => {
      alert(err);
      dispatch({ type: LOADING_FINISH });
    });
  };
};

export const editMyCar = carDetails => {
  return dispatch => {
    dispatch({ type: LOADING_START });
    return updateMyCar(carDetails).catch(err => {
      alert(err);
      dispatch({ type: LOADING_FINISH });
    });
  };
};

export const fetchMyCarDetails = isHardRefresh => {
  return dispatch => {
    dispatch({ type: LOADING_START });

    const formatAndDispatchData = data => {
      const carDetails = data ? Object.values(data)[0] : null;

      if (carDetails) {
        const carId = Object.keys(data)[0];
        carDetails.uid = carId;

        return getComponentsByCar(carId).then(components => {
          if (components) {
            carDetails.components = Object.entries(components).map(
              ([id, component]) => {
                component.id = id;
                delete component.commentAmount;
                delete component.vehicle;
                return component;
              }
            );
          }

          return dispatch({
            type: FETCH_MY_CAR,
            payload: carDetails,
          });
        });
      }

      return dispatch({
        type: FETCH_MY_CAR,
        payload: null,
      });
    };

    if (!isHardRefresh) {
      return getCurrentUserCarRef().on('value', data =>
        formatAndDispatchData(data.val())
      );
    }

    return getCurrentUserCar().then(data => formatAndDispatchData(data));
  };
};

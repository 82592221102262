export const SHOWROOM_ROUTE = '/showroom';
export const VEHICLE_ROUTE = '/vehicle/'; //:id
export const CATALOGUE_ROUTE = '/catalogue';
export const NOTIFICATIONS_ROUTE = '/notifications';
export const MY_CAR_ROUTE = '/my-car';
export const EDIT_MY_CAR_ROUTE = '/edit-car';
export const PROFILE_ROUTE = '/user/'; //:id
export const FOLLOWERS_ROUTE = '/user/:id/followers';
export const FOLLOWING_ROUTE = '/user/:id/following';
export const MY_LIKES_ROUTE = '/my-likes';
export const SETTINGS_ROUTE = '/settings';
export const SETTINGS_PROFILE_ROUTE = '/settings/profile';
export const SETTINGS_PASSWORD_ROUTE = '/settings/password';
export const SETTINGS_MAIL_ROUTE = '/settings/mail';
export const SETTINGS_NOTIFICATIONS_ROUTE = '/settings/notifications';
export const SETTINGS_DISPLAY_ROUTE = '/settings/display';
export const SETTINGS_DELETE_CAR_ROUTE = '/settings/delete-car';
export const SETTINGS_DELETE_PROFILE_ROUTE = '/settings/delete-profile';
export const FAQ_ROUTE = '/faq';
export const PRIVACY_ROUTE = '/privacy';
export const LEGAL_NOTICE_ROUTE = '/legal-notice';
export const TERMS_OF_SERVICE_ROUTE = '/terms-of-service';
export const COMPONENT_ROUTE = '/component/'; // d:id
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const NEW_PASSWORD_ROUTE = '/new-password';
export const WELCOME_ROUTE = '/welcome';
export const ACTION_ROUTE = '/action';

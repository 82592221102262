import React from 'react';
import { Link } from 'react-router-dom';
import {
  IonTextarea,
  IonChip,
  IonIcon,
  IonLabel,
  IonList,
  IonItem,
  IonButton,
  IonSpinner,
} from '@ionic/react';
import { close } from 'ionicons/icons';

import { LOGIN_ROUTE } from '../../../constants/routes';
import './CommentInput.sass';

const CommentInput = ({
  inputRef,
  isLoggedIn,
  onSubmit,
  replyTo,
  onReplyReset,
  isSubmitting,
}) => {
  return (
    <div className="CommentInput">
      {isLoggedIn ? (
        <form onSubmit={onSubmit}>
          <IonList>
            <IonItem className="CommentInput__input">
              {replyTo && (
                <IonChip className="CommentInput__chip" onClick={onReplyReset}>
                  <IonIcon icon={close} />
                  <IonLabel>Reply:</IonLabel>
                </IonChip>
              )}
              <IonTextarea
                ref={inputRef}
                autoGrow
                placeholder="Type your comment..."
                required
                autocorrect
                spellcheck
                rows={1}
              />
            </IonItem>
          </IonList>
          <IonButton
            className="CommentInput__button"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <IonSpinner /> : 'Comment'}
          </IonButton>
        </form>
      ) : (
        <Link className="CommentInput__link" to={LOGIN_ROUTE}>
          Log in to comment
        </Link>
      )}
    </div>
  );
};

export default CommentInput;

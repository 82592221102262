import React from 'react';

import './TagPopover.sass';

const TagPopover = ({ position, name, parentSizes }) => {
  if (!parentSizes) return null;

  const positionNumeric = {
    top: +position.top.split('%')[0],
    left: +position.left.split('%')[0],
  };
  const styles = {
    ...position,
    maxWidth:
      positionNumeric.left <= 50
        ? // * 1.5 because of translateX(-50%)
          1.5 * parentSizes.width * (positionNumeric.left / 100) + 'px'
        : 1.5 * parentSizes.width * ((100 - positionNumeric.left) / 100) + 'px',
  };
  const isInverted =
    ((100 - positionNumeric.top) / 100) * parentSizes.height < 32;

  return (
    <div
      className={'TagPopover' + (isInverted ? '--inverted' : '')}
      style={styles}
    >
      <div className="TagPopover__polygon"></div>
      {name}
    </div>
  );
};

export default TagPopover;

import React, { Component } from 'react';
import { IonContent, IonPage, IonText, withIonLifeCycle } from '@ionic/react';
import Header from '../../components/Header/Header';

import FollowersCard from '../../components/CardGroup/FollowersCard/FollowersCard';

import { getFollowing } from '../../api/firebase';

class Followers extends Component {
  state = {
    followers: null,
  };

  ionViewDidEnter() {
    const uid = this.props.history.location.pathname.split('/')[2];

    return getFollowing(uid, 'followers').then(followers =>
      this.setState({ followers })
    );
  }

  render() {
    const { followers } = this.state;

    return (
      <IonPage>
        <Header goBack />
        <IonContent className="ion-padding">
          <IonText color="dark">
            <h1>Followers</h1>
          </IonText>
          <FollowersCard followers={followers} />
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Followers);

import React, { Component } from 'react';
import { IonContent, IonPage, IonText, withIonLifeCycle } from '@ionic/react';
import Header from '../../components/Header/Header';

import FollowersCard from '../../components/CardGroup/FollowersCard/FollowersCard';

import { getFollowing, getCurrentUserUid } from '../../api/firebase';

class Following extends Component {
  state = {
    following: null,
    isCurrentUser: false,
  };

  ionViewDidEnter() {
    const uid = this.props.history.location.pathname.split('/')[2];

    return getFollowing(uid, 'following').then(following =>
      this.setState({ following, isCurrentUser: uid === getCurrentUserUid() })
    );
  }

  ionViewWillLeave() {
    this.setState({ following: null, isCurrentUser: false });
  }

  handleUnfollow = el => {
    const following = [...this.state.following];
    const index = following.indexOf(el);

    document.getElementById(`following-${el.uid}`).style.opacity = '0';

    setTimeout(() => {
      following.splice(index, 1);

      this.setState({
        following,
      });
    }, 300);
  };

  render() {
    const { following, isCurrentUser } = this.state;

    return (
      <IonPage>
        <Header goBack />
        <IonContent className="ion-padding">
          <IonText color="dark">
            <h1>Following</h1>
          </IonText>
          <FollowersCard
            followers={following}
            withAnimation={isCurrentUser}
            onUnfollow={this.handleUnfollow}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Following);

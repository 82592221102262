import React from 'react';
import { Link } from 'react-router-dom';
import {
  IonCard,
  IonCardContent,
  IonChip,
  IonItem,
  IonLabel,
} from '@ionic/react';

import LikeButton from '../../ButtonGroup/LikeButton/LikeButton';

import { COMPONENT_ROUTE } from '../../../constants/routes';
import {
  formatCategoryNameFromDB,
  formatPrice,
  getOptimizedImageURL,
} from '../../../utils/utils';
import { getCurrentUserUid } from '../../../api/firebase';
import './CatalogueComponentCard.sass';

const CatalogueComponentCard = ({ component, shrinked }) => {
  const isOwner = component.owner === getCurrentUserUid();

  return (
    <IonCard
      className={`CatalogueComponentCard ${
        shrinked ? 'CatalogueComponentCard--shrinked' : ''
      }`}
    >
      <Link className="Link" to={COMPONENT_ROUTE + `${component.id}`}>
        <img
          className="CatalogueComponentCard__image"
          srcSet={`${getOptimizedImageURL(component.image, 730)} 768w,
          ${getOptimizedImageURL(component.image, 385)} 425w`}
          src={getOptimizedImageURL(component.image, 385)}
          alt="Component"
        />
      </Link>
      <IonCardContent className="CatalogueComponentCard-content">
        <Link
          className="CatalogueComponentCard-card-info Link"
          to={COMPONENT_ROUTE + `${component.id}`}
        >
          <IonItem className="CatalogueComponentCard-card-info__title">
            <IonLabel>{component.name}</IonLabel>
          </IonItem>
          <div>
            <div className="CatalogueComponentCard-card-info__price">
              {formatPrice(component.price)} €
            </div>
            <IonChip className="CatalogueComponentCard-card-info__chip" outline>
              <IonLabel color="primary">
                {formatCategoryNameFromDB(component.category)}
              </IonLabel>
            </IonChip>
          </div>
        </Link>
        <div className="CatalogueComponentCard-like-zone">
          <LikeButton
            type="icon"
            size="large"
            path={{ componentId: component.id }}
            isLiked={
              component.likes ? component.likes[getCurrentUserUid()] : false
            }
            disabled={isOwner}
          />
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default CatalogueComponentCard;

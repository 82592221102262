import React, { Component } from 'react';
import {
  IonButton,
  IonItem,
  IonText,
  IonInput,
  IonLabel,
  IonList,
  IonRange,
} from '@ionic/react';
import { ImagePicker } from '@ionic-native/image-picker';

import { getNumericInput, getPriceInput } from '../../../utils/utils';
import { vibrate } from '../../../utils/vibrate';
import { DEFAULT_COMPONENT_IMAGE_SRC } from '../../../constants/imageSources';
import categories from '../../../constants/categories';
import './ComponentInputs.sass';

const formatTireSizeToArray = (tireSize) => {
  tireSize = tireSize.replace(/ /g, '');
  const strParts = tireSize.split('/');

  return [strParts[0], strParts[1].split('R')[0], tireSize.split('R')[1]];
};

class ComponentInputs extends Component {
  state = {
    name:
      this.props.componentData && this.props.componentData.name
        ? this.props.componentData.name
        : null,
    make:
      this.props.componentData && this.props.componentData.make
        ? this.props.componentData.make
        : null,
    retailer:
      this.props.componentData && this.props.componentData.retailer
        ? this.props.componentData.retailer
        : null,
    color:
      this.props.componentData && this.props.componentData.color
        ? this.props.componentData.color
        : null,
    rimSize:
      this.props.componentData && this.props.componentData.rimSize
        ? this.props.componentData.rimSize
        : null,
    tireSize:
      this.props.componentData && this.props.componentData.tireSize
        ? formatTireSizeToArray(this.props.componentData.tireSize)
        : ['', '', ''],
    price:
      this.props.componentData && this.props.componentData.price
        ? this.props.componentData.price + ''
        : null,
    difficulty:
      this.props.componentData && this.props.componentData.difficulty
        ? this.props.componentData.difficulty
        : 1,
    image:
      this.props.componentData && this.props.componentData.image
        ? this.props.componentData.image
        : null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.resetAllInputs();
    }
  }

  resetAllInputs = () =>
    this.setState({
      name: null,
      make: null,
      retailer: null,
      color: null,
      rimSize: null,
      tireSize: ['', '', ''],
      price: null,
      difficulty: 1,
      image: null,
    });

  onImageAdding = () => {
    try {
      ImagePicker.getPictures({
        maximumImagesCount: 1,
        quality: 80,
        outputType: 1, // base64 format
      }).then((imageFile) => {
        if (!imageFile.length) return;

        this.setState({ image: { selectedImgSrc: imageFile[0] } });
      });
    } catch {}
  };

  onTireSizeInputChange = (e, partIndex) => {
    const value = e.target.value;

    return this.setState((prevState) => {
      const updTireSize = [...prevState.tireSize];
      updTireSize[partIndex] = partIndex !== 0 ? getNumericInput(value) : value;
      return { tireSize: updTireSize };
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { category, onSubmit } = this.props;
    const { image } = this.state;
    const component = {
      category,
    };

    if (image) {
      component.image = image;
    }

    Object.entries(this.state).map(([fieldName, value]) => {
      if (!value) return false;
      if (fieldName === 'tireSize') {
        if (!value[0]) return false;
        return (component[
          fieldName
        ] = `${value[0]} / ${value[1]} R ${value[2]}`);
      }
      if (fieldName === 'rimSize') {
        return (component[fieldName] = +value);
      }

      return (component[fieldName] = value);
    });

    onSubmit(component);
  };

  renderCategoryInputs = () => {
    const { category } = this.props;
    const {
      name,
      make,
      retailer,
      color,
      rimSize,
      tireSize,
      difficulty,
      image,
      price,
    } = this.state;

    return (
      <IonList>
        <IonItem className="TaggingModal-content-item">
          <IonLabel color="primary" position="stacked">
            Name <IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            value={name}
            placeholder="what did you tag?"
            onIonChange={(e) => this.setState({ name: e.target.value })}
            required
            autocorrect
          />
        </IonItem>
        <IonItem className="TaggingModal-content-item">
          <IonLabel color="primary" position="stacked">
            Make <IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            value={make}
            placeholder="Brembo, BBS etc."
            onIonChange={(e) => this.setState({ make: e.target.value })}
            required
            autocorrect
          />
        </IonItem>
        <IonItem className="TaggingModal-content-item">
          <IonLabel color="primary" position="stacked">
            Retailer
          </IonLabel>
          <IonInput
            value={retailer}
            placeholder="www.amazon.com"
            pattern="^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$"
            onIonChange={(e) => this.setState({ retailer: e.target.value })}
          />
        </IonItem>
        {category === categories.PAINT_WRAP && (
          <IonItem className="TaggingModal-content-item">
            <IonLabel color="primary" position="stacked">
              Color <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              value={color}
              placeholder="black, green etc."
              onIonChange={(e) => this.setState({ color: e.target.value })}
              required
              autocorrect
            />
          </IonItem>
        )}
        {category === categories.RIMS && (
          <IonItem className="TaggingModal-content-item">
            <IonLabel color="primary" position="stacked">
              Rim size <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              value={rimSize}
              placeholder="21"
              maxlength="2"
              required
              onIonChange={(e) =>
                this.setState({ rimSize: getNumericInput(e.target.value) })
              }
              inputmode="numeric"
            />
          </IonItem>
        )}
        {category === categories.TIRES && (
          <>
            <div className="ion-text-left">
              <IonLabel color="primary" position="stacked">
                Tire size <IonText color="danger">*</IonText>
              </IonLabel>
            </div>
            <IonItem className="TaggingModal-content-item">
              <input
                className="ComponentInputs-grouped-input--4-symbols"
                value={tireSize[0]}
                maxLength="4"
                placeholder="195"
                onChange={(e) => this.onTireSizeInputChange(e, 0)}
                required
              />
              <div className="ComponentInputs-grouped-input-separator">/</div>
              <input
                className="ComponentInputs-grouped-input"
                value={tireSize[1]}
                maxLength="2"
                placeholder="55"
                onChange={(e) => this.onTireSizeInputChange(e, 1)}
                required
                inputMode="numeric"
              />
              <div className="ComponentInputs-grouped-input-separator">R</div>
              <input
                className="ComponentInputs-grouped-input"
                value={tireSize[2]}
                maxLength="2"
                placeholder="16"
                onChange={(e) => this.onTireSizeInputChange(e, 2)}
                required
                inputMode="numeric"
              />
            </IonItem>
          </>
        )}
        <IonItem className="TaggingModal-content-item">
          <IonLabel color="primary" position="stacked">
            Price <IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            value={price}
            placeholder="199,99"
            maxlength="8"
            required
            onIonChange={(e) =>
              this.setState({ price: getPriceInput(e.target.value) })
            }
            inputmode="decimal"
          />
        </IonItem>
        <div className="TaggingModal-content-item__title-block  ion-text-left">
          <IonLabel color="primary" position="stacked">
            Difficulty
          </IonLabel>
        </div>
        <IonItem className="TaggingModal-content-item">
          <IonRange
            min={1}
            max={5}
            step={1}
            snaps={true}
            value={difficulty}
            color="primary"
            onIonChange={(e) => {
              vibrate();
              this.setState({ difficulty: e.target.value });
            }}
          >
            <IonLabel slot="start">1</IonLabel>
            <IonLabel slot="end">5</IonLabel>
          </IonRange>
        </IonItem>
        <div className="TaggingModal-content-item__title-block ion-text-left ion-padding-bottom">
          <IonLabel color="primary" position="stacked">
            Choose your image
          </IonLabel>
        </div>
        <IonItem
          className="TaggingModal-content-item TaggingModal-content-upload-btn"
          onClick={this.onImageAdding}
        >
          <div>
            <img
              src={
                image
                  ? image.selectedImgSrc
                    ? `data:image/png;base64,${image.selectedImgSrc}`
                    : image
                  : DEFAULT_COMPONENT_IMAGE_SRC
              }
              alt="component"
            />
          </div>
        </IonItem>
      </IonList>
    );
  };

  render() {
    return (
      <form
        className="TaggingModal-content ion-padding"
        onSubmit={this.onSubmit}
      >
        {this.renderCategoryInputs()}
        <IonButton type="submit" className="TaggingModal-content-btn">
          Save
        </IonButton>
      </form>
    );
  }
}

export default ComponentInputs;

import {
  colorThemes,
  COLOR_THEME_STORAGE_PROPERTY,
} from '../constants/constants';
import { IMGIX_REDIRECT_URL } from '../constants/imageSources';

export const getNumericInput = value => {
  if (!value || value[0] === '0') return '';

  return value.replace(/\D/g, '');
};

export const getPriceInput = value => {
  if (!value || value[0] === '0') return '';

  const decimalPointPosition = value.indexOf(',');

  if (decimalPointPosition !== -1 && value.length > decimalPointPosition + 3) {
    const parts = value.split(',');
    return parts[0] + ',' + parts[1].slice(0, 2);
  }

  if (
    (value[value.length - 1] === ',' || value[value.length - 1] === '.') &&
    value[value.length - 2] !== ',' &&
    decimalPointPosition === value.length - 1
  ) {
    return value.slice(0, -1) + ',';
  }

  if (decimalPointPosition !== -1) {
    return value.slice(0, -1) + value.slice(-1).replace(/\D/g, '');
  }

  return value.replace(/\D/g, '');
};

export const formatPrice = price =>
  price ? (price.toFixed(2) + '').replace(/\./g, ',') : '0,00';

export const formatCategoryNameFromDB = name => name.replace('-', ' / ');

export const activateSelectedColorTheme = () => {
  const colorTheme = localStorage.getItem(COLOR_THEME_STORAGE_PROPERTY);

  switch (colorTheme) {
    case colorThemes.DARKMODE:
      return document.body.classList.toggle('dark', true);
    case colorThemes.DEVICE_SETTING: {
      const isSystemInDarkmode = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;

      return document.body.classList.toggle('dark', isSystemInDarkmode);
    }
    case colorThemes.LIGHT:
    default:
      return document.body.classList.toggle('dark', false);
  }
};

export const sortByObjKeyHandler = (a, b, key) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

export const getOptimizedImageURL = (imageUrl, width, height, ratio) =>
  `${IMGIX_REDIRECT_URL}/${imageUrl.split('/').pop()}?auto=compress${
    width ? '&w=' + width : ''
  }${height ? '&h=' + height : ''}${ratio ? '&ar=' + ratio + '&fit=crop' : ''}`;

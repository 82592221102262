import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';

import Fade from '../../utils/Fade';

import { getOptimizedImageURL } from '../../../utils/utils';
import './NarrowCardList.sass';

const NarrowCardList = ({
  data,
  getImage,
  getTitle,
  getSubtitle,
  getLink,
  scrollHeight,
  onClick = null,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const a = useRef(null);

  useEffect(() => {
    const cardContent = a.current;

    if (scrollHeight !== undefined) {
      cardContent.addEventListener('scroll', onScroll);
    }

    return () => cardContent.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line
  }, []);

  const onScroll = (e) => setIsScrolled(e.target.scrollTop !== 0);

  return (
    <IonCard className="NarrowCardList">
      <div>
        <Fade show={isScrolled} className="shadow"></Fade>
      </div>
      <IonCardContent
        ref={a}
        className="NarrowCardList-content"
        style={
          scrollHeight
            ? { overflow: 'scroll', maxHeight: scrollHeight + 'px' }
            : {}
        }
      >
        <IonList>
          {data.map((item, index) => {
            // E.g. carDetails can be null
            if (!item) return null;
            
            const listItem = (
              <IonItem
                className="NarrowCardList-item"
                key={index}
                onClick={() => (onClick ? onClick(item) : null)}
                style={{ borderBottomWidth: data.length > 1 && index !== data.length-1 ? '1px' : '0px' }}
              >
                <img
                  src={getOptimizedImageURL(getImage(item), 96, null, '1:1')}
                  className="NarrowCardList-item__image"
                  slot="start"
                  alt="User"
                />
                <IonLabel className="NarrowCardList-item__info" color="medium">
                  <h3>{getTitle(item)}</h3>
                  {getSubtitle(item)}
                </IonLabel>
                <IonIcon
                  icon={chevronForwardOutline}
                  className="NarrowCardList-item__icon"
                  slot="end"
                />
              </IonItem>
            );

            if (getLink) {
              return (
                <Link to={getLink(item)} key={index} className="Link">
                  {listItem}
                </Link>
              );
            }

            return listItem;
          })}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default NarrowCardList;

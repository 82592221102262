import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  IonAlert,
  IonSpinner,
} from '@ionic/react';

import { SHOWROOM_ROUTE, LOGIN_ROUTE } from '../../constants/routes';
import { setPasswordAfterReset } from '../../api/firebase';
import './AuthForms.sass';

let password = null;
let passwordRepeat = null;

class NewPassword extends Component {
  state = {
    showAlertMessage: false,
    isLoading: false,
  };

  componentDidMount() {
    const { history } = this.props;
    const actionCode = history.location.state
      ? history.location.state.actionCode
      : null;

    if (!actionCode) {
      return history.push(SHOWROOM_ROUTE);
    }
  }

  setShowAlertMessage = val => this.setState({ showAlertMessage: val });

  onSubmit = e => {
    e.preventDefault();

    const { history } = this.props;
    const passwords = {
      password: password.value,
      passwordRepeat: passwordRepeat.value,
    };

    if (!passwords.password || !passwords.passwordRepeat) {
      return;
    }

    if (passwords.password !== passwords.passwordRepeat) {
      this.setShowAlertMessage(true);
      password.value = '';
      passwordRepeat.value = '';
      return;
    }

    // SUCCESS
    this.setState({ isLoading: true });
    const actionCode = history.location.state.actionCode;

    setPasswordAfterReset(actionCode, passwords.password).then(() =>
      history.push(LOGIN_ROUTE)
    );
  };

  render() {
    const { showAlertMessage, isLoading } = this.state;

    return (
      <>
        <IonAlert
          isOpen={showAlertMessage}
          onDidDismiss={() => this.setShowAlertMessage(false)}
          header="Warning"
          message="Passwords don't match"
          buttons={['OK']}
        />
        <form className="AuthForms" onSubmit={this.onSubmit}>
          <IonList>
            <IonItem className="AuthForms__input">
              <IonLabel color="primary" position="floating">
                Password <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                type="password"
                required={true}
                minlength="6"
                ref={input => (password = input)}
              ></IonInput>
            </IonItem>
            <IonItem className="AuthForms__input">
              <IonLabel color="primary" position="floating">
                Password (Repeat) <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                type="password"
                required={true}
                minlength="6"
                ref={input => (passwordRepeat = input)}
              ></IonInput>
            </IonItem>
          </IonList>
          <IonButton
            className="AuthForms__button"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <IonSpinner /> : 'Save Password'}
          </IonButton>
        </form>
      </>
    );
  }
}

export default withRouter(NewPassword);

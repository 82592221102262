import React, { Component } from 'react';
import {
  IonButton,
  IonSlide,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
} from '@ionic/react';
import uniqid from 'uniqid';

import NarrowCardList from '../../CardGroup/NarrowCardList/NarrowCardList';
import ComponentInputs from '../ComponentInputs/ComponentInputs';

import { addNewComponentOrTag } from '../../../api/api';
import { uploadImage, getImageURL } from '../../../api/firebase';
import { COMPONENTS_IMAGE_UPLOAD_PATH } from '../../../api/routes';
import categories from '../../../constants/categories';

import './TagNewComponent.sass';

class TagNewComponent extends Component {
  state = {
    scrollHeight: 0,
    category: null,
  };

  componentDidMount() {
    this.setScrollHeight();
    window.addEventListener('resize', this.setScrollHeight);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentSlide === 2 &&
      this.props.currentSlide === 1 &&
      this.state.category
    ) {
      this.setState({ category: null });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScrollHeight);
  }

  setScrollHeight = () =>
    // 171.8px - button + title, 0.6 - modal height is 60%
    this.setState({ scrollHeight: window.innerHeight * 0.6 - 171.8 });

  handleCreatingNewComponent = component => {
    const { lastTag, imageIndex, startLoading } = this.props;

    component.tag = lastTag;
    component.imageIndex = imageIndex;

    startLoading();

    if (component.image && component.image.selectedImgSrc) {
      const uniqueFileName = uniqid('component-image-');

      return uploadImage(
        COMPONENTS_IMAGE_UPLOAD_PATH,
        uniqueFileName,
        component.image.selectedImgSrc
      )
        .then(() => getImageURL(COMPONENTS_IMAGE_UPLOAD_PATH, uniqueFileName))
        .then(url => {
          component.image = url;

          return this.submit(component).then(() => {
            if (this.props.isNewLevel) {
              this.props.openLevelUpModal();
            }
          });
        });
    }

    return this.submit(component).then(() => {
      if (this.props.isNewLevel) {
        this.props.openLevelUpModal();
      }
    });
  };

  onTaggingExistingComponent = component => {
    const { lastTag, imageIndex } = this.props;

    const tagData = {
      tag: lastTag,
      imageIndex,
    };

    tagData.tag.componentId = component.id;

    this.submit(tagData);
  };

  submit = data => {
    const { closeModal, finishLoading } = this.props;

    return addNewComponentOrTag(data)
      .then(() => closeModal())
      .catch(() => finishLoading());
  };

  onChoosingCategory = e =>
    this.setState(
      { category: e.detail.value },
      e.detail.value ? this.props.onSwipingNext : null
    );

  render() {
    const { components, onSwipingNext } = this.props;
    const { category } = this.state;

    if (components.length) {
      components.sort((a, b) => a.name.localeCompare(b.name));
    }

    return (
      <>
        <IonSlide>
          <div className="TaggingModal-content ion-padding">
            <IonButton
              className="TaggingModal-content-btn"
              style={{ marginTop: '1rem' }}
              onClick={onSwipingNext}
            >
              Tag New Component
            </IonButton>
            {components.length ? (
              <div className="TagNewComponent-components-list">
                <div className="TagNewComponent-components-list__title">
                  <span></span>or choose existing<span></span>
                </div>
                <NarrowCardList
                  data={components}
                  getImage={component => component.image}
                  getTitle={component => component.name}
                  getSubtitle={component => component.make}
                  scrollHeight={this.state.scrollHeight}
                  onClick={this.onTaggingExistingComponent}
                />
              </div>
            ) : null}
          </div>
        </IonSlide>
        <IonSlide>
          <div className="TaggingModal-content ion-padding">
            <IonItem className="TaggingModal-content-item">
              <IonLabel>Choose your category</IonLabel>
              <IonSelect
                className="TaggingModal-content-select"
                interface="alert"
                onIonChange={this.onChoosingCategory}
                value={category}
              >
                <IonSelectOption value={categories.BRAKES}>
                  Brakes
                </IonSelectOption>
                <IonSelectOption value={categories.CHASSIS}>
                  Chassis
                </IonSelectOption>
                <IonSelectOption value={categories.ENGINE_TRANSMISSION}>
                  Engine / Transmission
                </IonSelectOption>
                <IonSelectOption value={categories.EXHAUST}>
                  Exhaust
                </IonSelectOption>
                <IonSelectOption value={categories.FILTER}>
                  Filter
                </IonSelectOption>
                <IonSelectOption value={categories.HIFI_NAVI}>
                  HiFi / Navi
                </IonSelectOption>
                <IonSelectOption value={categories.INTERIOR}>
                  Interior
                </IonSelectOption>
                <IonSelectOption value={categories.LIGHTS}>
                  Lights
                </IonSelectOption>
                <IonSelectOption value={categories.PAINT_WRAP}>
                  Paint / Wrap
                </IonSelectOption>
                <IonSelectOption value={categories.RIMS}>Rims</IonSelectOption>
                <IonSelectOption value={categories.SUSPENSION}>
                  Suspension
                </IonSelectOption>
                <IonSelectOption value={categories.TIRES}>
                  Tires
                </IonSelectOption>
                <IonSelectOption value={categories.TURBO}>
                  Turbo
                </IonSelectOption>
                <IonSelectOption value={categories.MISC}>Misc</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
        </IonSlide>
        <IonSlide>
          <ComponentInputs
            category={category}
            onSubmit={this.handleCreatingNewComponent}
          />
        </IonSlide>
      </>
    );
  }
}

export default TagNewComponent;

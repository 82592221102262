import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonContent,
  IonPage,
  IonText,
  IonList,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  withIonLifeCycle,
} from '@ionic/react';

import Header from '../../components/Header/Header';
import Auth from '../../screens/Auth/Auth';
import Notification from '../../components/Notification/Notification';
import Fade from '../../components/utils/Fade';

import { readNotifications } from '../../api/firebase';
import { fetchNotifications } from '../../store/notifications/actions';

class Notifications extends Component {
  ionContent = React.createRef();

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSameActiveTab !== this.props.isSameActiveTab &&
      this.props.isSameActiveTab
    ) {
      this.ionContent.current.scrollToTop(500);
      this.props.resetSecondTabClick();
    }
  }

  ionViewWillLeave() {
    readNotifications();
  }

  onFetchNotifications = e => {
    const { notifications, fetchNotifications } = this.props;
    const lastNotification = notifications[notifications.length - 1];

    return fetchNotifications(
      lastNotification ? lastNotification.id : null
    ).then(() => e.target.complete());
  };

  onRefresh = e =>
    setTimeout(
      this.props.fetchNotifications().then(() => {
        e.detail.complete();
        readNotifications();
      }),
      500
    );

  render() {
    const {
      notifications,
      isAllComponentsFetched,
      isLoggedIn,
    } = this.props;
    // need to have <Auth /> inline because of strange rerender behavior of IonReactRouter
    return (
      <IonPage>
        {isLoggedIn ? (
          <>
            <Header />
            <IonContent ref={this.ionContent} className="ion-padding">
              <IonRefresher slot="fixed" onIonRefresh={this.onRefresh}>
                <IonRefresherContent className="infinite-spinner" />
              </IonRefresher>
              <Fade>
                <IonText color="dark">
                  <h1>Notifications</h1>
                </IonText>
                <IonList>
                  {notifications.map(notification => (
                    <Notification data={notification} key={notification.id} />
                  ))}
                </IonList>
                <IonInfiniteScroll
                  style={{ marginTop: '1rem' }}
                  threshold="100px"
                  disabled={isAllComponentsFetched}
                  onIonInfinite={this.onFetchNotifications}
                >
                  <IonInfiniteScrollContent
                    className="infinite-spinner"
                    loading-spinner="dots"
                  />
                </IonInfiniteScroll>
              </Fade>
            </IonContent>
          </>
        ) : (
          <Auth />
        )}
      </IonPage>
    );
  }
}

const mapStateToProps = store => ({
  notifications: store.notifications.notifications,
  isAllComponentsFetched: store.catalogue.isAllComponentsFetched,
  isLoggedIn: store.user.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
  fetchNotifications: lastNotificationId =>
    dispatch(fetchNotifications(lastNotificationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withIonLifeCycle(Notifications));

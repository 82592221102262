import React from 'react';
import {
  IonContent,
  IonPage,
  IonText,
} from '@ionic/react';
import Header from '../../components/Header/Header';

const LegalNotice = () => {
  return (
    <IonPage>
      <Header goBack />
      <IonContent className="ion-padding">
        <IonText color="dark">
          <h1>Legal Notice</h1>
        </IonText>
      </IonContent>
    </IonPage>
  );
};

export default LegalNotice;

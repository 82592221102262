import React from 'react';
import Collapsible from 'react-collapsible';

import './CollapsibleTab.sass';

const CollapsibleTab = ({ title, content, isOpen, onToggle }) => {
  return (
    <Collapsible
      trigger={title}
      transitionTime={300}
      easing="ease"
      open={isOpen}
      handleTriggerClick={onToggle}
      classParentString="CollapsibleTab"
    >
      <p>{content}</p>
    </Collapsible>
  );
};

export default CollapsibleTab;

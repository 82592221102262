import React from 'react';
import { IonIcon, IonText } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

import './BackButton.sass';

const BackButton = ({ onClick }) => {
  return (
    <div className="BackButton" onClick={onClick}>
      <IonIcon
        className="BackButton__icon"
        icon={chevronBackOutline}
        color="primary"
      />
      <IonText className="BackButton__text" color="primary">
        Back
      </IonText>
    </div>
  );
};

export default BackButton;

import React, { Component } from 'react';
import { IonContent, IonPage, withIonLifeCycle, IonText } from '@ionic/react';

import Header from '../../components/Header/Header';
import CollapsibleTab from '../../components/CollapsibleTab/CollapsibleTab';

import { sortByObjKeyHandler } from '../../utils/utils';
import { getFAQRef, getFAQ } from '../../api/firebase';

class FAQ extends Component {
  state = {
    activeTab: null,
    faqs: [],
  };
  FAQsListener = null;

  ionViewDidEnter() {
    this.FAQsListener = getFAQRef().on('value', () => {
      this.fetchFAQs();
    });
  }

  ionViewWillLeave() {
    getFAQRef().off('value', this.FAQsListener);
  }

  fetchFAQs = () => {
    getFAQ().then((data) => this.setState({ faqs: Object.values(data) }));
  };

  render() {
    const { activeTab, faqs } = this.state;

    return (
      <IonPage>
        <Header goBack />
        <IonContent className="ion-padding">
          <IonText color="dark">
            <h1>FAQ</h1>
          </IonText>
          {faqs
            .sort((a, b) => sortByObjKeyHandler(a, b, 'rank'))
            .map((el, index) => (
              <CollapsibleTab
                key={index}
                title={el.title}
                content={el.answer}
                isOpen={activeTab === index}
                onToggle={() => {
                  if (activeTab === index) {
                    return this.setState({ activeTab: null });
                  }

                  this.setState({ activeTab: index });
                }}
              />
            ))}
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(FAQ);

const categories = {
  BRAKES: 'brakes',
  CHASSIS: 'chassis',
  ENGINE_TRANSMISSION: 'engine-transmission',
  EXHAUST: 'exhaust',
  FILTER: 'filter',
  HIFI_NAVI: 'hifi-navi',
  INTERIOR: 'interior',
  LIGHTS: 'lights',
  PAINT_WRAP: 'paint-wrap',
  RIMS: 'rims',
  SUSPENSION: 'suspension',
  TIRES: 'tires',
  TURBO: 'turbo',
  MISC: 'misc',
};

export default categories;

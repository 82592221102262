import React, { Component } from 'react';
import {
  IonSlides,
  IonSlide,
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonText,
} from '@ionic/react';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { closeOutline, pricetagOutline, settingsOutline } from 'ionicons/icons';

import TaggingContainer from '../TaggingComponents/TaggingContainer/TaggingContainer';
import TaggingModal from '../TaggingComponents/TaggingModal/TaggingModal';

import './FullscreenGallerySlider.sass';

let slider = null;

class FullscreenGallerySlider extends Component {
  state = {
    currentSlide: 0,
    editMode: false,
    showPopovers: false,
    taggingActive: false,
    lastTagData: null,
    animated: true,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    if (!slider) return;

    if (!prevProps.isOpen && this.props.isOpen) {
      ScreenOrientation.unlock();
    }

    slider.lockSwipes(this.state.taggingActive || this.state.editMode);
  }

  onDismiss = () => {
    this.setState({ taggingActive: false, editMode: false });
    this.handleClosingGallery();
  };

  onResize = () =>
    setTimeout(() => {
      if (slider) {
        slider.update();
      }
    }, 400);

  onIonSlideNextStart = () => {
    const { currentSlide } = this.state;
    const nextSlide =
      currentSlide !== this.props.images.length - 1 ? currentSlide + 1 : 0;
    return this.setState({ currentSlide: nextSlide });
  };

  onIonSlidePrevStart = () => {
    const { currentSlide } = this.state;
    const prevSlide =
      currentSlide !== 0 ? currentSlide - 1 : this.props.images.length - 1;
    return this.setState({ currentSlide: prevSlide });
  };

  handleSetLastTagData = (lastTagData, callback = null) =>
    this.setState({ lastTagData }, callback);

  handleDisableTagging = () => {
    const { images } = this.props;
    const { editMode, currentSlide } = this.state;

    if (editMode)
      return this.setState({ taggingActive: false, lastTagData: null });

    return this.setState(prevState => ({
      taggingActive: false,
      lastTagData:
        editMode &&
        images[currentSlide].tags &&
        images[currentSlide].tags[images[currentSlide].tags.length - 1]
          .componentId === prevState.lastTagData.componentId
          ? prevState.lastTagData
          : {
              ...prevState.lastTagData,
              delete: true,
              imageIndex: currentSlide,
            },
    }));
  };

  handleClosingGallery = (animated = true) => {
    ScreenOrientation.lock('portrait');

    if (animated) {
      return this.setState(
        { currentSlide: 0, showPopovers: false },
        this.props.closeFullscreenGallery
      );
    }

    this.setState({ animated: false, currentSlide: 0 }, () =>
      setTimeout(() => {
        this.props.closeFullscreenGallery();
        // we need setTimout here to avoid closing animation on component page
        setTimeout(
          () => this.setState({ animated: true, showPopovers: false }),
          400
        );
      }, 400)
    );
  };

  render() {
    const {
      single = false,
      images = [],
      components = [],
      withTagging,
      initialSlide,
      isOpen,
    } = this.props;
    const {
      editMode,
      showPopovers,
      currentSlide,
      lastTagData,
      taggingActive,
      animated,
    } = this.state;
    const slideOpts = {
      initialSlide,
      speed: 400,
      loop: !single,
    };
    if (!images.length) return null;

    return (
      <>
        <IonModal
          swipeToClose
          isOpen={isOpen}
          animated={animated}
          cssClass="FullscreenGallerySlider"
          onWillDismiss={this.onDismiss}
        >
          <IonHeader>
            <IonToolbar className="FullscreenGallerySlider-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="FullscreenGallerySlider-toolbar__close-btn"
                  onClick={this.onDismiss}
                >
                  <IonIcon icon={closeOutline} />
                </IonButton>
                <div slot="end">
                  {/* single is used for component page image */}
                  {!single && images[currentSlide].tags && (
                    <IonButton
                      className="FullscreenGallerySlider-toolbar__popover-btn"
                      color="primary"
                      fill="outline"
                      shape="round"
                      onClick={() =>
                        this.setState(prevState => ({
                          showPopovers: !prevState.showPopovers,
                          editMode: false,
                        }))
                      }
                      slot="end"
                    >
                      <IonIcon
                        color="primary"
                        icon={pricetagOutline}
                        style={{ marginRight: showPopovers ? '.3rem' : 0 }}
                      />
                      <IonText color="primary">
                        {showPopovers ? 'components' : ''}
                      </IonText>
                    </IonButton>
                  )}
                </div>
              </IonButtons>
              {withTagging && images[currentSlide].tags && (
                <div slot="end">
                  <IonButton
                    className="FullscreenGallerySlider-toolbar__edit-btn"
                    fill="outline"
                    shape="round"
                    onClick={() =>
                      this.setState(prevState => ({
                        editMode: !prevState.editMode,
                        showPopovers: false,
                      }))
                    }
                    slot="end"
                  >
                    <IonText color="primary">{editMode ? 'edit' : ''}</IonText>
                    <IonIcon
                      icon={settingsOutline}
                      style={{ marginLeft: editMode ? '.3rem' : 0 }}
                    />
                  </IonButton>
                </div>
              )}
              {!single && (
                <IonLabel
                  className="FullscreenGallerySlider-toolbar__counter"
                  slot="end"
                >
                  {currentSlide + 1} / {images.length}
                </IonLabel>
              )}
            </IonToolbar>
            <IonSlides
              ref={el => (slider = el)}
              className="FullscreenGallerySlider-slider"
              onIonSlidesDidLoad={() =>
                this.setState({ currentSlide: initialSlide })
              }
              onIonSlideNextStart={this.onIonSlideNextStart}
              onIonSlidePrevStart={this.onIonSlidePrevStart}
              options={slideOpts}
            >
              {images.map((el, index) => (
                <IonSlide key={index}>
                  {withTagging ? (
                    <TaggingContainer
                      tags={images[index].tags ? images[index].tags : []}
                      components={components}
                      showPopovers={showPopovers}
                      editMode={editMode}
                      closeFullscreenGallery={this.handleClosingGallery}
                      imageIndex={index}
                      enableTagging={() =>
                        this.setState({ taggingActive: true })
                      }
                      disableTagging={() =>
                        this.setState({ taggingActive: false })
                      }
                      lastTagData={lastTagData}
                      setLastTagData={this.handleSetLastTagData}
                      image={el.src}
                    />
                  ) : (
                    <TaggingContainer
                      tags={images[index].tags ? images[index].tags : []}
                      components={components}
                      showPopovers={showPopovers}
                      withTagging={false}
                      closeFullscreenGallery={this.handleClosingGallery}
                      image={el.src ? el.src : el}
                      imageIndex={index}
                    />
                  )}
                </IonSlide>
              ))}
            </IonSlides>
          </IonHeader>
        </IonModal>
        <TaggingModal
          isOpen={taggingActive}
          editMode={editMode}
          components={components ? components : []}
          images={images}
          lastTag={lastTagData}
          imageIndex={currentSlide}
          closeModal={() =>
            this.setState({
              taggingActive: false,
              showPopovers: false,
              editMode: false,
            })
          }
          disableTagging={this.handleDisableTagging}
        />
      </>
    );
  }
}

export default FullscreenGallerySlider;

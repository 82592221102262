import React from 'react';
import {
  IonCard,
  IonSkeletonText,
  IonCardContent,
  IonCardHeader,
  IonAvatar,
  IonButtons,
  IonButton,
  IonThumbnail,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';

const ProfileSkeleton = () => {
  return (
    <>
      <IonSkeletonText
        className="ion-margin-bottom"
        animated
        style={{ margin: '24px 0', width: '30%', height: '20px' }}
      />
      <IonCard className="UserInfoCard" style={{ marginLeft: 0, marginRight: 0 }}>
        <IonCardHeader
          className="ion-align-items-center"
          style={{ display: 'flex' }}
        >
          <IonAvatar
            style={{ marginRight: '1rem', width: '72px', height: '72px' }}
          >
            <IonSkeletonText animated />
          </IonAvatar>
          <p style={{ width: 'calc(100% - 96px)' }}>
            <IonSkeletonText animated style={{ width: '40%' }} />
            <IonSkeletonText animated style={{ width: '30%' }} />
            <IonSkeletonText animated style={{ width: '10%' }} />
          </p>
        </IonCardHeader>
        <IonCardContent>
          <IonButtons className="UserInfoCard-stats">
            <IonButton>
              <div className="UserInfoCard-stats__stat">
                <IonSkeletonText animated style={{ width: '65px' }} />
                <IonSkeletonText animated style={{ width: '30px' }} />
              </div>
            </IonButton>
            <IonButton>
              <div className="UserInfoCard-stats__stat">
                <IonSkeletonText animated style={{ width: '65px' }} />
                <IonSkeletonText animated style={{ width: '30px' }} />
              </div>
            </IonButton>
            <IonButton>
              <div className="UserInfoCard-stats__stat">
                <IonSkeletonText animated style={{ width: '65px' }} />
                <IonSkeletonText animated style={{ width: '30px' }} />
              </div>
            </IonButton>
          </IonButtons>
        </IonCardContent>
      </IonCard>
      <IonSkeletonText
        className="ion-margin-bottom"
        animated
        style={{ margin: '24px 0', width: '30%', height: '20px' }}
      />
      <IonCard className="NarrowCardList" style={{ marginLeft: 0, marginRight: 0 }}>
        <IonCardContent>
          <IonList>
            <IonItem className="NarrowCardList-item">
              <IonThumbnail className="NarrowCardList-item__image" slot="start">
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonLabel className="NarrowCardList-item__info" color="medium">
                <IonSkeletonText animated style={{ width: '80%' }} />
                <IonSkeletonText animated style={{ width: '50%' }} />
              </IonLabel>
              <IonIcon
                icon={chevronForwardOutline}
                className="NarrowCardList-item__icon"
                slot="end"
              />
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
      <IonSkeletonText
        className="ion-margin-bottom"
        animated
        style={{ margin: '24px 0', width: '30%', height: '20px' }}
      />
      <IonCard className="NarrowCardList" style={{ marginLeft: 0, marginRight: 0 }}>
        <IonCardContent>
          <IonList>
            <IonItem className="NarrowCardList-item">
              <IonThumbnail className="NarrowCardList-item__image" slot="start">
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonLabel className="NarrowCardList-item__info" color="medium">
                <IonSkeletonText animated style={{ width: '80%' }} />
                <IonSkeletonText animated style={{ width: '50%' }} />
              </IonLabel>
              <IonIcon
                icon={chevronForwardOutline}
                className="NarrowCardList-item__icon"
                slot="end"
              />
            </IonItem>
            <IonItem className="NarrowCardList-item">
              <IonThumbnail className="NarrowCardList-item__image" slot="start">
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonLabel className="NarrowCardList-item__info" color="medium">
                <IonSkeletonText animated style={{ width: '80%' }} />
                <IonSkeletonText animated style={{ width: '50%' }} />
              </IonLabel>
              <IonIcon
                icon={chevronForwardOutline}
                className="NarrowCardList-item__icon"
                slot="end"
              />
            </IonItem>
            <IonItem className="NarrowCardList-item">
              <IonThumbnail className="NarrowCardList-item__image" slot="start">
                <IonSkeletonText animated />
              </IonThumbnail>
              <IonLabel className="NarrowCardList-item__info" color="medium">
                <IonSkeletonText animated style={{ width: '80%' }} />
                <IonSkeletonText animated style={{ width: '50%' }} />
              </IonLabel>
              <IonIcon
                icon={chevronForwardOutline}
                className="NarrowCardList-item__icon"
                slot="end"
              />
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default ProfileSkeleton;

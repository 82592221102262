import * as actions from './actions';

const initialState = {
  details: null,
  isLoading: false,
};

const carReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING_START:
      return { ...state, isLoading: true };
    case actions.FETCH_MY_CAR:
      return {
        ...state,
        details: action.payload ? action.payload : null,
        isLoading: false,
      };
    case actions.LOADING_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default carReducer;

import React from 'react';
import { IonButtons, IonBackButton } from '@ionic/react';

import { SHOWROOM_ROUTE } from '../../../constants/routes';

const HeaderBackButton = ({ goBack, onGoBack }) => {
  const renderBackButton = () => {
    if (onGoBack) {
      return (
        <IonButtons slot="start">
          <IonBackButton onClick={onGoBack} />
        </IonButtons>
      );
    }

    if (goBack) {
      return (
        <IonButtons slot="start">
          <IonBackButton defaultHref={SHOWROOM_ROUTE} />
        </IonButtons>
      );
    }

    return <></>;
  };

  return renderBackButton();
};

export default HeaderBackButton;

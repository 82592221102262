import React from 'react';
import { IonList } from '@ionic/react';
import {
  logInOutline,
  logOutOutline,
  personAddOutline,
  personOutline,
  carSportOutline,
  flameOutline,
  settingsOutline,
  bulbOutline,
  fingerPrintOutline,
  documentOutline,
} from 'ionicons/icons';

import MenuItem from './MenuItem';
import UserInfoCard from '../CardGroup/UserInfoCard/UserInfoCard';

import {
  LOGIN_ROUTE,
  SHOWROOM_ROUTE,
  REGISTER_ROUTE,
  MY_CAR_ROUTE,
  EDIT_MY_CAR_ROUTE,
  PROFILE_ROUTE,
  MY_LIKES_ROUTE,
  SETTINGS_ROUTE,
  FAQ_ROUTE,
  PRIVACY_ROUTE,
  LEGAL_NOTICE_ROUTE,
} from '../../constants/routes';

const MenuContent = ({ isLoggedIn, user, carDetails, logUserOut }) => {
  return (
    <>
      {!isLoggedIn ? (
        <IonList>
          <MenuItem link={LOGIN_ROUTE} icon={logInOutline}>
            Login
          </MenuItem>
          <MenuItem link={REGISTER_ROUTE} icon={personAddOutline}>
            Register
          </MenuItem>
        </IonList>
      ) : (
        <>
          <div className="ion-padding">
            <UserInfoCard
              user={user}
              componentAmount={
                carDetails && carDetails.components
                  ? carDetails.components.length
                  : 0
              }
              withoutWrapper
              withFollowing={false}
              small
            />
          </div>
          <IonList>
            <MenuItem link={PROFILE_ROUTE + user.uid} icon={personOutline}>
              Profile
            </MenuItem>
            <MenuItem
              link={carDetails ? EDIT_MY_CAR_ROUTE : MY_CAR_ROUTE}
              icon={carSportOutline}
            >
              {carDetails ? 'Edit car' : 'Add car'}
            </MenuItem>
            <MenuItem link={MY_LIKES_ROUTE} icon={flameOutline}>
              Likes
            </MenuItem>
            <MenuItem link={SETTINGS_ROUTE} icon={settingsOutline}>
              Settings
            </MenuItem>
            <MenuItem link={FAQ_ROUTE} icon={bulbOutline}>
              FAQ
            </MenuItem>
            <MenuItem link={PRIVACY_ROUTE} icon={fingerPrintOutline}>
              Privacy
            </MenuItem>
            <MenuItem link={LEGAL_NOTICE_ROUTE} icon={documentOutline}>
              Legal Notice
            </MenuItem>
            <MenuItem
              link={SHOWROOM_ROUTE}
              icon={logOutOutline}
              onClick={logUserOut}
            >
              Logout
            </MenuItem>
          </IonList>
        </>
      )}
    </>
  );
};

export default MenuContent;

import React from 'react';
import { Link } from 'react-router-dom';
import {
  IonCard,
  IonAvatar,
  IonText,
  IonCardContent,
  IonIcon,
  IonButtons,
  IonButton,
} from '@ionic/react';
import {
  pinOutline,
  trophyOutline,
  trophy,
  checkmarkCircleOutline,
} from 'ionicons/icons';

import FollowButton from '../../ButtonGroup/FollowButton/FollowButton';

import { getCurrentUserUid } from '../../../api/firebase';
import { getOptimizedImageURL } from '../../../utils/utils';

import './UserInfoCard.sass';

const UserInfoCard = ({
  user,
  withoutWrapper = false,
  small = false,
  componentAmount = 0,
  profileLink,
  withFollowing = true,
}) => {
  const { profileImage, name, location, verified } = user;

  const renderTrophy = () => {
    let color = 'dark';

    if (componentAmount >= 1 && componentAmount <= 5) {
      color = 'bronze';
    } else if (componentAmount >= 6 && componentAmount <= 10) {
      color = 'silver';
    } else if (componentAmount > 10) {
      color = 'gold';
    }

    return (
      <div className="UserInfoCard-info__trophy">
        <IonIcon
          color={color}
          icon={componentAmount > 0 ? trophy : trophyOutline}
        />
      </div>
    );
  };

  const renderWithLink = (DOMelement) => {
    return profileLink ? (
      <Link to={profileLink} className="Link">
        {DOMelement}
      </Link>
    ) : (
      DOMelement
    );
  };

  const cardContent = (
    <div
      className="UserInfoCard-content"
      style={{ fontSize: small ? '12px' : '16px' }}
    >
      {renderWithLink(
        <IonAvatar className="UserInfoCard-avatar">
          <img
            src={getOptimizedImageURL(profileImage, 160, null, '1:1')}
            alt="User"
          />
        </IonAvatar>
      )}
      <div className={`UserInfoCard-info-wrapper${small ? '--small' : ''}`}>
        <div className="UserInfoCard-info">
          {renderWithLink(
            <IonText color="dark">
              <h3 className="UserInfoCard-info__name">
                {name}
                {verified && (
                  <IonIcon icon={checkmarkCircleOutline} color="primary" />
                )}
              </h3>
            </IonText>
          )}
          <div className="UserInfoCard-info__location">
            <IonIcon icon={pinOutline} /> {location ? location : 'Unknown'}
          </div>
          {renderTrophy()}
        </div>
        {withFollowing && user.uid !== getCurrentUserUid() && (
          <div slot="end">
            <FollowButton profileId={user.uid} />
          </div>
        )}
      </div>
    </div>
  );

  return withoutWrapper ? (
    <div style={{ fontSize: small ? '12px' : '16px' }}>{cardContent}</div>
  ) : (
    <IonCard className="UserInfoCard">
      <IonCardContent>
        {cardContent}
        <IonButtons className="UserInfoCard-stats">
          <IonButton>
            <Link
              to={(location) => location.pathname + '/followers'}
              className="Link"
            >
              <div className="UserInfoCard-stats__stat">
                Followers
                <span>{user.followers}</span>
              </div>
            </Link>
          </IonButton>
          <IonButton>
            <Link
              to={(location) => location.pathname + '/following'}
              className="Link"
            >
              <div className="UserInfoCard-stats__stat">
                Following
                <span>{user.following}</span>
              </div>
            </Link>
          </IonButton>
          <IonButton>
            <div className="UserInfoCard-stats__stat">
              Likes
              <span>{user.stats ? user.stats.likes : 0}</span>
            </div>
          </IonButton>
        </IonButtons>
      </IonCardContent>
    </IonCard>
  );
};

export default UserInfoCard;

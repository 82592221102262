import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  IonPage,
  isPlatform,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Plugins } from '@capacitor/core';
import {
  homeOutline,
  pricetagsOutline,
  notificationsOutline,
  notifications,
  carSportOutline,
  home,
  pricetags,
  carSport,
} from 'ionicons/icons';
import firebase from './api/firebase';
import { setUserFcmToken } from './api/api';

import Menu from './components/MenuComponents/Menu';

import AppUrlListener from './components/AppUrlListener/AppUrlListener';
import Showroom from './screens/Showroom/Showroom';
import Vehicle from './screens/Vehicle/Vehicle';
import Catalogue from './screens/Catalogue/Catalogue';
import Notifications from './screens/Notifications/Notifications';
import MyCar from './screens/MyCar/MyCar';
import Profile from './screens/Profile/Profile';
import Followers from './screens/Followers/Followers';
import Following from './screens/Following/Following';
import MyLikes from './screens/MyLikes/MyLikes';
import Settings from './screens/Settings/Settings';
import FAQ from './screens/FAQ/FAQ';
import Privacy from './screens/Privacy/Privacy';
import LegalNotice from './screens/LegalNotice/LegalNotice';
import TermsOfService from './screens/TermsOfService/TermsOfService';
import Component from './screens/Component/Component';
import Auth from './screens/Auth/Auth';
import Welcome from './screens/Welcome/Welcome';
import Action from './screens/Action/Action';
import IconWithBadge from './components/IconWithBadge/IconWithBadge';
import IOSStaticHeader from './components/IOSStaticHeader/IOSStaticHeader';

import { fetchUserDataAndLogIn } from './store/user/actions';
import { listenToShowroomUpdates } from './store/showroom/actions';
import { listenToCatalogueUpdates } from './store/catalogue/actions';
import { fetchMyCarDetails } from './store/car/actions';
import { fetchNotifications } from './store/notifications/actions';

import { activateSelectedColorTheme } from './utils/utils';
import * as routes from './constants/routes';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/commonStyles.sass';

const { PushNotifications } = Plugins;

const tabNames = {
  SHOWROOM: 'showroom',
  CATALOGUE: 'catalogue',
  NOTIFICATIONS: 'notifications',
  MY_CAR: 'my-car',
};

class App extends React.Component {
  state = {
    hideTabBar: false,
    protectedRoutes: true,
    activeTab: null,
    isTabWillChangeWasCalled: false,
    isSameActiveTab: false,
  };
  tabs = React.createRef();

  componentDidMount() {
    ScreenOrientation.lock('portrait');
    activateSelectedColorTheme();

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    prefersDark.addListener(activateSelectedColorTheme);

    firebase.auth().onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        this.props.logUserIn();
        this.props.fetchMyCarDetails();
        this.props.fetchNotifications();
        this.regesterPushNotifications();
      }
    });

    this.setState({
      activeTab: this.tabs.current.ionTabContextState.activeTab,
    });

    this.props.listenToShowroomUpdates();
    this.props.listenToCatalogueUpdates();
  }

  regesterPushNotifications() {
    PushNotifications.requestPermission().then(permission => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration', token =>
      setUserFcmToken(token.value)
    );

    PushNotifications.addListener('registrationError', error =>
      alert('Error: ' + JSON.stringify(error))
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async notification => {
        const data = notification.notification.data;

        if (data.path) {
          this.props.history.push(data.path);
        }
      }
    );
  }

  handleToggleTabBar = () =>
    this.setState(prevState => ({
      hideTabBar: !prevState.hideTabBar,
    }));

  setActiveTab = tab => {
    this.setState({
      activeTab: tab,
      isTabWillChangeWasCalled: true,
      isSameActiveTab: false,
    });
  };

  onTabButtonClick = tab => {
    if (this.state.isTabWillChangeWasCalled) {
      return this.setState({ isTabWillChangeWasCalled: false });
    }

    return this.setState(prevState => ({
      isSameActiveTab: prevState.activeTab === tab,
    }));
  };
  // wee need to reset sameActiveTab in order to scroll to top right after component gets a new prop value
  handleResetSecondTabClick = () => this.setState({ isSameActiveTab: false });

  render() {
    const { isNotificationBadgeShown } = this.props;
    const { hideTabBar, activeTab, isSameActiveTab } = this.state;

    return (
      <>
        <AppUrlListener />
        <Menu />
        {isPlatform('ios') && !hideTabBar && <IOSStaticHeader />}
        <IonTabs
          ref={this.tabs}
          onIonTabsWillChange={e => this.setActiveTab(e.detail.tab)}
        >
          <IonRouterOutlet id="menu-content">
            <Route
              path={routes.SHOWROOM_ROUTE}
              render={props => (
                <Showroom
                  {...props}
                  isSameActiveTab={
                    isSameActiveTab && activeTab === tabNames.SHOWROOM
                  }
                  resetSecondTabClick={this.handleResetSecondTabClick}
                />
              )}
              exact
            />
            <Route
              path={routes.VEHICLE_ROUTE + ':id'}
              component={Vehicle}
              exact
            />
            <Route
              path={routes.CATALOGUE_ROUTE}
              render={props => (
                <Catalogue
                  {...props}
                  isSameActiveTab={
                    isSameActiveTab && activeTab === tabNames.CATALOGUE
                  }
                  resetSecondTabClick={this.handleResetSecondTabClick}
                />
              )}
              exact
            />

            {/* Authentication Routes */}
            <Route
              path={routes.LOGIN_ROUTE}
              render={props => (
                <IonPage>
                  <Auth type="login" {...props} />
                </IonPage>
              )}
              exact
            />
            <Route
              path={routes.REGISTER_ROUTE}
              render={props => (
                <IonPage>
                  <Auth type="registration" {...props} />
                </IonPage>
              )}
              exact
            />
            <Route
              path={routes.RESET_PASSWORD_ROUTE}
              render={props => (
                <IonPage>
                  <Auth type="reset-password" {...props} />
                </IonPage>
              )}
              exact
            />
            <Route
              path={routes.NEW_PASSWORD_ROUTE}
              render={props => (
                <IonPage>
                  <Auth type="new-password" {...props} />
                </IonPage>
              )}
              exact
            />
            
            <Route
              path={routes.NOTIFICATIONS_ROUTE}
              render={props => (
                <Notifications
                  {...props}
                  isSameActiveTab={
                    isSameActiveTab && activeTab === tabNames.NOTIFICATIONS
                  }
                  resetSecondTabClick={this.handleResetSecondTabClick}
                />
              )}
              exact
            />
            <Route
              path={routes.MY_CAR_ROUTE}
              render={props => (
                <MyCar
                  {...props}
                  isSameActiveTab={
                    isSameActiveTab && activeTab === tabNames.MY_CAR
                  }
                  resetSecondTabClick={this.handleResetSecondTabClick}
                />
              )}
              exact
            />
            <Route path={routes.EDIT_MY_CAR_ROUTE} component={MyCar} exact />
            <Route
              path={routes.PROFILE_ROUTE + ':id'}
              component={Profile}
              exact
            />
            <Route path={routes.FOLLOWERS_ROUTE} component={Followers} exact />
            <Route path={routes.FOLLOWING_ROUTE} component={Following} exact />
            <Route path={routes.MY_LIKES_ROUTE} component={MyLikes} exact />

            {/* Setting Routes */}
            <Route
              path={routes.SETTINGS_ROUTE}
              render={() => <Settings type="list" />}
              exact
            />
            <Route
              path={routes.SETTINGS_PROFILE_ROUTE}
              render={() => <Settings type="profile" />}
              exact
            />
            <Route
              path={routes.SETTINGS_PASSWORD_ROUTE}
              render={() => <Settings type="password" />}
              exact
            />
            <Route
              path={routes.SETTINGS_MAIL_ROUTE}
              render={() => <Settings type="mail" />}
              exact
            />
            <Route
              path={routes.SETTINGS_NOTIFICATIONS_ROUTE}
              render={() => <Settings type="notifications" />}
              exact
            />
            <Route
              path={routes.SETTINGS_DISPLAY_ROUTE}
              render={() => <Settings type="display" />}
              exact
            />
            <Route
              path={routes.SETTINGS_DELETE_CAR_ROUTE}
              render={() => <Settings type="delete-car" />}
              exact
            />
            <Route
              path={routes.SETTINGS_DELETE_PROFILE_ROUTE}
              render={() => <Settings type="delete-profile" />}
              exact
            />

            <Route path={routes.FAQ_ROUTE} component={FAQ} exact />
            <Route path={routes.PRIVACY_ROUTE} component={Privacy} exact />
            <Route
              path={routes.LEGAL_NOTICE_ROUTE}
              component={LegalNotice}
              exact
            />
            <Route
              path={routes.TERMS_OF_SERVICE_ROUTE}
              component={TermsOfService}
              exact
            />
            <Route
              path={routes.COMPONENT_ROUTE + ':id'}
              component={Component}
              exact
            />
            <Route
              path={routes.WELCOME_ROUTE}
              render={props => (
                <Welcome toggleTabBar={this.handleToggleTabBar} {...props} />
              )}
              exactt
            />
            <Route path={routes.ACTION_ROUTE} component={Action} exactt />
            <Redirect to={routes.SHOWROOM_ROUTE} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom" hidden={hideTabBar}>
            <IonTabButton
              tab={tabNames.SHOWROOM}
              href={routes.SHOWROOM_ROUTE}
              onClick={() => this.onTabButtonClick(tabNames.SHOWROOM)}
            >
              <IonIcon
                icon={tabNames.SHOWROOM === activeTab ? home : homeOutline}
              />
              <IonLabel>Showroom</IonLabel>
            </IonTabButton>
            <IonTabButton
              tab={tabNames.CATALOGUE}
              href={routes.CATALOGUE_ROUTE}
              onClick={() => this.onTabButtonClick(tabNames.CATALOGUE)}
            >
              <IonIcon
                icon={
                  tabNames.CATALOGUE === activeTab
                    ? pricetags
                    : pricetagsOutline
                }
              />
              <IonLabel>Catalogue</IonLabel>
            </IonTabButton>
            <IonTabButton
              tab={tabNames.NOTIFICATIONS}
              href={routes.NOTIFICATIONS_ROUTE}
              onClick={() => this.onTabButtonClick(tabNames.NOTIFICATIONS)}
            >
              <IconWithBadge
                icon={
                  tabNames.NOTIFICATIONS === activeTab
                    ? notifications
                    : notificationsOutline
                }
                showBadge={isNotificationBadgeShown}
              />
              <IonLabel>Notifications</IonLabel>
            </IonTabButton>
            <IonTabButton
              tab={tabNames.MY_CAR}
              href={routes.MY_CAR_ROUTE}
              onClick={() => this.onTabButtonClick(tabNames.MY_CAR)}
            >
              <IonIcon
                icon={
                  tabNames.MY_CAR === activeTab ? carSport : carSportOutline
                }
              />
              <IonLabel>My Car</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </>
    );
  }
}

const mapStateToProps = store => ({
  isNotificationBadgeShown: store.notifications.isUnseenNotification,
});

const mapDispatchToProps = dispatch => ({
  logUserIn: () => fetchUserDataAndLogIn(dispatch),
  fetchMyCarDetails: () => dispatch(fetchMyCarDetails()),
  fetchNotifications: () => dispatch(fetchNotifications(0, true)),
  listenToShowroomUpdates: () => dispatch(listenToShowroomUpdates()),
  listenToCatalogueUpdates: () => dispatch(listenToCatalogueUpdates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

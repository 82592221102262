import React from 'react';
import { Link } from 'react-router-dom';
import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';

import Logo from '../../assets/logo.png';

import { SHOWROOM_ROUTE } from '../../constants/routes';
import './IOSStaticHeader.sass';

const IOSStaticHeader = () => {
  return (
    <IonHeader className="IOSStaticHeader">
      <IonToolbar mode="ios">
        <Link to={SHOWROOM_ROUTE}>
          <IonTitle>
            <img src={Logo} className="Header-logo" alt="Logo" />
          </IonTitle>
        </Link>
      </IonToolbar>
    </IonHeader>
  );
};

export default IOSStaticHeader;

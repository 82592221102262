import React from 'react';
import { IonText, IonList } from '@ionic/react';
import {
  personOutline,
  keyOutline,
  paperPlaneOutline,
  notificationsOutline,
  moonOutline,
  carSportOutline,
  trashOutline,
} from 'ionicons/icons';
import SettingsListItem from './SettingsListItem';

import {
  SETTINGS_PROFILE_ROUTE,
  SETTINGS_PASSWORD_ROUTE,
  SETTINGS_MAIL_ROUTE,
  SETTINGS_NOTIFICATIONS_ROUTE,
  SETTINGS_DISPLAY_ROUTE,
  SETTINGS_DELETE_CAR_ROUTE,
  SETTINGS_DELETE_PROFILE_ROUTE,
} from '../../../constants/routes';

const SettingsList = ({ carDetails }) => {
  return (
    <>
      <IonText>
        <h1>Settings</h1>
      </IonText>
      <IonList className="SettingsList">
        <SettingsListItem
          name="Profile"
          icon={personOutline}
          link={SETTINGS_PROFILE_ROUTE}
        />
        <SettingsListItem
          name="Password"
          icon={keyOutline}
          link={SETTINGS_PASSWORD_ROUTE}
        />
        <SettingsListItem
          name="Mail"
          icon={paperPlaneOutline}
          link={SETTINGS_MAIL_ROUTE}
        />
        <SettingsListItem
          name="Notifications"
          icon={notificationsOutline}
          link={SETTINGS_NOTIFICATIONS_ROUTE}
        />
        <SettingsListItem
          name="Display"
          icon={moonOutline}
          link={SETTINGS_DISPLAY_ROUTE}
        />
        {carDetails && (
          <SettingsListItem
            name="Delete Car"
            icon={carSportOutline}
            color="danger"
            link={SETTINGS_DELETE_CAR_ROUTE}
          />
        )}
        <SettingsListItem
          name="Delete Profile"
          icon={trashOutline}
          color="danger"
          link={SETTINGS_DELETE_PROFILE_ROUTE}
        />
      </IonList>
    </>
  );
};

export default SettingsList;

import React from 'react';
import { Link } from 'react-router-dom';
import {
  IonCard,
  IonList,
  IonItem,
  IonCardContent,
  IonLabel,
} from '@ionic/react';

import LikeButton from '../../ButtonGroup/LikeButton/LikeButton';

import { segments } from '../../../screens/MyLikes/MyLikes';
import { getOptimizedImageURL } from '../../../utils/utils';
import './MyLikesCard.sass';

const MyLikesCard = ({ likes, activeSegment, onUnlike }) => {
  const isCar = activeSegment === segments.CARS;

  return (
    <IonCard className="MyLikesCard">
      <IonCardContent>
        <IonList>
          {likes.map((el) => (
            <IonItem
              className="MyLikesCard-item"
              key={el.id}
              id={`my-likes-${el.id}`}
            >
              <Link
                to={(isCar ? 'vehicle/' : 'component/') + el.id}
                className="Link"
              >
                <div className="MyLikesCard-item-wrapper">
                  <img
                    src={getOptimizedImageURL(el.image, 96, null, '1:1')}
                    className="MyLikesCard-item__image"
                    slot="start"
                    alt="User"
                  />
                  <IonLabel className="MyLikesCard-item__info" color="medium">
                    <h3>{isCar ? `${el.model}, ${el.type}` : el.name}</h3>
                    {el.make}
                  </IonLabel>
                </div>
              </Link>
              <div
                className="MyLikesCard-item__icon"
                slot="end"
                onClick={() => onUnlike(el)}
              >
                <LikeButton
                  type="icon"
                  path={isCar ? { vehicleId: el.id } : { componentId: el.id }}
                  isLiked
                />
              </div>
            </IonItem>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default MyLikesCard;

import React, { Component } from 'react';
import {
  IonContent,
  IonPage,
  IonSlides,
  IonSlide,
  IonButton,
  IonIcon,
  IonAlert,
} from '@ionic/react';
import {
  arrowForwardOutline,
  carSportOutline,
  pricetagOutline,
  trophyOutline,
  flameOutline,
} from 'ionicons/icons';
import { SHOWROOM_ROUTE, LOGIN_ROUTE } from '../../constants/routes';
import { verifyUserEmail } from '../../api/firebase';

import './Welcome.sass';

class Welcome extends Component {
  state = {
    showAlertMessage: false,
  };

  componentDidMount() {
    const { history } = this.props;
    const actionCode = history.location.state
      ? history.location.state.actionCode
      : null;

    this.props.toggleTabBar();

    if (!actionCode) {
      return history.push(SHOWROOM_ROUTE);
    }

    verifyUserEmail(actionCode).catch(() =>
      this.setState({ showAlertMessage: true })
    );
  }

  onContinueClick = () => {
    this.props.toggleTabBar();
    this.props.history.push(LOGIN_ROUTE);
  };

  goNext = () => {
    this.props.toggleTabBar();
    this.props.history.push(SHOWROOM_ROUTE);
  };

  render() {
    const { showAlertMessage } = this.state;

    return (
      <IonPage>
        <IonContent className="Welcome" fullscreen>
          <IonAlert
            isOpen={showAlertMessage}
            onDidDismiss={this.goNext}
            header="Warning"
            message="Something went wrong. Email isn't or already verified"
            buttons={['OK']}
          />
          <IonSlides pager>
            <IonSlide className="Welcome-slide">
              <IonIcon className="Welcome-slide__icon" icon={carSportOutline} />
              <h2 className="Welcome-slide__title">Welcome</h2>
              <p className="Welcome-slide__description">
                Thank you for joining TuningWelt! Up next you can set up your
                car
              </p>
            </IonSlide>
            <IonSlide className="Welcome-slide">
              <IonIcon className="Welcome-slide__icon" icon={pricetagOutline} />
              <h2 className="Welcome-slide__title">Parts</h2>
              <p className="Welcome-slide__description">
                Tag parts you've added to you car
              </p>
            </IonSlide>
            <IonSlide className="Welcome-slide">
              <IonIcon className="Welcome-slide__icon" icon={trophyOutline} />
              <h2 className="Welcome-slide__title">Levels</h2>
              <p className="Welcome-slide__description">
                The more tagged parts you have, the higher your level
              </p>
            </IonSlide>
            <IonSlide className="Welcome-slide">
              <IonIcon className="Welcome-slide__icon" icon={flameOutline} />
              <h2 className="Welcome-slide__title">Ready to start?</h2>
              <IonButton
                onClick={this.onContinueClick}
              >
                Login Now{' '}
                <IonIcon slot="end" icon={arrowForwardOutline}></IonIcon>
              </IonButton>
            </IonSlide>
          </IonSlides>
        </IonContent>
      </IonPage>
    );
  }
}

export default Welcome;

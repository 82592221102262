export const COLOR_THEME_STORAGE_PROPERTY = 'colorTheme';
export const colorThemes = {
  LIGHT: 'light',
  DARKMODE: 'darkmode',
  DEVICE_SETTING: 'device-setting',
};

export const SHOWROOM_VIEW_MODE_STORAGE_PROPERTY = 'showroomViewMode';
export const CATALOGUE_VIEW_MODE_STORAGE_PROPERTY = 'catalogueViewMode';
export const viewModes = { 
  ONE_COLUMN: 'one-column',
  TWO_COLUMNS: 'two-columns'
}
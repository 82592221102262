import React, { useState } from 'react';
import {
  IonText,
  IonAvatar,
  IonList,
  IonItem,
  IonInput,
  IonIcon,
  IonLabel,
  IonButton,
} from '@ionic/react';
import { cameraOutline } from 'ionicons/icons';
import { ImagePicker } from '@ionic-native/image-picker';
import uniqid from 'uniqid';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { updateUserProfile } from '../../../api/api';
import { uploadImage, getImageURL } from '../../../api/firebase';
import { USERS_IMAGE_UPLOAD_PATH } from '../../../api/routes';
import './ProfileSettings.sass';

const ProfileSettings = ({
  user = {},
  startLoading,
  finishLoading,
  goBack,
}) => {
  const [username, setUsername] = useState(user.name ? user.name : '');
  const [location, setLocation] = useState('');
  const [profileImage, setProfileImage] = useState(user.profileImage);

  const onImageAdding = () => {
    try {
      ImagePicker.getPictures({
        maximumImagesCount: 1,
        quality: 30,
        outputType: 1, // base64 format
      }).then(imageFile => {
        if (!imageFile.length) return;

        setProfileImage({ selectedImgSrc: imageFile[0] });
      });
    } catch {}
  };

  const renderSuggestions = (_, suggestions) => {
    const cities = [];
    suggestions.map(suggestion => {
      const city = suggestion.terms[0].value;

      if (cities.indexOf(city) === -1) {
        cities.push(city);
      }

      return city;
    });

    return (
      <div className="suggestions-container">
        {cities.map((city, index) => (
          <div
            className="suggestion"
            onClick={() => {
              setLocation(city);
              locationInput.getInputElement().then(el => (el.value = city));
            }}
            key={index}
          >
            {city}
          </div>
        ))}
      </div>
    );
  };

  const onSubmit = () => {
    const userProfile = {};

    if (username) {
      userProfile.name = username;
    }
    if (location) {
      userProfile.location = location;
    }

    if (profileImage.selectedImgSrc) {
      const uniqueFileName = uniqid('profile-image-');

      startLoading();

      uploadImage(
        USERS_IMAGE_UPLOAD_PATH,
        uniqueFileName,
        profileImage.selectedImgSrc
      )
        .then(() => getImageURL(USERS_IMAGE_UPLOAD_PATH, uniqueFileName))
        .then(url => {
          userProfile.profileImage = url;

          return updateUserProfile(userProfile);
        })
        .then(() => {
          goBack();
          finishLoading();
        })
        .catch(err => {
          alert(err.message);
          finishLoading();
        });
    } else {
      if (!Object.values(userProfile)) return;

      startLoading();
      updateUserProfile(userProfile)
        .then(() => {
          goBack();
          finishLoading();
        })
        .catch(err => {
          alert(err.message);
          finishLoading();
        });
    }
  };

  let locationInput = null;

  return (
    <div className="ProfileSettings">
      <IonText>
        <h1>Profile</h1>
      </IonText>
      <div>
        <IonAvatar className="ProfileSettings__avatar" onClick={onImageAdding}>
          <div className="ProfileSettings__avatar-overlay">
            <IonIcon icon={cameraOutline} />
          </div>
          <img
            src={
              profileImage && profileImage.selectedImgSrc
                ? `data:image/png;base64,${profileImage.selectedImgSrc}`
                : profileImage
            }
            alt="user"
          />
        </IonAvatar>
      </div>
      <IonList>
        <IonItem className="Settings-input">
          <IonLabel color="primary" position="stacked">
            Username
          </IonLabel>
          <IonInput
            value={username}
            onIonChange={e => setUsername(e.detail.value)}
          ></IonInput>
        </IonItem>
        <IonItem className="Settings-input">
          <IonLabel color="primary" position="stacked">
            Location
          </IonLabel>
          <GooglePlacesAutocomplete
            renderInput={props => (
              <IonInput
                value={props.value}
                onIonChange={props.onChange}
                onKeyDown={props.onKeyDown}
                placeholder={user.location ? user.location : 'Location'}
                ref={input => (locationInput = input)}
              ></IonInput>
            )}
            renderSuggestions={renderSuggestions}
            autocompletionRequest={{ types: ['(cities)'] }}
          />
        </IonItem>
      </IonList>
      <IonButton className="Settings-button" onClick={onSubmit}>
        Save Changes
      </IonButton>
    </div>
  );
};

export default ProfileSettings;

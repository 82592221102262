/* --- USER --- */
export const CREATE_NEW_USER =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/createNewUser';
export const UPDATE_USER_PROFILE =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/updateUserProfile';
export const SET_USER_FCM_TOKEN =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/setUserFcmToken';
export const DELETE_USER_PROFILE =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/deleteUserProfile';

/* --- CAR --- */
export const ADD_NEW_CAR =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/addNewVehicle';
export const UPDATE_MY_CAR =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/updateVehicle';
export const DELETE_CAR =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/deleteVehicle';
export const GET_ALL_CAR_MODELS =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/getAllCarModels';

/* --- COMPONENTS AND TAGS --- */
export const ADD_NEW_COMPONENT_OR_TAG =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/addNewComponentOrTag';
export const EDIT_COMPONENT =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/editComponent';
export const DELETE_TAG_OR_COMPONENT =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/deleteTagOrComponent';

/* --- IMAGES --- */
export const USERS_IMAGE_UPLOAD_PATH = '/users/';
export const VEHICLES_IMAGE_UPLOAD_PATH = '/vehicles/';
export const COMPONENTS_IMAGE_UPLOAD_PATH = '/components/';

/* --- LIKES --- */
export const TOGGLE_LIKE =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/toggleLike';

/* --- COMMENTS --- */
export const ADD_COMMENT =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/addComment';

/* --- FOLLOWING --- */
export const FOLLOW_PROFILE =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/followProfile';

/* --- NOTIFICATIONS --- */
export const NOTIFY_NEW_FOLLOWING_LEVEL =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/notifyNewFollowingLevel';
export const SET_NOTIFICATION_SETTING =
  'https://us-central1-tuningwelt-141809.cloudfunctions.net/setNotificationSetting';

export const BASE_URL = 'https://stage.tuningwelt.net';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';

import Auth from '../../screens/Auth/Auth';
import Header from '../../components/Header/Header';
import MyCarForm from '../../components/MyCarForm/MyCarForm';
import CarDetails from '../../components/CarDetails/CarDetails';
import VerifyEmail from '../../components/VerifyEmail/VerifyEmail';
import Fade from '../../components/utils/Fade';

import {
  addNewCar,
  editMyCar,
  LOADING_START,
  LOADING_FINISH,
} from '../../store/car/actions';
import { isEmailVerified } from '../../api/firebase';
import { MY_CAR_ROUTE, EDIT_MY_CAR_ROUTE } from '../../constants/routes';

class MyCar extends Component {
  ionContent = React.createRef();

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSameActiveTab !== this.props.isSameActiveTab &&
      this.props.isSameActiveTab
    ) {
      this.ionContent.current.scrollToTop(500);
      this.props.resetSecondTabClick();
    }
  }

  render() {
    const {
      isLoggedIn,
      startLoading,
      finishLoading,
      isLoading,
      addNewCar,
      editMyCar,
      carDetails,
      user,
      history,
    } = this.props;
    const isEditMode = history.location.pathname === EDIT_MY_CAR_ROUTE;

    return (
      <IonPage>
        {isLoggedIn ? (
          <>
            <Header goBack={isEditMode} />
            <IonContent ref={this.ionContent} className="ion-padding">
              <Fade>
                {!isEmailVerified() ? (
                  <VerifyEmail isNotCanAccess />
                ) : carDetails && !isEditMode ? (
                  <CarDetails
                    details={carDetails}
                    user={user}
                    isOwner
                  />
                ) : (
                  <MyCarForm
                    addNewCar={addNewCar}
                    editMyCar={editMyCar}
                    startLoading={startLoading}
                    finishLoading={finishLoading}
                    isLoading={isLoading}
                    editMode={isEditMode}
                    carDetails={carDetails}
                    goBack={() => history.push(MY_CAR_ROUTE)}
                  />
                )}
              </Fade>
            </IonContent>
          </>
        ) : (
          <Auth />
        )}
      </IonPage>
    );
  }
}

const mapStateToProps = store => ({
  carDetails: store.car.details,
  isLoading: store.car.isLoading,
  user: store.user.user,
  isLoggedIn: store.user.isLoggedIn,
});

const mapDispatchToProps = dispatch => ({
  addNewCar: carDetails => dispatch(addNewCar(carDetails)),
  editMyCar: carDetails => dispatch(editMyCar(carDetails)),
  startLoading: () => dispatch({ type: LOADING_START }),
  finishLoading: () => dispatch({ type: LOADING_FINISH }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCar);

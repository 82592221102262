import React, { useEffect, useState } from 'react';

const Fade = ({ show = true, children, style, className = '' }) => {
  const [shouldRender, setShouldRender] = useState(show);

  useEffect(() => {
    if (show) setShouldRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setShouldRender(false);
  };

  return shouldRender ? (
    <div
      className={className}
      style={{
        ...style,
        animation: `${show ? 'fadeIn' : 'fadeOut'} 0.25s ease-in`,
      }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : null;
};

export default Fade;

import React, { Component } from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  IonAlert,
  IonSpinner,
} from '@ionic/react';

import VerifyEmail from '../VerifyEmail/VerifyEmail';

import { isEmail } from 'validator';
import { resetPassword } from '../../api/firebase';
import './AuthForms.sass';

let email = null;

class PasswordReset extends Component {
  state = {
    isResetDone: false,
    showAlertMessage: false,
    isLoading: false,
  };

  setShowAlertMessage = val => this.setState({ showAlertMessage: val });

  onSubmit = e => {
    e.preventDefault();
    const emailAddress = email.value;

    if (!isEmail(emailAddress)) {
      this.setShowAlertMessage(true);
      return;
    }

    this.setState({ isLoading: true });

    return resetPassword(emailAddress).then(() =>
      this.setState({ isResetDone: true, isLoading: false })
    );
  };

  render() {
    const { showAlertMessage, isResetDone, isLoading } = this.state;

    return (
      <>
        <IonAlert
          isOpen={showAlertMessage}
          onDidDismiss={() => this.setShowAlertMessage(false)}
          header="Warning"
          message="Invalid email"
          buttons={['OK']}
        />
        {!isResetDone ? (
          <form className="AuthForms" onSubmit={this.onSubmit}>
            <IonList>
              <IonItem className="AuthForms__input">
                <IonLabel color="primary" position="floating">
                  Email Address <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  type="email"
                  required={true}
                  ref={input => (email = input)}
                ></IonInput>
              </IonItem>
            </IonList>
            <IonButton
              className="AuthForms__button"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <IonSpinner /> : 'Reset Password'}
            </IonButton>
          </form>
        ) : (
          <VerifyEmail isPasswordReset />
        )}
      </>
    );
  }
}

export default PasswordReset;
